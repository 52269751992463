import React, { PureComponent } from 'react';
// Styling
import { withStyles } from '@material-ui/core/styles';
// Searchbar components
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

/**
 * Custom styles for the Searchbar component
 */
const styles = (theme) => ({
  // Styling related to the search bar
  '@global': {
    '.MuiFilledInput-underline ': {
      borderRadius: '8px',
    },
    '.MuiFilledInput-underline:before, .MuiFilledInput-underline:after, .MuiFilledInput-underline:hover:before':
      {
        borderBottom: 'unset',
      },
    '.MuiFilledInput-root, .MuiFilledInput-root:hover': {
      backgroundColor: 'rgba(0,0,0,0.09)',
    },
  },
  searchBarFormControl: {
    width: '100%',
    maxWidth: theme.breakpoints.values.md,
  },
  searchBarDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchicon: {
    color: theme.palette.gray2,
  },
});

/**
 * The required props are :
 * - changeSearchValue : function to invoke when search value is changed
 * - searchValue        : current value in searchbar
 */
class Searchbar extends PureComponent {
  render() {
    const { classes, searchValue, onChange } = this.props;

    return (
      <div className={classes.searchBarDiv}>
        <FormControl classes={{ root: classes.searchBarFormControl }}>
          {/* The fontSize has been set to 16px, and then transformed by 0.9375 times back to 15px
          in this text field to prevent an annoying feature in ios where once the user presses on
          the text field, the display zooms in to the text field. More details about this
          solution hack can be found at https://codepen.io/jakob-e/pen/yakBwJ */}
          <TextField
            placeholder="Search all food items"
            variant="filled"
            autoComplete="off"
            name="searchValue"
            onChange={onChange}
            value={searchValue}
            InputProps={{
              inputProps: {
                style: {
                  padding: '8px 0px 8px 0px',
                  width: '100%',
                  fontSize: '16px',
                  transformOrigin: 'center left',
                  transform: 'scale(0.9375)',
                },
              },
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.searchicon}
                  style={{ marginTop: '0px' }} // Inline style to force override some MUI style
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(Searchbar);
