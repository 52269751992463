import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

// Functions
import { AppContext } from '../../../AppContext';
import CommonFunctions from '../../common/CommonFunctions';

const { convertToAbbreviatedNumber } = CommonFunctions;

const styles = (theme) => ({
  root: {
    justifyContent: 'space-around',
    paddingTop: '15px',
    paddingBottom: '20px',
  },
  numberTextPadding: {
    paddingLeft: '3px',
  },
  unitTextStyle: {
    paddingLeft: '3px',
    paddingTop: '5px',
  },
  fontStyle: {
    ...theme.typography.weightCostSummary,
    whiteSpace: 'pre-line',
  },
});

class WeightCostSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currency } = this.context;
    const { classes, selectedLocationWasteAnalysisForHighlights } = this.props;

    return (
      selectedLocationWasteAnalysisForHighlights && (
        <Grid container direction="row" className={classes.root} spacing={1}>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Typography className={classes.fontStyle} align="center" color="primary">
                  TOTAL{'\n'}WASTE
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.numberTextPadding}
                  variant="subtitle1"
                  color="primary"
                >
                  {convertToAbbreviatedNumber(selectedLocationWasteAnalysisForHighlights.weight)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={`${classes.fontStyle} ${classes.unitTextStyle}`}
                  color="primary"
                >
                  KG
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Typography className={classes.fontStyle} align="center" color="primary">
                  TOTAL{'\n'}COST
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.numberTextPadding}
                  variant="subtitle1"
                  color="primary"
                >
                  {convertToAbbreviatedNumber(selectedLocationWasteAnalysisForHighlights.cost)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={`${classes.fontStyle} ${classes.unitTextStyle}`}
                  color="primary"
                >
                  {currency}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    );
  }
}

WeightCostSummary.contextType = AppContext;

export default withStyles(styles)(WeightCostSummary);
