import React, { PureComponent } from 'react';
// Styling
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// Card-related components
import { Box, Divider, Grid } from '@material-ui/core';

/**
 * Custom styles for the AddToWatchlistCard component
 */
const styles = (theme) => ({
  // Styling related to the general AddToWatchlistCard
  rows: {
    padding: '10px 10px',
    margin: '6px 4px',
  },
  searchResultText: {
    color: theme.palette.gray0,
  },
  divider: {
    backgroundColor: '#f0f0f0',
  },

  // Styling for indicating whether AddToWatchlistCard is selected/not selected
  selected: {
    backgroundColor: '#eff2f7',
    boxShadow: '3px 3px 10px lightgrey',
    borderRadius: '5px',
  },
  notSelected: {
    backgroundColor: '#FFFFFF',
  },
});

/**
 * A PureComponent that renders a clickable card with functionality and menu item data
 */
class AddToWatchlistCard extends PureComponent {
  render() {
    const { classes, isSelected, menuItemRestaurant, onClick } = this.props;
    const { menuItemName, restaurantName, locationName, station, serviceName } = menuItemRestaurant;

    return (
      <>
        <Box
          onClick={() => onClick(menuItemRestaurant, isSelected)}
          className={`${classes.rows} ${isSelected ? classes.selected : classes.notSelected}`}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" className={classes.searchResultText}>
                {menuItemName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                className={`${classes.searchResultText} ${classes.searchResultTextDetails}`}
              >{`${restaurantName}, ${locationName}\n${station}, ${serviceName}`}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider className={classes.divider} />
      </>
    );
  }
}

export default withStyles(styles)(AddToWatchlistCard);
