import React, { PureComponent } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
// Styling
import { withStyles } from '@material-ui/core/styles';
// Material UI Components
import { TableCell, TableRow } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// Icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicatorSharp';
import DeleteIcon from '@material-ui/icons/Delete';
// Trendline
import TrendLine from '../../common/chart/TrendLine';
import { AppContext, CONSTANT } from '../../../AppContext';
// eslint-disable-next-line no-unused-vars
import typedefs from '../../typedefs';

// Functions
import CommonFunctions from '../../common/CommonFunctions';

const { convertToAbbreviatedNumber } = CommonFunctions;

/**
 * Custom styles for the WatchlistRow component.
 */
const styles = (theme) => ({
  // Table column width styles
  tableColumn5: {
    width: '5%',
  },
  tableColumn20: {
    width: '20%',
  },
  tableColumn40: {
    width: '40%',
  },
  tableColumn50: {
    width: '50%',
  },
  // Default table row styles
  tableCellNoBottomBorder: {
    borderBottom: 'none',
  },
  tableCellStyle: {
    padding: '12px 4px 12px 4px',
    whiteSpace: 'nowrap',
  },
  tableCellNameStyle: {
    padding: '12px 4px 12px 4px',
    wordBreak: 'break-word',
  },
  tableCellSortIconStyle: {
    padding: '12px 4px 12px 0px',
  },
  tableCellIconStyle: {
    padding: '12px 0px 12px 0px',
  },
  // Icon/button styles
  sortIcon: {
    ...theme.typography.body1,
    paddingTop: '5px',
    color: theme.palette.gray2,
  },
  expandIconButton: {
    padding: '0px 0px 0px 0px',
  },
  expandIcon: {
    ...theme.typography.body1,
  },
  // Expanded row styles
  tableFirstSubRowCell: {
    padding: '0px 4px 0px 4px',
  },
  tableSubRowCell: {
    padding: '10px 4px 0px 4px',
  },
  tableSubRowText: {
    ...theme.typography.tableCell,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  tableSubRowDetailsFirstCell: {
    ...theme.typography.tableCell,
    padding: '4px 4px 12px 4px',
    color: theme.palette.primary.main,
  },
  tableSubRowDetailsTrendlineCell: {
    padding: '4px 4px 12px 4px',
    whiteSpace: 'nowrap',
  },
  deleteButtonCell: {
    verticalAlign: 'bottom',
    padding: '12px 0px 12px 0px',
  },
  deleteButton: {
    padding: '0px',
  },
  deleteButtonIcon: {
    ...theme.typography.body1,
  },
});

const draggingStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    display: 'table',
    opacity: 0.5,
  }),
});

/**
 * A PureComponent that renders an expandable table row for the Watchlist component.
 */

/**
 * Creates a data structure for Trendline Component
 * @param {typedefs.MenuItemWasteAnalysisForLineChart} axesData - Object arrDate, arrWeight and arrCost for plotting trendline
 * @returns {Object} result - Data structure for plotting trendline
 */
const createTrendlineDataSet = (axesData) => {
  const result = {
    labels: axesData.arrDate, // x axis data
    datasets: [
      {
        data: axesData.arrWeight, // y axis data (can be either weight or cost)
        borderWidth: 1.5,
        fill: false,
        lineTension: 0,
        pointRadius: 0,
        borderColor: CONSTANT.watchlistTrendlineColor,
      },
    ],
  };

  return result;
};
class WatchlistRow extends PureComponent {
  /**
   * Render a menu item's total weight and total cost only when the watchlist item has multiple menu items
   * @param {typedefs.MenuItemRestaurantWasteAnalysis} menuItemRestaurantWasteAnalysis - Object containing the company, restaurant, location and service of a menu item, including the waste analysis
   * @param {number} index - Position of the menu item in the array of menuItemRestaurantWasteAnalysis
   * @param {boolean} hasMultipleMenuItems - To indicate if there is multiple menu items
   */
  displayWeightAndCostOfMenuItem(menuItemRestaurantWasteAnalysis, index, hasMultipleMenuItems) {
    if (!hasMultipleMenuItems) {
      return null;
    }

    const { classes, isWatchlistItemCollapsed } = this.props;
    return (
      <TableRow style={{ display: isWatchlistItemCollapsed ? 'none' : 'table-row' }}>
        <TableCell padding="none" className={classes.tableCellNoBottomBorder} colSpan={2} />
        <TableCell
          className={`${index === 0 ? classes.tableFirstSubRowCell : classes.tableSubRowCell} ${
            classes.tableSubRowText
          } ${classes.tableColumn20} ${classes.tableCellNoBottomBorder}`}
          align="right"
        >
          {`${menuItemRestaurantWasteAnalysis.weight.toFixed(2)} KG`}
        </TableCell>
        <TableCell
          className={`${index === 0 ? classes.tableFirstSubRowCell : classes.tableSubRowCell} ${
            classes.tableSubRowText
          } ${classes.tableColumn20} ${classes.tableCellNoBottomBorder}`}
          align="right"
        >
          {`$${menuItemRestaurantWasteAnalysis.cost.toFixed(2)}`}
        </TableCell>
        <TableCell
          padding="none"
          className={`${classes.tableColumn5} ${classes.tableCellNoBottomBorder}`}
        />
      </TableRow>
    );
  }

  /**
   * Render the details of a menu item's waste analysis, including a trendline for its wastage over time.
   * Also include a delete icon to enable user to delete the menu item from the watchlist.
   * @param {typedefs.MenuItemRestaurantWasteAnalysis} menuItemRestaurantWasteAnalysis - Object containing the company, restaurant, location and service of a menu item, including the waste analysis
   */
  displayMenuItemRestaurantWasteAnalysisDetailsWithDeleteIcon(menuItemRestaurantWasteAnalysis) {
    const {
      auth0,
      classes,
      watchlistItemMenuItemRestaurantWasteAnalysis,
      onClickOpenConfirmationDialog,
    } = this.props;
    const {
      menuItemId,
      menuItemName,
      restaurantName,
      locationName,
      serviceName,
      station,
      wasteAnalysisForLineChart,
    } = menuItemRestaurantWasteAnalysis;
    const { impersonatorIsCompanyUser } = this.context;

    const { user } = auth0;
    return (
      <>
        <TableCell
          padding="none"
          className={`${classes.tableSubRowDetailsFirstCell} ${classes.tableColumn50}`}
        >
          {(!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser) ? (
            <Box>
              <b>Restaurant: </b>
              {restaurantName}
            </Box>
          ) : null}
          <Box>
            <b>Location: </b>
            {locationName}
          </Box>
          <Box>
            <b>Service: </b>
            {serviceName}
          </Box>
          <Box>
            <b>Station: </b>
            {station}
          </Box>
        </TableCell>
        <TableCell
          padding="none"
          colSpan={2}
          className={`${classes.tableSubRowDetailsTrendlineCell} ${classes.tableColumn40}`}
          align="center"
        >
          <TrendLine
            chartData={createTrendlineDataSet(wasteAnalysisForLineChart)}
            height={60}
            width={100}
          />
        </TableCell>
        <TableCell align="right" className={`${classes.deleteButtonCell} ${classes.tableColumn5}`}>
          <IconButton
            className={classes.deleteButton}
            onClick={() =>
              onClickOpenConfirmationDialog(
                menuItemId,
                menuItemName,
                watchlistItemMenuItemRestaurantWasteAnalysis
              )
            }
          >
            <DeleteIcon className={classes.deleteButtonIcon} />
          </IconButton>
        </TableCell>
      </>
    );
  }

  render() {
    const {
      watchlistItemMenuItemRestaurantWasteAnalysis,
      classes,
      snapshot,
      provided,
      isWatchlistItemCollapsed,
      onClickInfoIcon,
    } = this.props;
    const { cost, weight, watchlistItemId, arrMenuItemRestaurantWasteAnalysis } =
      watchlistItemMenuItemRestaurantWasteAnalysis;
    const hasMultipleMenuItems = arrMenuItemRestaurantWasteAnalysis.length > 1;

    return (
      <>
        {/* Collapsed View */}
        <TableRow
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={draggingStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <TableCell
            {...provided.dragHandleProps}
            className={`${classes.tableCellSortIconStyle} ${classes.tableColumn5} ${
              isWatchlistItemCollapsed && classes.tableCellNoBottomBorder
            }`}
          >
            <DragIndicatorIcon className={classes.sortIcon} />
          </TableCell>
          <TableCell
            className={`${classes.tableCellNameStyle} ${classes.tableColumn50} ${
              isWatchlistItemCollapsed && classes.tableCellNoBottomBorder
            }`}
            align="left"
          >
            {arrMenuItemRestaurantWasteAnalysis[0].menuItemName}
          </TableCell>
          <TableCell
            className={`${classes.tableCellStyle} ${classes.tableColumn20} ${
              isWatchlistItemCollapsed && classes.tableCellNoBottomBorder
            }`}
            align="right"
          >
            {`${convertToAbbreviatedNumber(weight)}`}
          </TableCell>
          <TableCell
            className={`${classes.tableCellStyle} ${classes.tableColumn20} ${
              isWatchlistItemCollapsed && classes.tableCellNoBottomBorder
            }`}
            align="right"
          >
            {`${convertToAbbreviatedNumber(cost)}`}
          </TableCell>
          <TableCell
            className={`${classes.tableCellIconStyle} ${classes.tableColumn5} ${
              isWatchlistItemCollapsed && classes.tableCellNoBottomBorder
            }`}
            align="right"
          >
            <IconButton
              aria-label="expand row"
              onClick={() => onClickInfoIcon(watchlistItemId)}
              className={classes.expandIconButton}
            >
              {isWatchlistItemCollapsed ? (
                <KeyboardArrowUpIcon className={classes.expandIcon} />
              ) : (
                <KeyboardArrowDownIcon className={classes.expandIcon} />
              )}
            </IconButton>
          </TableCell>
        </TableRow>

        {/* Expanded View */}
        {!isWatchlistItemCollapsed &&
          arrMenuItemRestaurantWasteAnalysis.map((menuItemRestaurantWasteAnalysis, index) => (
            <React.Fragment
              key={`${menuItemRestaurantWasteAnalysis.menuItemName}_${menuItemRestaurantWasteAnalysis.restaurantName}_${menuItemRestaurantWasteAnalysis.locationName}_${menuItemRestaurantWasteAnalysis.serviceName}_${menuItemRestaurantWasteAnalysis.station}`}
            >
              {this.displayWeightAndCostOfMenuItem(
                menuItemRestaurantWasteAnalysis,
                index,
                hasMultipleMenuItems
              )}
              <TableRow>
                <TableCell
                  className={`${classes.tableColumn5} ${
                    index < arrMenuItemRestaurantWasteAnalysis.length - 1 &&
                    classes.tableCellNoBottomBorder
                  }`}
                  padding="none"
                />
                {this.displayMenuItemRestaurantWasteAnalysisDetailsWithDeleteIcon(
                  menuItemRestaurantWasteAnalysis
                )}
              </TableRow>
            </React.Fragment>
          ))}
      </>
    );
  }
}

WatchlistRow.contextType = AppContext;

export default withAuth0(withStyles(styles)(WatchlistRow));
