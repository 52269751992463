import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Card,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowLeft, ArrowRight, Info } from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import TopFoodItemsTable from './TopFoodItemsTable';
import TopThreeWastefulStations from './TopThreeWastefulStations';
import WastageByServiceTable from './WastageByServiceTable';
import WastageOverTimeLineChart from './WastageOverTimeLineChart';
import WeightCostSummary from './WeightCostSummary';
import DropdownList from '../../common/DropdownList';
import ToggleWeightCostButton from '../../common/ToggleWeightCostButton';
// eslint-disable-next-line no-unused-vars
import typedefs from '../../typedefs';
import { AppContext } from '../../../AppContext';

/**
 * Custom styles for Restaurant breakdown component
 */
const styles = (theme) => ({
  rootCard: {
    ...theme.card.home,
    minWidth: 275,
  },
  headingContainer: {
    paddingLeft: '16px',
    paddingTop: '15px',
    alignItems: 'center',
  },
  spacedTitle: theme.typography.spacedTitle,
  // Tooltip
  tooltipIcon: {
    ...theme.typography.h4,
    color: theme.palette.gray3,
  },
  tooltipText: {
    textAlign: 'justify',
  },
  // Tabs
  tabContainer: {
    boxShadow: 'none',
    borderBottomStyle: 'solid',
    borderBottomWidth: 'thin',
    borderBottomColor: 'lightgrey',
  },
  tabStyle: {
    ...theme.typography.h4,
    padding: '0px',
    width: '50%', // To show only 2 restaurant tabs
    textTransform: 'none',
    fontWeight: 600,
    color: theme.palette.gray3, // For higher constrast between selected and unselected tab
  },
  tabSelectedIndicatorLine: {
    backgroundColor: '#4477A0',
  },
  wrapper: {
    marginBottom: '-10px',
  },
  arrowIcon: {
    padding: '13px 0px 5px 0px',
  },
  tabPanelStyle: {
    padding: '0px',
  },
  // Tab panel
  gridRootTabPanel: {
    paddingLeft: '16px',
    paddingRight: '16px',
    flexWrap: 'nowrap',
  },
  gridItemDropdownToggle: {
    paddingTop: '18px',
  },
  gridItemChart: {
    paddingBottom: '20px',
  },
  // Clickthrough links
  linkToStationBreakdownBox: {
    textAlign: 'right',
    paddingTop: '35px',
  },
  linkToBreakdownBox: {
    textAlign: 'right',
  },
  linkToBreakdown: {
    color: theme.palette.primary3,
    ...theme.typography.clickthroughLink,
  },
});

class RestaurantBreakdown extends PureComponent {
  /**
   * Render tooltip text differently for company or restaurant level users
   * @param {typedefs.WasteAnalysisForKeyOverview} wasteAnalysisForKeyOverview - Waste analysis for key overview
   */
  renderTooltipText(isCompanyUser, classes) {
    const { selectedRestaurantService } = this.props;

    return isCompanyUser ? (
      <Typography variant="caption" className={classes.tooltiptext}>
        <p>This shows the main wastage data of each restaurant.</p>
        <ul>
          <li>You can select a restaurant using the left/right arrow.</li>
          <li>You can also switch between viewing weight and cost data using the toggle.</li>
        </ul>
      </Typography>
    ) : (
      <Typography variant="caption" className={classes.tooltiptext}>
        <p>
          This shows the main wastage data of each location for{' '}
          <b>{selectedRestaurantService ? selectedRestaurantService.name : ''}</b>.
        </p>
        <ul>
          <li>You can select a location using the location dropdown.</li>
          <li>You can also switch between viewing weight and cost data using the toggle.</li>
        </ul>
      </Typography>
    );
  }

  render() {
    const {
      classes,
      auth0,
      arrRestaurantService,
      arrSelectedMenuItemServiceWasteAnalysis,
      arrDropdownItem,
      selectedLocationId,
      selectedLocationWasteAnalysisForHighlights,
      tabValue,
      isTooltipOpened,
      isRightArrowDisabled,
      isLeftArrowDisabled,
      selectedToggleValueForWeightCost,
      onClickAwayTooltip,
      onClickInfoIcon,
      onCloseTooltip,
      onClickAdd,
      onClickClearSelection,
      onClickLeftArrowIcon,
      onClickRightArrowIcon,
      onChangeTabList,
      onChangeDropdownList,
      onChangeToggleWeightCost,
      updateArrSelectedMenuItemServiceWasteAnalysis,
    } = this.props;
    const { user } = auth0;
    const { impersonatorIsCompanyUser } = this.context;

    return (
      <Card className={classes.rootCard}>
        {/* Title and tooltip container */}
        <Grid
          container
          direction="row"
          className={classes.headingContainer}
          spacing={1}
          alignItems="baseline"
        >
          <Grid item>
            <Typography variant="h1" color="primary" className={classes.spacedTitle}>
              {(!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser)
                ? 'BREAKDOWN BY PROPERTY'
                : 'BREAKDOWN BY LOCATION'}
            </Typography>
          </Grid>
          <Grid item>
            <ClickAwayListener onClickAway={onClickAwayTooltip}>
              <Tooltip
                arrow
                onClose={onCloseTooltip}
                open={isTooltipOpened}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={this.renderTooltipText(
                  (!user.isAdmin && user.isCompanyUser) ||
                    (user.isAdmin && impersonatorIsCompanyUser),
                  classes
                )}
              >
                <Info onClick={onClickInfoIcon} className={classes.tooltipIcon} />
              </Tooltip>
            </ClickAwayListener>
          </Grid>
        </Grid>
        <TabContext value={tabValue.toString()}>
          {/* Restaurant tabs and the left/right arrow button */}
          {(!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser) ? (
            <Paper className={classes.tabContainer}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={1}>
                  <IconButton
                    className={classes.arrowIcon}
                    onClick={onClickLeftArrowIcon}
                    color="primary"
                    disabled={isLeftArrowDisabled}
                  >
                    <ArrowLeft />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <TabList
                    onChange={(event, newValue) => onChangeTabList(event, newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="off"
                    classes={{ indicator: classes.tabSelectedIndicatorLine }}
                  >
                    {arrRestaurantService.map((restaurantService, index) => (
                      <Tab
                        className={classes.tabStyle}
                        label={restaurantService.name}
                        value={index.toString()}
                        key={restaurantService.restaurantId}
                        classes={{
                          wrapper: classes.wrapper,
                        }}
                      />
                    ))}
                  </TabList>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    className={classes.arrowIcon}
                    onClick={onClickRightArrowIcon}
                    color="primary"
                    disabled={arrRestaurantService.length <= 1 ? true : isRightArrowDisabled}
                  >
                    <ArrowRight />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ) : null}
          <Grid container direction="column" className={classes.gridRootTabPanel}>
            {/* Location dropdown and weightCost toggle */}
            <Grid item className={`dropdownToggle ${classes.gridItemDropdownToggle}`}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <DropdownList
                    label="Location"
                    backgroundColor="white"
                    selectedValue={selectedLocationId}
                    arrDropdownItem={arrDropdownItem}
                    onChange={onChangeDropdownList}
                  />
                </Grid>
                <Grid item>
                  <ToggleWeightCostButton
                    selectedToggleValue={selectedToggleValueForWeightCost}
                    onChange={onChangeToggleWeightCost}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Total weight and cost */}
            <Grid item>
              <WeightCostSummary
                selectedLocationWasteAnalysisForHighlights={
                  selectedLocationWasteAnalysisForHighlights
                }
              />
            </Grid>
            {/* Charts */}
            <Grid item>
              {arrRestaurantService.map((restaurantService, index) => (
                <TabPanel
                  className={classes.tabPanelStyle}
                  value={index.toString()}
                  key={restaurantService.restaurantId}
                >
                  <Grid container direction="column" wrap="nowrap">
                    {/* Wastage Over Time Line Chart */}
                    <Grid item className={classes.gridItemChart}>
                      <WastageOverTimeLineChart
                        selectedLocationWasteAnalysisForHighlights={
                          selectedLocationWasteAnalysisForHighlights
                        }
                        toggleValueForWeightCost={selectedToggleValueForWeightCost}
                      />
                    </Grid>

                    {/* Wastage By Service */}
                    <Grid item className={classes.gridItemChart}>
                      <WastageByServiceTable
                        selectedLocationWasteAnalysisForHighlights={
                          selectedLocationWasteAnalysisForHighlights
                        }
                        toggleValueForWeightCost={selectedToggleValueForWeightCost}
                      />
                    </Grid>

                    {/* Top 3 Wasteful Station Bar Chart */}
                    <Grid item className={classes.gridItemChart}>
                      <TopThreeWastefulStations
                        selectedLocationWasteAnalysisForHighlights={
                          selectedLocationWasteAnalysisForHighlights
                        }
                        toggleValueForWeightCost={selectedToggleValueForWeightCost}
                      />
                      <Box className={classes.linkToStationBreakdownBox}>
                        <Typography>
                          <Link
                            to={`/stations/${restaurantService.restaurantId}`}
                            className={classes.linkToBreakdown}
                          >
                            GO TO STATIONS BREAKDOWN &rarr;
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                    {/* Top 6 Wasted Menu Items Table */}
                    <Grid item className={classes.gridItemChart}>
                      <TopFoodItemsTable
                        selectedLocationWasteAnalysisForHighlights={
                          selectedLocationWasteAnalysisForHighlights
                        }
                        arrSelectedMenuItemServiceWasteAnalysis={
                          arrSelectedMenuItemServiceWasteAnalysis
                        }
                        toggleValueForWeightCost={selectedToggleValueForWeightCost}
                        onClickClearSelection={onClickClearSelection}
                        onClickAdd={onClickAdd}
                        updateArrSelectedMenuItemServiceWasteAnalysis={
                          updateArrSelectedMenuItemServiceWasteAnalysis
                        }
                      />
                      <Box className={classes.linkToBreakdownBox}>
                        <Typography>
                          <Link
                            to={`/items/${restaurantService.restaurantId}`}
                            className={classes.linkToBreakdown}
                          >
                            GO TO FOOD ITEMS BREAKDOWN &rarr;
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </TabContext>
      </Card>
    );
  }
}

RestaurantBreakdown.contextType = AppContext;

export default withAuth0(withStyles(styles)(RestaurantBreakdown));
