import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  buttonText: {
    textTransform: 'none',
  },
  toggleButton: {
    width: '60px',
    [theme.breakpoints.up('sm')]: {
      width: '70px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '80px',
    },
  },
  '@global': {
    '.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.primary3,
      borderColor: theme.palette.primary3,
      backgroundColor: '#eceff1',
      borderRadius: '4px',
    },
    '.MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: '#eceff1',
    },
  },
});

class ToggleWeightCostButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * The required props are:
   * - selectedValue   : weight or cost
   * - onChangeHandler : function to to toggle between weight and cost
   */
  render() {
    const { classes, selectedToggleValue, onChange } = this.props;

    return (
      <ToggleButtonGroup
        value={selectedToggleValue}
        exclusive
        onChange={(event, newSelectedToggleValue) => onChange(event, newSelectedToggleValue)}
        size="small"
      >
        <ToggleButton value="weight" className={`weightToggleButton ${classes.toggleButton}`}>
          <Typography className={classes.buttonText} variant="h4">
            Weight
          </Typography>
        </ToggleButton>
        <ToggleButton value="cost" className={`costToggleButton ${classes.toggleButton}`}>
          <Typography className={classes.buttonText} variant="h4">
            Cost
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

export default withStyles(styles)(ToggleWeightCostButton);
