import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import LockIcon from '@material-ui/icons/Lock';
import MenuIcon from '@material-ui/icons/Menu';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';

import { AppContext } from '../../AppContext';

/*
 * Styling
 */
const styles = (theme) => ({
  // General styling
  paperAnchorLeft: {
    backgroundImage: theme.palette.primaryGradient,
    width: '60%',
    color: 'white',
  },

  // Sidebar header
  appBar: {
    backgroundImage: theme.palette.primaryGradient,
  },
  headerContainer: {
    paddingTop: '10px',
    paddingBottom: '0px',
    marginBottom: '10px',
  },
  detailsContainer: {
    paddingRight: '5px',
    marginTop: '15px',
  },
  imgContainer: {
    minWidth: '50px',
  },
  avatar: {
    objectFit: 'contain',
    height: '35px',
  },
  name: {
    fontSize: theme.typography.h6.fontSize,
  },

  // Icons
  icon: {
    color: 'white',
    cursor: 'pointer',
  },

  // Tabs
  selectedTab: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0px 1px 10px rgba(180, 180, 180, 0.3)',
    cursor: 'pointer',
  },
  unselectedTab: {
    cursor: 'pointer',
  },
  switchUserButtonBox: {
    display: 'flex',
    justifyContent: 'end',
  },
  switchUserButton: {
    margin: '2px 8px',
    backgroundColor: theme.palette.primary.main3,
    fontSize: theme.typography.h6.fontSize,
    padding: '8px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main2,
    },
  },
});

class Sidebar extends PureComponent {
  render() {
    const {
      auth0,
      classes,
      history,
      isSidebarOpen,
      onClickCloseSidebar,
      onCloseSidebar,
      onClickLogout,
      onClickDirectToHomePage,
      onClickDirectToChangePasswordPage,
      onClickDirectToCoverInputPage,
      onClickDirectToMenuItemMappingPage,
      isUserAtHomePage,
      getTabClassName,
    } = this.props;
    const { companyLogoURL, companyName, impersonatorIsCompanyUser, impersonatorName } =
      this.context;
    const { user } = auth0;
    let name;
    if (user.isAdmin) {
      name = impersonatorName;
    } else {
      name = `${user.given_name} ${user.family_name}`;
    }

    return (
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        onClose={onCloseSidebar}
        classes={{ paperAnchorLeft: classes.paperAnchorLeft }}
        width="80%"
      >
        {/* HEADER */}
        <AppBar position="sticky" elevation={0} className={classes.appBar}>
          <List>
            <ListItem className={classes.headerContainer}>
              <ListItemIcon>
                <MenuIcon className={classes.icon} onClick={onClickCloseSidebar} />
              </ListItemIcon>

              {/* Image, Company, Name */}
              <ListItemSecondaryAction>
                <ListItem className={classes.detailsContainer}>
                  <ListItemIcon className={classes.imgContainer}>
                    <img src={companyLogoURL} className={classes.avatar} alt="avatar" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="h4">{companyName}</Typography>}
                    secondary={<Typography className={classes.name}>{name}</Typography>}
                  />
                </ListItem>
              </ListItemSecondaryAction>
            </ListItem>
            {user.isAdmin ? (
              <div className={classes.switchUserButtonBox}>
                <Button
                  className={classes.switchUserButton}
                  variant="contained"
                  onClick={() => history.push('/impersonator')}
                >
                  <CompareArrowsIcon />
                  <Typography variant="body2">Impersonator</Typography>
                </Button>
              </div>
            ) : null}
          </List>
        </AppBar>

        {/* SIDEBAR CONTENT */}
        <List>
          {/* Home */}
          <ListItem
            onClick={() => onClickDirectToHomePage('sidebar')}
            className={isUserAtHomePage() ? classes.selectedTab : classes.unselectedTab}
          >
            <ListItemIcon>
              <HomeIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItem>

          {/* Cover Input */}
          {(!user.isAdmin && !user.isCompanyUser) ||
          (user.isAdmin && !impersonatorIsCompanyUser) ? (
            <>
              <ListItem
                onClick={() => onClickDirectToCoverInputPage('sidebar')}
                className={
                  getTabClassName('cover-input') ? classes.selectedTab : classes.unselectedTab
                }
              >
                <ListItemIcon>
                  <PeopleIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>Cover Input</ListItemText>
              </ListItem>
              <ListItem
                onClick={() => onClickDirectToMenuItemMappingPage('sidebar')}
                className={
                  getTabClassName('menu-item-mapping') ? classes.selectedTab : classes.unselectedTab
                }
              >
                <ListItemIcon>
                  <MenuBookIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>Menu Item Mapping</ListItemText>
              </ListItem>
            </>
          ) : null}

          {/* Change Password */}
          <ListItem
            onClick={() => onClickDirectToChangePasswordPage('sidebar')}
            className={
              getTabClassName('change-password') ? classes.selectedTab : classes.unselectedTab
            }
          >
            <ListItemIcon>
              <LockIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>Change Password</ListItemText>
          </ListItem>

          {/* Logout */}
          <ListItem onClick={onClickLogout} className={classes.unselectedTab}>
            <ListItemIcon>
              <ExitToAppIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

Sidebar.contextType = AppContext;

export default withRouter(withAuth0(withStyles(styles)(Sidebar)));
