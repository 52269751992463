import axios from 'axios';
import { format } from 'date-fns';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import React, { PureComponent } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Card, ClickAwayListener, Grid, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import FoodItemsBreakdownTable from './FoodItemsBreakdownTable';
import LineChart from '../common/chart/LineChart';
import CommonFunctions from '../common/CommonFunctions';
import DropdownList from '../common/DropdownList';
import ToggleWeightCostButton from '../common/ToggleWeightCostButton';
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext, CONSTANT } from '../../AppContext';

const { getPreviousDateRangeString } = CommonFunctions;

/**
 * This is the clickthrough component for the breakdown by food items.
 * It acts as a container for the following:
 * - Location drop-down list
 * - Weight/Cost toggle button
 * - Search bar
 * - Table of food items
 *
 * @prop {Array} locationsList
 */

const styles = (theme) => ({
  spacedTitle: theme.typography.spacedTitle,
  rootGridContainer: {
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  rootGridItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCard: {
    ...theme.card.home,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCardGridContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
  rootCardGridTableContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  rootCardGridItemDefault: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  rootCardGridItemTitle: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  rootCardGridSubtitle: {
    paddingTop: '10px',
  },
  rootCardGridItemChart: {
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  tooltipIcon: {
    ...theme.typography.h4,
    color: theme.palette.gray3,
    paddingLeft: '8px',
  },
  downloadButton: {
    ...theme.typography.h6,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '7px',
    color: '#50655b',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#088280',
      color: theme.palette.secondary.main,
    },
    '&:disabled': {
      backgroundColor: 'white',
      color: theme.palette.gray3,
    },
  },
  rootCardGridItemLineChart: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '10px',
      paddingBottom: '20px',
      paddingLeft: '10px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
      paddingBottom: '20px',
    },
  },
});

class FoodItemsBreakdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // If menu item service waste analysis is fetched
      isArrLocationWithMenuItemServiceWasteAnalysisFetched: false,

      // For watchlist
      arrMenuItemRestaurant: [],
      arrWatchlistItemMenuItemRestaurantWasteAnalysis: [],

      // Data
      arrLocationWithMenuItemServiceWasteAnalysis: [],
      selectedLocationWithMenuItemServiceWasteAnalysis: {},
      arrSelectedMenuItemServiceWasteAnalysis: [],

      // Selected values
      selectedRestaurantService: null,
      selectedRestaurantName: '',
      selectedToggleValueForWeightCost: 'weight',
      isTooltipOpened: false,

      // Line Chart
      selectedMenuItemWasteAnalysisForLineChart: {},
      topMenuItemWasteAnalysisForLineChart: {},

      previousSelectedStartDate: '',
      previousSelectedEndDate: '',
      previousSelectedGroupBy: '',
    };
  }

  componentDidMount() {
    document.title = 'Food Items Breakdown | Lumitics | Towards Zero Food Waste';
    const { setPageHistory } = this.context;
    const { arrRestaurantService, match } = this.props;

    const selectedRestaurantId = parseInt(match.params.restaurantId, 10);

    const selectedRestaurantService = arrRestaurantService.find(
      (restaurantService) => restaurantService.restaurantId === selectedRestaurantId
    );

    const selectedRestaurantName = selectedRestaurantService.name;

    this.setState({
      selectedRestaurantService,
      selectedRestaurantName,
    });

    setPageHistory([`${selectedRestaurantName}: ${CONSTANT.breakdownFoodItemsPage}`]);

    this.setPreviousSelectedStartEndDateAndGroupBy();
    this.fetchArrLocationWithMenuItemServiceWasteAnalysis(
      selectedRestaurantService.arrLocationService
    );
    this.fetchWatchlistItems([selectedRestaurantService]);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      previousSelectedStartDate,
      previousSelectedEndDate,
      previousSelectedGroupBy,
      selectedRestaurantService,
      arrSelectedMenuItemServiceWasteAnalysis,
    } = this.state;
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;

    if (
      previousSelectedStartDate !== selectedStartDate ||
      previousSelectedEndDate !== selectedEndDate ||
      previousSelectedGroupBy !== selectedGroupBy
    ) {
      this.setPreviousSelectedStartEndDateAndGroupBy();
      this.fetchArrLocationWithMenuItemServiceWasteAnalysis(
        selectedRestaurantService.arrLocationService
      );
      this.fetchWatchlistItems([selectedRestaurantService]);
    }
    if (
      prevState.arrSelectedMenuItemServiceWasteAnalysis !== arrSelectedMenuItemServiceWasteAnalysis
    ) {
      this.getSelectedMenuItemWasteAnalysisForCharts();
    }
  }

  /**
   * Change selected location
   */
  onChangeDropdownList(event) {
    const { value } = event.target;

    const { arrLocationWithMenuItemServiceWasteAnalysis } = this.state;
    const selectedLocationWithMenuItemServiceWasteAnalysis =
      arrLocationWithMenuItemServiceWasteAnalysis.find(
        (locationWithMenuItemServiceWasteAnalysis) =>
          locationWithMenuItemServiceWasteAnalysis.locationId === value
      );
    this.setState({
      selectedLocationWithMenuItemServiceWasteAnalysis,
      topMenuItemWasteAnalysisForLineChart:
        selectedLocationWithMenuItemServiceWasteAnalysis.topMenuItemWasteAnalysisForLineChart,
      selectedMenuItemWasteAnalysisForLineChart:
        selectedLocationWithMenuItemServiceWasteAnalysis.topMenuItemWasteAnalysisForLineChart,
    });
  }

  onChangeToggleWeightCostButton(event, newSelectedToggleValue) {
    this.setState({ selectedToggleValueForWeightCost: newSelectedToggleValue });
  }

  onClickAwayTooltip() {
    this.closeTooltip();
  }

  onClickInfoIcon() {
    const { isTooltipOpened } = this.state;

    this.setState({ isTooltipOpened: !isTooltipOpened });
  }

  onCloseTooltip() {
    this.closeTooltip();
  }

  onClickClearSelectionButton() {
    this.clearSelectedRow();
  }

  /**
   * This function filters out menu item(s) that is already on the watchlist from arrSelectedMenuItemServiceWasteAnalysis and passes it to
   * the addToWatchlist function. Also set state to unselect the menu item(s).
   */
  onClickAddMenuItemToWatchlist() {
    const { arrMenuItemRestaurant, arrSelectedMenuItemServiceWasteAnalysis } = this.state;
    const arrMenuItemRestaurantToBeAdded = [];

    arrSelectedMenuItemServiceWasteAnalysis.forEach((selectedMenuItemServiceWasteAnalysis) => {
      const matchedMenuItemRestaurant = arrMenuItemRestaurant.find(
        (menuItemRestaurant) =>
          menuItemRestaurant.menuItemId === selectedMenuItemServiceWasteAnalysis.menuItemId &&
          menuItemRestaurant.serviceId === selectedMenuItemServiceWasteAnalysis.serviceId
      );
      if (!this.isMenuItemAlreadyOnWatchlist(matchedMenuItemRestaurant)) {
        arrMenuItemRestaurantToBeAdded.push(matchedMenuItemRestaurant);
      }
    });

    this.addToWatchlist(arrMenuItemRestaurantToBeAdded);
    this.setState({ arrSelectedMenuItemServiceWasteAnalysis: [] });
  }

  /**
   * This function is called when there is a change in the selection of table rows. If there is no selected table row, the
   * line chart will display the waste analysis of the top menu items. If there is row(s) selected, it will call the function to find and formulate the data to display the line chart for the specific selected menu items.
   */
  getSelectedMenuItemWasteAnalysisForCharts() {
    const { arrSelectedMenuItemServiceWasteAnalysis, topMenuItemWasteAnalysisForLineChart } =
      this.state;

    // If none of the rows in the table is checked, show default top 6 menu item waste analysis
    if (arrSelectedMenuItemServiceWasteAnalysis.length === 0) {
      this.setState({
        selectedMenuItemWasteAnalysisForLineChart: topMenuItemWasteAnalysisForLineChart,
      });
    } else {
      this.fetchSelectedMenuItemWasteAnalysisForCharts(arrSelectedMenuItemServiceWasteAnalysis);
    }
  }

  /**
   * Display DAY, WEEK, or MONTH depending on the context
   */
  getGroupByText() {
    const { selectedGroupBy } = this.context;
    if (selectedGroupBy === CONSTANT.groupByDay) {
      return 'DAY';
    }
    if (selectedGroupBy === CONSTANT.groupByWeek) {
      return 'WEEK';
    }
    return 'MONTH';
  }

  /**
   * Get an array of location for dropdown
   */
  getArrDropdownLocation() {
    const { selectedRestaurantService } = this.state;
    let arrDropdownItem = [];
    if (selectedRestaurantService) {
      arrDropdownItem = selectedRestaurantService.arrLocationService.map((locationService) => {
        return {
          value: locationService.locationId,
          name: locationService.name,
        };
      });
    }
    return arrDropdownItem;
  }

  setPreviousSelectedStartEndDateAndGroupBy() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    this.setState({
      previousSelectedStartDate: selectedStartDate,
      previousSelectedEndDate: selectedEndDate,
      previousSelectedGroupBy: selectedGroupBy,
    });
  }

  /**
   * This function fetches selectedMenuItemWasteAnalysisForCharts when menu item rows are selected and sets the
   * selectedMenuItemWasteAnalysisForLineChart state accordingly.
   * @param {typedefs.MenuItemServiceWasteAnalysis[]} arrSelectedMenuItemServiceWasteAnalysis - Array of menu item service waste analysis to chart into their group by format
   */
  async fetchSelectedMenuItemWasteAnalysisForCharts(arrSelectedMenuItemServiceWasteAnalysis) {
    const { openSnackbar } = this.context;
    const { auth0, history } = this.props;

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-selected-menu-item-waste-analysis-for-charts',
        {
          userId,
          arrMenuItemServiceWasteAnalysis: arrSelectedMenuItemServiceWasteAnalysis,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { selectedMenuItemWasteAnalysisForCharts } = response.data;
      const { menuItemWasteAnalysisForLineChart } = selectedMenuItemWasteAnalysisForCharts;
      this.setState({
        selectedMenuItemWasteAnalysisForLineChart: menuItemWasteAnalysisForLineChart,
      });
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during fetching selected menu item waste analysis for charts. Please notify admin.',
          'error'
        );
      }
    }
  }

  clearSelectedRow() {
    this.setState({
      arrSelectedMenuItemServiceWasteAnalysis: [],
    });
  }

  closeTooltip() {
    this.setState({ isTooltipOpened: false });
  }

  /**
   * Check if a menu item is already on the watchlist
   * @param {typedefs.MenuItemServiceWasteAnalysis} menuItemServiceWasteAnalysis - Menu item to be verified if it is already on the watchlist
   * @returns {boolean} Boolean value to indicate if menu item is already on the watchlist
   */
  isMenuItemAlreadyOnWatchlist(menuItemServiceWasteAnalysis) {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    const matchedWatchlistItemMenuItemRestaurantWasteAnalysis =
      arrWatchlistItemMenuItemRestaurantWasteAnalysis.find(
        (watchlistItemMenuItemRestaurantWasteAnalysis) =>
          menuItemServiceWasteAnalysis.menuItemName ===
          watchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis[0]
            .menuItemName
      );

    if (!matchedWatchlistItemMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    const matchedMenuItemRestaurantWasteAnalysis =
      matchedWatchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis.find(
        (menuItemRestaurantWasteAnalysis) =>
          menuItemRestaurantWasteAnalysis.menuItemId === menuItemServiceWasteAnalysis.menuItemId
      );

    if (!matchedMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    return true;
  }

  updateArrSelectedMenuItemServiceWasteAnalysis(arrNewlySelectedMenuItemServiceWasteAnalysis) {
    const { openSnackbar } = this.context;
    if (
      arrNewlySelectedMenuItemServiceWasteAnalysis.length >
      CONSTANT.foodItemsBreakdownSelectionMaxSize
    ) {
      openSnackbar(
        `You can only select up to ${CONSTANT.foodItemsBreakdownSelectionMaxSize} food items at a time`,
        'error'
      );
      return;
    }
    this.setState({
      arrSelectedMenuItemServiceWasteAnalysis: arrNewlySelectedMenuItemServiceWasteAnalysis,
    });
  }

  /**
   * Download table data in csv format. The fields include details of the menu item(s) of the selected location, which includes the
   * menu item name, service name, station, total weight, waste per cover, % change in waste per cover, total cost, cost per cover and % change in cost per cover.
   * Note: All numbers are rounded off to 2 dp
   */
  downloadTableData() {
    const { companyName, selectedEndDate, selectedStartDate } = this.context;
    const { selectedRestaurantName, selectedLocationWithMenuItemServiceWasteAnalysis } = this.state;
    const arrHeader = [
      'ITEM',
      'SERVICE',
      'STATION',
      'WEIGHT (KG)',
      'WASTE PER COVER (GRAMS)',
      '% CHANGE IN WASTE PER COVER',
      `COST (${selectedLocationWithMenuItemServiceWasteAnalysis.arrMenuItemServiceWasteAnalysis[0].currency})`,
      `COST PER COVER (${selectedLocationWithMenuItemServiceWasteAnalysis.arrMenuItemServiceWasteAnalysis[0].currency})`,
      '% CHANGE IN COST PER COVER',
    ];
    const arrRowData =
      selectedLocationWithMenuItemServiceWasteAnalysis.arrMenuItemServiceWasteAnalysis.map(
        (menuItemServiceWasteAnalysis) => {
          return {
            [`${arrHeader[0]}`]: menuItemServiceWasteAnalysis.menuItemName,
            [`${arrHeader[1]}`]: menuItemServiceWasteAnalysis.serviceName,
            [`${arrHeader[2]}`]: menuItemServiceWasteAnalysis.station,
            [`${arrHeader[3]}`]: menuItemServiceWasteAnalysis.weight.toFixed(2),
            [`${arrHeader[4]}`]:
              typeof menuItemServiceWasteAnalysis.wastePerCover === 'number'
                ? menuItemServiceWasteAnalysis.wastePerCover.toFixed(2)
                : menuItemServiceWasteAnalysis.wastePerCover,
            [`${arrHeader[5]}`]:
              typeof menuItemServiceWasteAnalysis.wastePerCoverDifference === 'number'
                ? menuItemServiceWasteAnalysis.wastePerCoverDifference.toFixed(2)
                : menuItemServiceWasteAnalysis.wastePerCoverDifference,
            [`${arrHeader[6]}`]: menuItemServiceWasteAnalysis.cost.toFixed(2),
            [`${arrHeader[7]}`]:
              typeof menuItemServiceWasteAnalysis.costPerCover === 'number'
                ? menuItemServiceWasteAnalysis.costPerCover.toFixed(2)
                : menuItemServiceWasteAnalysis.costPerCover,
            [`${arrHeader[8]}`]:
              typeof menuItemServiceWasteAnalysis.costPerCoverDifference === 'number'
                ? menuItemServiceWasteAnalysis.costPerCoverDifference.toFixed(2)
                : menuItemServiceWasteAnalysis.costPerCoverDifference,
          };
        }
      );
    const options = {
      filename: `${companyName}_${selectedRestaurantName}_${selectedLocationWithMenuItemServiceWasteAnalysis.name}_${selectedStartDate}_${selectedEndDate}_FoodItem`,
      quoteStrings: '',
      showLabels: true,
      columnHeaders: arrHeader,
    };

    const cvsConfig = mkConfig(options);
    const cvsData = generateCsv(cvsConfig)(arrRowData);
    download(cvsConfig)(cvsData);
  }

  /**
   * This function fetches the user's menu item restaurant and watchlist items on component mount and update
   * and sets the states arrMenuItemRestaurant and arrWatchlistItemMenuItemRestaurantWasteAnalysis accordingly
   */
  async fetchWatchlistItems(arrSelectedRestaurantService) {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { auth0, history } = this.props;
    renderLoaderAnimation(true);

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-arr-menu-item-restaurant-and-arr-watchlist-item-menu-item-restaurant-waste-analysis',
        {
          userId,
          arrRestaurantService: arrSelectedRestaurantService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { arrMenuItemRestaurant, arrWatchlistItemMenuItemRestaurantWasteAnalysis } =
        response.data;
      // arrMenuItemRestaurant is for addding menu item(s) to watchlist
      this.setState({
        arrMenuItemRestaurant,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      });
      renderLoaderAnimation(false);
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during loading for food items wastage. Please notify admin.',
          'error'
        );
      }
    }
  }

  /**
   * To send the menu item(s) to be added to the watchlist to the Backend for adding to the database. If the number of watchlist items is already at its maximum watchlist
   * size of 20, a snackbar will apear to inform the user and the menu item(s) will not be sent to the Backend.
   * ToDo: Shift this function to the child component during optimization (Ref: #85)
   * @param {typedefs.MenuItemRestaurant[]} arrMenuItemRestaurantToBeAdded - Array of menu item(s) to be added to watchlist
   */
  async addToWatchlist(arrMenuItemRestaurantToBeAdded) {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { arrMenuItemRestaurant, arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;
    const { auth0 } = this.props;

    if (arrWatchlistItemMenuItemRestaurantWasteAnalysis.length >= 20) {
      openSnackbar(
        `You can only have a maximum of ${CONSTANT.watchlistMaxSize} items in your watchlist`,
        'error'
      );
    } else if (arrMenuItemRestaurantToBeAdded.length === 0) {
      openSnackbar(`Selected food item(s) are already on the watchlist`, 'error');
    } else {
      try {
        renderLoaderAnimation(true);
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        const response = await axios.post(
          '/api/add-menu-items-to-watchlist',
          {
            userId,
            arrMenuItemRestaurantToBeAdded,
            arrWatchlistItemMenuItemRestaurantWasteAnalysis,
            arrAllMenuItemRestaurant: arrMenuItemRestaurant,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            groupBy: selectedGroupBy,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { data } = response;
        this.setState({
          arrWatchlistItemMenuItemRestaurantWasteAnalysis:
            data.arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        });
        renderLoaderAnimation(false);
        openSnackbar(
          'Successfully added ' +
            `${arrMenuItemRestaurantToBeAdded.length}` +
            ' food item' +
            `${arrMenuItemRestaurantToBeAdded.length > 1 ? 's' : ''}` +
            ' to the watchlist',
          'success'
        );
      } catch (error) {
        openSnackbar(
          'Unknown error during adding food item to watchlist. Please try again. If the problem persists, notify admin',
          'error'
        );
      }
    }
  }

  /**
   * This function fetches arrLocationWithMenuItemServiceWasteAnalysis on mount and update and sets the states
   * arrLocationWithMenuItemServiceWasteAnalysis, isArrLocationWithMenuItemServiceWasteAnalysisFetched and
   * selectedLocationWithMenuItemServiceWasteAnalysis accordingly.
   * @param {typedefs.locationService[]} arrLocationService - Array of locationService
   */
  async fetchArrLocationWithMenuItemServiceWasteAnalysis(arrLocationService) {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { auth0, history } = this.props;

    renderLoaderAnimation(true);

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-arr-location-with-menu-item-service-waste-analysis',
        {
          userId,
          arrLocationService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
          numberOfTopWastedFoodItemsToDisplay: CONSTANT.numberOfTopWastedFoodItemsToDisplay,
          isToIncludeTopWasteImage: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { arrLocationWithMenuItemServiceWasteAnalysis } = response.data;

      this.setState({
        arrLocationWithMenuItemServiceWasteAnalysis,
        isArrLocationWithMenuItemServiceWasteAnalysisFetched: true,
        selectedLocationWithMenuItemServiceWasteAnalysis:
          arrLocationWithMenuItemServiceWasteAnalysis[0],
        topMenuItemWasteAnalysisForLineChart:
          arrLocationWithMenuItemServiceWasteAnalysis[0].topMenuItemWasteAnalysisForLineChart,
        selectedMenuItemWasteAnalysisForLineChart:
          arrLocationWithMenuItemServiceWasteAnalysis[0].topMenuItemWasteAnalysisForLineChart,
        arrSelectedMenuItemServiceWasteAnalysis: [],
      });
      renderLoaderAnimation(false);
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during fetching food items waste analysis. Please notify admin.',
          'error'
        );
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {
      selectedLocationWithMenuItemServiceWasteAnalysis,
      isArrLocationWithMenuItemServiceWasteAnalysisFetched,
      selectedRestaurantName,
      arrSelectedMenuItemServiceWasteAnalysis,
      isTooltipOpened,
      selectedToggleValueForWeightCost,
      selectedMenuItemWasteAnalysisForLineChart,
    } = this.state;
    const { currency, selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;

    let xAxisDataForLineChart = [];
    let yAxisDataForLineChart = [];
    let labelDataForLineChart = [];
    if (isArrLocationWithMenuItemServiceWasteAnalysisFetched) {
      if (selectedToggleValueForWeightCost === 'weight') {
        xAxisDataForLineChart = selectedMenuItemWasteAnalysisForLineChart.byWeight.arrDate;
        yAxisDataForLineChart =
          selectedMenuItemWasteAnalysisForLineChart.byWeight.arrArrWeightByMenuItem;
        labelDataForLineChart = selectedMenuItemWasteAnalysisForLineChart.byWeight.arrMenuItemName;
      } else {
        xAxisDataForLineChart = selectedMenuItemWasteAnalysisForLineChart.byCost.arrDate;
        yAxisDataForLineChart =
          selectedMenuItemWasteAnalysisForLineChart.byCost.arrArrCostByMenuItem;
        labelDataForLineChart = selectedMenuItemWasteAnalysisForLineChart.byCost.arrMenuItemName;
      }
    }
    return (
      <Box>
        {isArrLocationWithMenuItemServiceWasteAnalysisFetched && (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            spacing={0}
            className={classes.rootGridContainer}
          >
            <Grid item className={classes.rootGridItem}>
              <Card className={classes.rootCard}>
                {/* Page title */}
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  spacing={0}
                  className={classes.rootCardGridContainer}
                >
                  <Grid item className={classes.rootCardGridItemTitle}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Grid item>
                        <Grid container>
                          <Typography variant="h1" color="primary" className={classes.spacedTitle}>
                            BREAKDOWN BY FOOD ITEMS
                          </Typography>
                          <ClickAwayListener onClickAway={() => this.onClickAwayTooltip()}>
                            <Tooltip
                              open={isTooltipOpened}
                              onClose={() => this.onCloseTooltip()}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                              title={
                                <Typography variant="caption">
                                  <p>This shows the wastage data of food items.</p>
                                  <p>
                                    You may check on multiple food items to add them to your
                                    watchlist.
                                  </p>
                                  <p>
                                    Percentage change values are derived from comparing data of the
                                    previous date range
                                    <b>
                                      {getPreviousDateRangeString(
                                        selectedStartDate,
                                        selectedEndDate,
                                        selectedGroupBy
                                      )}
                                    </b>{' '}
                                    and the current selected date range
                                    <b>{` ${format(
                                      new Date(selectedStartDate),
                                      'dd-MM-yyyy'
                                    )} — ${format(new Date(selectedEndDate), 'dd-MM-yyyy')}`}</b>
                                    .
                                  </p>
                                </Typography>
                              }
                            >
                              <InfoIcon
                                onClick={() => this.onClickInfoIcon()}
                                className={classes.tooltipIcon}
                              />
                            </Tooltip>
                          </ClickAwayListener>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          className={classes.downloadButton}
                          variant={
                            selectedLocationWithMenuItemServiceWasteAnalysis
                              .arrMenuItemServiceWasteAnalysis.length === 0
                              ? 'outlined'
                              : 'contained'
                          }
                          disabled={
                            selectedLocationWithMenuItemServiceWasteAnalysis
                              .arrMenuItemServiceWasteAnalysis.length === 0
                          }
                          onClick={() => this.downloadTableData()}
                        >
                          <GetAppRoundedIcon />
                          <Typography variant="body4">Download Table</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Location dropdown and weight/cost toggle */}
                  <Grid item className={classes.rootCardGridItemDefault}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <DropdownList
                          label="Location"
                          backgroundColor="white"
                          arrDropdownItem={this.getArrDropdownLocation()}
                          selectedValue={
                            selectedLocationWithMenuItemServiceWasteAnalysis.locationId
                          }
                          onChange={(event) => this.onChangeDropdownList(event)}
                        />
                      </Grid>
                      <Grid item>
                        <ToggleWeightCostButton
                          selectedToggleValue={selectedToggleValueForWeightCost}
                          onChange={(event, newSelectedToggleValue) =>
                            this.onChangeToggleWeightCostButton(event, newSelectedToggleValue)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Waste Analysis by menu items for Line Chart */}
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    className={classes.rootCardGridItemLineChart}
                  >
                    <Grid item className={classes.rootCardGridSubtitle}>
                      <Typography variant="h2" color="primary" align="center">
                        FOOD ITEMS WASTAGE BY {this.getGroupByText()}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <LineChart
                        xAxisData={xAxisDataForLineChart}
                        yAxisData={yAxisDataForLineChart}
                        yAxisLabel={
                          selectedToggleValueForWeightCost === 'weight'
                            ? 'WEIGHT (KG)'
                            : `COST (${currency})`
                        }
                        labelData={labelDataForLineChart}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rootCardGridItemDefault}>
                    <FoodItemsBreakdownTable
                      arrMenuItemServiceWasteAnalysis={
                        selectedLocationWithMenuItemServiceWasteAnalysis.arrMenuItemServiceWasteAnalysis
                      }
                      arrSelectedMenuItemServiceWasteAnalysis={
                        arrSelectedMenuItemServiceWasteAnalysis
                      }
                      restaurantName={selectedRestaurantName}
                      toggleValueForWeightCost={selectedToggleValueForWeightCost}
                      onClickClearSelection={() => this.onClickClearSelectionButton()}
                      onClickAdd={() => this.onClickAddMenuItemToWatchlist()}
                      updateArrSelectedMenuItemServiceWasteAnalysis={(
                        arrNewlySelectedMenuItemServiceWasteAnalysis
                      ) =>
                        this.updateArrSelectedMenuItemServiceWasteAnalysis(
                          arrNewlySelectedMenuItemServiceWasteAnalysis
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}
FoodItemsBreakdown.contextType = AppContext;

export default withAuth0(withStyles(styles)(FoodItemsBreakdown));
