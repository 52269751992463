import axios from 'axios';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Watchlist from '../home/watchlist/Watchlist';
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext, CONSTANT } from '../../AppContext';

const styles = (theme) => ({
  rootContainer: {
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    paddingTop: '5px',
    margin: '10px 0px',
  },
});

/**
 * Get the following arrays:
 * - An array of watchlist items where their ranks are to be updated, with the new ranks reflected, to be sent to Backend to be updated in the backend
 * - An array of all watchlist items with the affected ones updated with the new ranks, for the set state in the Frontend
 * @param {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis - Array of watchlist items with its menuItemRestaurantWasteAnalysis arranged according to the new order
 * @returns {Object} Object - Object containing arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend and arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
 * @returns {typedefs.WatchlistItemMenuItemRestaurant[]} arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend - Array of watchlist items with the updated rank and an empty array of menuItemRestaurant
 * @returns {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend - Array of watchlist items with the updated rank (those affected) and its array of menuItemRestaurantWasteAnalysis
 */
const getArrWatchlistItemMenuItemRestaurantWithUpdatedRankForFrontendAndBackend = (
  arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis
) => {
  const arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend = [];
  const arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend = [];

  arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis.forEach(
    (reorderedWatchlistItemMenuItemRestaurantWasteAnalysis, index) => {
      const newRank = index + 1;
      const watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend =
        reorderedWatchlistItemMenuItemRestaurantWasteAnalysis;
      if (reorderedWatchlistItemMenuItemRestaurantWasteAnalysis.rank !== newRank) {
        // Note that the update of rank has to be done within the if condition because watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
        // is a shallow copy of reorderedWatchlistItemMenuItemRestaurantWasteAnalysis, hence changing the rank out of the if condition will render the if
        // condition ineffective
        watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend.rank = newRank;
        // This watchlistItemMenuItemRestaurantForRankToBeUpdated object contains weight, cost and arrMenuItemRestaurantWasteAnalysis
        // which are not required for the updating of ranks in database, but kept in the object as it does not cause any other
        // complications so there is no need for extra code to remove them
        const watchlistItemMenuItemRestaurantForRankToBeUpdated = {
          ...watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
          arrMenuItemRestaurant: [],
        };
        arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend.push(
          watchlistItemMenuItemRestaurantForRankToBeUpdated
        );
      }
      arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend.push(
        watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
      );
    }
  );
  return {
    arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
    arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
  };
};

class WatchlistClickthrough extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      arrMenuItemRestaurant: [],
      arrWatchlistItemMenuItemRestaurantWasteAnalysis: [],
      previousSelectedStartDate: '',
      previousSelectedEndDate: '',
      previousSelectedGroupBy: '',
    };
  }

  componentDidMount() {
    const { setPageHistory } = this.context;

    setPageHistory([CONSTANT.watchlistPage]);

    this.setPreviousSelectedStartEndDateAndGroupBy();

    this.fetchWatchlistItems();
  }

  componentDidUpdate() {
    const { previousSelectedStartDate, previousSelectedEndDate, previousSelectedGroupBy } =
      this.state;
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;

    if (
      previousSelectedStartDate !== selectedStartDate ||
      previousSelectedEndDate !== selectedEndDate ||
      previousSelectedGroupBy !== selectedGroupBy
    ) {
      this.setPreviousSelectedStartEndDateAndGroupBy();

      this.fetchWatchlistItems();
    }
  }

  setPreviousSelectedStartEndDateAndGroupBy() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    this.setState({
      previousSelectedStartDate: selectedStartDate,
      previousSelectedEndDate: selectedEndDate,
      previousSelectedGroupBy: selectedGroupBy,
    });
  }

  /**
   * This function fetches the user's menu item restaurant and watchlist items on component mount and update
   * and sets the states arrMenuItemRestaurant and arrWatchlistItemMenuItemRestaurantWasteAnalysis accordingly
   */
  async fetchWatchlistItems() {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { arrRestaurantService, auth0, history } = this.props;
    renderLoaderAnimation(true);

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-arr-menu-item-restaurant-and-arr-watchlist-item-menu-item-restaurant-waste-analysis',
        {
          userId,
          arrRestaurantService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { arrMenuItemRestaurant, arrWatchlistItemMenuItemRestaurantWasteAnalysis } =
        response.data;
      // arrMenuItemRestaurant is for addding menu item(s) to watchlist
      this.setState({
        arrMenuItemRestaurant,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      });
      renderLoaderAnimation(false);
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar('Unknown error during fetching watchlist. Please notify admin.', 'error');
      }
    }
  }

  /**
   * To send the menu item(s) to be added to the watchlist to the Backend for adding to the database. If the number of watchlist items is already at its maximum watchlist
   * size of 20, a snackbar will apear to inform the user and the menu item(s) will not be sent to the Backend.
   * ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
   * @param {typedefs.MenuItemRestaurant[]} arrMenuItemRestaurantToBeAdded - Array of menu item(s) to be added to watchlist
   */
  async addToWatchlist(arrMenuItemRestaurantToBeAdded) {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { auth0, history } = this.props;
    const { arrMenuItemRestaurant, arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    if (arrWatchlistItemMenuItemRestaurantWasteAnalysis.length >= 20) {
      openSnackbar(
        `You can only have a maximum of ${CONSTANT.watchlistMaxSize} items in your watchlist`,
        'error'
      );
    } else {
      try {
        renderLoaderAnimation(true);
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        const response = await axios.post(
          '/api/add-menu-items-to-watchlist',
          {
            userId,
            arrMenuItemRestaurantToBeAdded,
            arrWatchlistItemMenuItemRestaurantWasteAnalysis,
            arrAllMenuItemRestaurant: arrMenuItemRestaurant,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            groupBy: selectedGroupBy,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { data } = response;
        this.setState({
          arrWatchlistItemMenuItemRestaurantWasteAnalysis:
            data.arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        });
        renderLoaderAnimation(false);
        openSnackbar(
          'Successfully added ' +
            `${arrMenuItemRestaurantToBeAdded.length}` +
            ' food item' +
            `${arrMenuItemRestaurantToBeAdded.length > 1 ? 's' : ''}` +
            ' to the watchlist',
          'success'
        );
      } catch (error) {
        const { response } = error;
        // Catch JWT web token error
        if (response && response.status === 401) {
          history.push('/login');
        } else {
          openSnackbar(
            'Unknown error during adding food item to watchlist. Please try again. If the problem persists, notify admin',
            'error'
          );
        }
      }
    }
  }

  // ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
  async deleteFromWatchlist(menuItemId, watchlistItemMenuItemRestaurantWasteAnalysis) {
    const { openSnackbar } = this.context;
    const { auth0, history } = this.props;
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/delete-menu-item-from-watchlist',
        {
          userId,
          menuItemIdToBeDeleted: menuItemId,
          watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted:
            watchlistItemMenuItemRestaurantWasteAnalysis,
          arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response;
      this.setState({
        arrWatchlistItemMenuItemRestaurantWasteAnalysis:
          data.arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      });
      openSnackbar('Successfully deleted 1 food item from the watchlist', 'success');
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during deleting food item from watchlist. Please try again. If the problem persists, notify admin',
          'error'
        );
      }
    }
  }

  /**
   * To send the watchlist items that have a change in rank the Backend for updating in the database. Only the watchlist items with a change in the rank will be sent to the Backend.
   * To reduce the waiting time for the updated display of watchlist items, the new array of watchlist items will be set state first, before axios.post is done.
   * ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
   * @param {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis - Array of watchlist items with its menuItemRestaurantWasteAnalysis arranged according to the new order
   */
  async updateWatchlist(arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis) {
    const { openSnackbar } = this.context;
    const { auth0, history } = this.props;

    try {
      const {
        arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
        arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
      } = getArrWatchlistItemMenuItemRestaurantWithUpdatedRankForFrontendAndBackend(
        arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis
      );
      // This setState is done out before the axios.post because the errors in updating the ranks in the database does not affect the the Frontend
      // display and setting the state only if the update of ranks in the database is successfully delays the display of the updated ranks
      this.setState({
        arrWatchlistItemMenuItemRestaurantWasteAnalysis:
          arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
      });
      if (arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend.length !== 0) {
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        await axios.post(
          'api/update-ranks-for-watchlist-items',
          {
            userId,
            arrWatchlistItemMenuItemRestaurantToBeUpdated:
              arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during updating rank for menu items on watchlist. Please try again. If the problem persists, notify admin',
          'error'
        );
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { arrMenuItemRestaurant, arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    return (
      <Box className={classes.rootContainer}>
        <Watchlist
          arrWatchlistItemMenuItemRestaurantWasteAnalysis={
            arrWatchlistItemMenuItemRestaurantWasteAnalysis
          }
          arrMenuItemRestaurant={arrMenuItemRestaurant}
          deleteFromWatchlist={(menuItemId, watchlistItemMenuItemRestaurantWasteAnalysis) =>
            this.deleteFromWatchlist(menuItemId, watchlistItemMenuItemRestaurantWasteAnalysis)
          }
          addToWatchlist={(arrMenuItemRestaurantToBeAdded) =>
            this.addToWatchlist(arrMenuItemRestaurantToBeAdded)
          }
          updateWatchlist={(arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis) =>
            this.updateWatchlist(arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis)
          }
        />
      </Box>
    );
  }
}

WatchlistClickthrough.contextType = AppContext;

export default withRouter(withAuth0(withStyles(styles)(WatchlistClickthrough)));
