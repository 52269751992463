import React, { createRef, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CommonFunctions from '../common/CommonFunctions';
import { AppContext } from '../../AppContext';

const { checkIsCostPerKilogramValid, checkIsMenuItemNameValid, checkIsStationValid } =
  CommonFunctions;

const NUMBER_OF_DISPLAYED_IMAGE = window.innerWidth < 768 ? 2 : 3;
const INPUT_TYPE = {
  menuItemName: 'menuItemName',
  station: 'station',
  costPerKilogram: 'costPerKilogram',
};
// STATE_TYPE constants are used by parent function's updateMapStateTypeByGroupId to indicate which parent state to update
const STATE_TYPE = {
  isError: 'isError',
  isEmpty: 'isEmpty',
  isPartiallyFilled: 'isPartiallyFilled',
};

const styles = (theme) => ({
  image: {
    width: '160px',
    height: '160px',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: '200px',
      height: '200px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      width: '270px',
      height: '270px',
    },
  },
  prevImgButton: {
    position: 'absolute',
    opacity: '80%',
    '&:hover': {
      backgroundColor: 'grey',
    },
    borderRadius: '0px',
  },
  nextImgButton: {
    position: 'absolute',
    opacity: '80%',
    '&:hover': {
      backgroundColor: 'grey',
    },
    left: `${160 * NUMBER_OF_DISPLAYED_IMAGE - 64}px`,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      left: `${200 * NUMBER_OF_DISPLAYED_IMAGE - 64}px`,
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      left: `${270 * NUMBER_OF_DISPLAYED_IMAGE - 64}px`,
    },
    borderRadius: '0px',
  },
  inputField: {
    backgroundColor: 'transparent',
  },
  inputGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '250px',
    width: '100%',
    [`@media (min-width: 960px)`]: {
      width: '70%',
    },
  },
  '@global': {
    '.MuiFormControl-root': {
      width: '100%',
    },
  },
  inputRow: {
    padding: '2px 20px',
  },
  errorMessage: {
    fontSize: '10px',
    color: 'red',
  },
  warningMessage: {
    fontSize: '10px',
    color: '#D1D119',
  },
  suggestedSpellingButton: {
    all: 'unset',
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  suggestedSpellingMessage: {
    fontSize: '10px',
  },
  inputCard: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      padding: '20px',
    },
    padding: '10px',
    margin: '10px 16px',
    boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.2)',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    justifyContent: 'space-around',
    flexDirection: 'column',
    [`@media (min-width: 1300px)`]: {
      flexDirection: 'row',
    },
  },
  dropdownList: {
    width: '560px',
    [`@media (min-width: 960px)`]: {
      width: '61%',
    },
    [`@media (min-width: 1300px)`]: {
      width: '378px',
    },
    position: 'absolute',
    zIndex: '1',
    overflow: 'auto',
    maxHeight: '140px',
    backgroundColor: '#a8a8a8',
    margin: '0px',
    '&:hover': {
      boxShadow: '10px',
    },
    listStyleType: 'none',
    padding: '0px',
    borderRadius: '0px 0px 6px 6px',
  },
  dropdownOption: {
    '&:hover': {
      backgroundColor: '#919191',
    },
    padding: '10px',
  },
  imageContainer: {
    backgroundColor: 'black',
    alignItems: 'flex-end',
  },
  inputWarning: {
    '& .MuiFormLabel-root.Mui-error': {
      color: '#D1D119',
    },
    '& .MuiInput-underline.Mui-error:after': {
      'border-bottom-color': '#D1D119',
    },
  },
});

/**
 * @typedef State
 * @prop {Array<number>} arrSelectedImageIndex - By default, this array is [0, 1, 2] to display first 3 images to user by index. On clicking next or previous button, image array is updated to reflect the image indexes displayed.
 * @prop {{menuItemName: boolean, station: boolean, costPerKilogram: boolean}} isValidByInputType - isValid state for all the input fields
 * @prop {{menuItemName: string, station: string, costPerKilogram: string}} errorMessageByInputType - errorMessage state for all the input fields
 * @prop {{menuItemName: boolean, station: boolean, costPerKilogram: boolean}} isEmptyByInputType - isEmpty state for all the input fields
 * @prop {{menuItemName: string, station: string}} suggestedSpellingByInputType - Suggested spelling for menuItemName and station, if available
 * @prop {{menuItemName: string, station: string}} previousInputByInputType - Previous input stored where if there is a change, the suggestion list needs to be updated as well
 * @prop {{menuItemName: boolean, station: boolean}} isSuggestionListOpenedByInputType - Boolean states of whether suggestion list is opened for menuItemName and station
 * @prop {{menuItemName: Array<string>, station: Array<string>}} arrFilteredOptionByInputType - Filtered arrays of suggestion list for menuItemName and station
 * @prop {{menuItemName: string, station: string, costPerKilogram: string | number, menuId: number}} inputByInputType - Stores all input made by user
 */

class MenuItemInput extends PureComponent {
  constructor(props) {
    super(props);
    const { defaultInput, mapMapExistingMenuItemByMenuIdByMenuItemName, menuId } = this.props;
    this.menuItemNameRef = createRef();
    this.stationRef = createRef();

    let isMenuItemAlreadyExistingInCurrentMenu = false;
    if (mapMapExistingMenuItemByMenuIdByMenuItemName.has(defaultInput.menuItemName)) {
      if (mapMapExistingMenuItemByMenuIdByMenuItemName.get(defaultInput.menuItemName).has(menuId)) {
        isMenuItemAlreadyExistingInCurrentMenu = true;
      }
    }
    /** @type {State} */
    this.state = {
      arrSelectedImageIndex: [...Array(NUMBER_OF_DISPLAYED_IMAGE)].map(
        (_, imageIndex) => imageIndex
      ),
      isValidByInputType: {
        menuItemName: !!defaultInput.menuItemName,
        station: !!defaultInput.station,
        costPerKilogram: !!defaultInput.costPerKilogram,
      },
      errorMessageByInputType: {
        menuItemName: '',
        station: '',
        costPerKilogram: '',
      },
      isEmptyByInputType: {
        menuItemName: !defaultInput.menuItemName,
        station: !defaultInput.station,
        costPerKilogram: !defaultInput.costPerKilogram,
      },
      suggestedSpellingByInputType: {
        menuItemName: '',
        station: '',
      },
      previousInputByInputType: {
        menuItemName: defaultInput.menuItemName,
        station: defaultInput.station,
      },
      isSuggestionListOpenedByInputType: {
        menuItemName: false,
        station: false,
      },
      arrFilteredOptionByInputType: {
        menuItemName: [],
        station: [],
      },
      inputByInputType: {
        menuId,
        menuItemName: defaultInput.menuItemName,
        station: defaultInput.station,
        costPerKilogram: defaultInput.costPerKilogram,
      },
      // Inputted Menu Item is considered already existing if there exists a current Menu Item with the same inputted Menu Item Name
      isMenuItemAlreadyExistingInCurrentMenu,
    };
  }

  componentDidMount() {
    const listener = (event) => {
      if (this.menuItemNameRef.current && !this.menuItemNameRef.current.contains(event.target)) {
        this.updateIsSuggestionListOpenedByInputType(INPUT_TYPE.menuItemName, false);
      }
      if (this.stationRef.current && !this.stationRef.current.contains(event.target)) {
        this.updateIsSuggestionListOpenedByInputType(INPUT_TYPE.station, false);
      }
    };
    document.addEventListener('click', listener);
    document.addEventListener('focusin', listener);
  }

  componentWillUnmount() {
    const listener = (event) => {
      if (!this.menuItemNameRef.current.contains(event.target)) {
        this.updateIsSuggestionListOpenedByInputType(INPUT_TYPE.menuItemName, false);
      }
      if (!this.stationRef.current.contains(event.target)) {
        this.updateIsSuggestionListOpenedByInputType(INPUT_TYPE.station, false);
      }
    };
    document.removeEventListener('click', listener);
    document.removeEventListener('focusin', listener);
  }

  /**
   * This function checks the spelling of an input menu item name or station and returns if spelling isValid and
   * if not, a suggested spelling
   * @param {string} value - Menu item name or station input
   * @returns {Array<boolean, string>} [isValidSpelling, suggestingSpelling] - Returns true if spelling is valid or no suggested
   *  spelling is found, and if spelling is not valid and a suggest spelling is found, return a string of suggested spelling
   */
  checkSpelling(value) {
    const { dictionary } = this.props;
    const arrWord = value.split(' ');
    let isValidSpelling = true;
    const arrSuggestedSpelling = [];
    arrWord.forEach((word) => {
      const wordCapitalised = word.charAt(0).toUpperCase() + word.slice(1);
      const isSpeltCorrectly = dictionary.check(wordCapitalised);
      if (isSpeltCorrectly) {
        arrSuggestedSpelling.push(wordCapitalised);
      } else {
        const suggestedSpelling = dictionary.suggest(wordCapitalised, 1);
        if (suggestedSpelling.length === 0) {
          arrSuggestedSpelling.push(wordCapitalised);
        } else {
          isValidSpelling = false;
          arrSuggestedSpelling.push(suggestedSpelling[0]);
        }
      }
    });
    const suggestedSpelling = arrSuggestedSpelling.join(' ');
    return [isValidSpelling, suggestedSpelling];
  }

  /**
   * This function is called when user clicks on either previous or next image button and sets the arrSelectedImageIndex state to display images
   * @param {boolean} isNext - True if next image, false if previous image
   */
  updateImage(isNext) {
    const { arrSelectedImageIndex } = this.state;
    const arrNewSelectedImageIndex = [...arrSelectedImageIndex];
    for (let imageIndex = 0; imageIndex < arrNewSelectedImageIndex.length; imageIndex += 1) {
      if (isNext) {
        arrNewSelectedImageIndex[imageIndex] += 1;
      } else {
        arrNewSelectedImageIndex[imageIndex] -= 1;
      }
    }
    this.setState({
      arrSelectedImageIndex: arrNewSelectedImageIndex,
    });
  }

  /**
   * This function is called onBlur of all input fields for checking (menuItemName, station or costPerKilogram) where
   * current and parent component states are updated
   * The following actions take place:
   * 1. Check if input is empty. If it is, isValid is marked false and error message is cleared for that field. If the input
   * card has been autopopulated from an existing menu item and the menu item name is removed, all input will also be removed
   * 2. If input is not empty, input is validated against its individual input requirements. For costPerKilogram, if it is a valid
   * number, it is converted to a number and updated to input state
   * 3. If input is either menuItemName or station, its dropdown list is updated to remove the previous input and updated with the
   * new input if there was a change
   * 4. Spelling for menuItemName or station is then checked and suggested spellings are recommended (if mispelt and recommendations are available)
   * 5. If menu item name is strictly equal to a menu item name of an existing menu item, its station and costPerKilogram are
   * automatically populated and if the menu item is part of the same menu, then station and costPerKilogram inputs will also be disabled
   * Any changes to the inputted menu item name thereafter will also remove the auto-populated 'station' and 'costPerKilogram'
   * @param {string} inputType - 'menuItemName' / 'station' / 'costPerKilogram'
   * @param {string} value - Menu item name, station or station input
   */
  checkInputField(inputType, value) {
    const {
      groupId,
      updateMapInputCountByInputByInputType,
      updateMapInputByGroupId,
      updateMapStateTypeByGroupId,
      mapMapExistingMenuItemByMenuIdByMenuItemName,
      menuId,
    } = this.props;
    const {
      errorMessageByInputType,
      inputByInputType,
      isEmptyByInputType,
      isValidByInputType,
      previousInputByInputType,
      suggestedSpellingByInputType,
      isMenuItemAlreadyExistingInCurrentMenu,
    } = this.state;
    const updatedInputByInputType = { ...inputByInputType };
    const isUpdatedValidByInputType = { ...isValidByInputType };
    const isUpdatedEmptyByInputType = { ...isEmptyByInputType };
    const updatedErrorMessageByInputType = { ...errorMessageByInputType };
    const updatedSuggestedSpellingByInputType = { ...suggestedSpellingByInputType };
    let isUpdatedMenuItemAlreadyExistingInCurrentMenu = isMenuItemAlreadyExistingInCurrentMenu;

    // Value passed in is first trimmed, such as to remove all spaces for isEmpty check below to run as intended
    const valueTrimmed = value.trim();

    // Check isEmpty
    if (valueTrimmed === '') {
      isUpdatedEmptyByInputType[inputType] = true;
      updatedInputByInputType[inputType] = valueTrimmed;
      if (inputType === INPUT_TYPE.menuItemName && isMenuItemAlreadyExistingInCurrentMenu) {
        isUpdatedMenuItemAlreadyExistingInCurrentMenu = false;
        updatedInputByInputType[INPUT_TYPE.station] = '';
        updatedInputByInputType[INPUT_TYPE.costPerKilogram] = '';
        isUpdatedEmptyByInputType[INPUT_TYPE.station] = true;
        isUpdatedEmptyByInputType[INPUT_TYPE.costPerKilogram] = true;
        updatedSuggestedSpellingByInputType[INPUT_TYPE.station] = '';
        updatedSuggestedSpellingByInputType[INPUT_TYPE.costPerKilogram] = '';
      }
    } else {
      isUpdatedEmptyByInputType[inputType] = false;
    }

    // Check isValid
    if (!isUpdatedEmptyByInputType[inputType]) {
      if (inputType === INPUT_TYPE.menuItemName) {
        const [isMenuItemNameValid, menuItemNameCheckOutput] =
          checkIsMenuItemNameValid(valueTrimmed);
        isUpdatedValidByInputType[inputType] = isMenuItemNameValid;
        if (isMenuItemNameValid) {
          updatedErrorMessageByInputType[inputType] = '';
          updatedInputByInputType[INPUT_TYPE.menuItemName] = menuItemNameCheckOutput;

          // Check if an existing menu item exists for autopopulation of station and costPerKilogram
          let existingMenuItem = null;
          const mapExistingMenuItemByMenuId =
            mapMapExistingMenuItemByMenuIdByMenuItemName.get(menuItemNameCheckOutput);
          if (mapExistingMenuItemByMenuId) {
            // If the existing menu item is part of the current menu id, then take as menu item, else take the first value
            if (mapExistingMenuItemByMenuId.has(menuId)) {
              existingMenuItem = mapExistingMenuItemByMenuId.get(menuId);
            } else {
              [existingMenuItem] = mapExistingMenuItemByMenuId.values();
            }
          }
          if (existingMenuItem) {
            const { station, costPerKilogram } = existingMenuItem;
            updatedInputByInputType[INPUT_TYPE.station] = station;
            updatedInputByInputType[INPUT_TYPE.costPerKilogram] = costPerKilogram;
            isUpdatedEmptyByInputType[INPUT_TYPE.station] = false;
            isUpdatedEmptyByInputType[INPUT_TYPE.costPerKilogram] = false;
            updatedSuggestedSpellingByInputType[INPUT_TYPE.station] = '';
            updatedSuggestedSpellingByInputType[INPUT_TYPE.costPerKilogram] = '';
            if (existingMenuItem.menuId === menuId) {
              isUpdatedMenuItemAlreadyExistingInCurrentMenu = true;
            }
          } else {
            isUpdatedMenuItemAlreadyExistingInCurrentMenu = false;
            if (isMenuItemAlreadyExistingInCurrentMenu) {
              updatedInputByInputType[INPUT_TYPE.station] = '';
              updatedInputByInputType[INPUT_TYPE.costPerKilogram] = '';
              isUpdatedEmptyByInputType[INPUT_TYPE.station] = true;
              isUpdatedEmptyByInputType[INPUT_TYPE.costPerKilogram] = true;
            }
          }
        } else {
          updatedErrorMessageByInputType[inputType] = menuItemNameCheckOutput;
        }
      } else if (inputType === INPUT_TYPE.station) {
        const [isStationValid, stationCheckOutput] = checkIsStationValid(valueTrimmed);
        isUpdatedValidByInputType[inputType] = isStationValid;
        if (isStationValid) {
          updatedErrorMessageByInputType[inputType] = '';
          updatedInputByInputType[INPUT_TYPE.station] = stationCheckOutput;
        } else {
          updatedErrorMessageByInputType[inputType] = stationCheckOutput;
        }
      } else if (inputType === INPUT_TYPE.costPerKilogram) {
        isUpdatedValidByInputType[inputType] = checkIsCostPerKilogramValid(value);
        if (isUpdatedValidByInputType[inputType]) {
          updatedErrorMessageByInputType[inputType] = '';
          updatedInputByInputType[INPUT_TYPE.costPerKilogram] = Number(value);
        } else {
          updatedErrorMessageByInputType[inputType] =
            'Cost per kilogram must be a valid positive number';
        }
      }
    } else {
      isUpdatedValidByInputType[inputType] = false;
      updatedErrorMessageByInputType[inputType] = '';
    }

    // Replace dropdown list options if inputType is menuItemName or station and value was changed
    if (
      (inputType === INPUT_TYPE.menuItemName || inputType === INPUT_TYPE.station) &&
      previousInputByInputType[inputType] !== updatedInputByInputType[inputType]
    ) {
      updateMapInputCountByInputByInputType(
        inputType,
        previousInputByInputType[inputType],
        updatedInputByInputType[inputType]
      );
    }
    previousInputByInputType[inputType] = updatedInputByInputType[inputType];

    // Check spelling and suggest spelling if mispelt and suggestedSpelling is available
    if (inputType === INPUT_TYPE.menuItemName || inputType === INPUT_TYPE.station) {
      const [isValidSpelling, suggestedSpelling] = this.checkSpelling(value);
      if (!isValidSpelling) {
        updatedSuggestedSpellingByInputType[inputType] = suggestedSpelling;
      } else {
        updatedSuggestedSpellingByInputType[inputType] = '';
      }
    }

    // Update current component's states
    this.setState({
      errorMessageByInputType: updatedErrorMessageByInputType,
      inputByInputType: updatedInputByInputType,
      isValidByInputType: isUpdatedValidByInputType,
      isEmptyByInputType: isUpdatedEmptyByInputType,
      suggestedSpellingByInputType: updatedSuggestedSpellingByInputType,
      isMenuItemAlreadyExistingInCurrentMenu: isUpdatedMenuItemAlreadyExistingInCurrentMenu,
    });

    // Update parent component's state of isError, isEmpty and mapInput by internal menu item id
    const hasErrorMessage = Boolean(
      updatedErrorMessageByInputType.menuItemName ||
        updatedErrorMessageByInputType.station ||
        updatedErrorMessageByInputType.costPerKilogram
    );
    const hasAllEmptyFields = Boolean(
      isUpdatedEmptyByInputType.menuItemName &&
        isUpdatedEmptyByInputType.station &&
        isUpdatedEmptyByInputType.costPerKilogram
    );
    const hasPartialEmptyFields = !(
      hasAllEmptyFields ||
      Boolean(
        !isUpdatedEmptyByInputType.menuItemName &&
          !isUpdatedEmptyByInputType.station &&
          !isUpdatedEmptyByInputType.costPerKilogram
      )
    );
    updateMapStateTypeByGroupId(groupId, hasErrorMessage, STATE_TYPE.isError);
    updateMapStateTypeByGroupId(groupId, hasPartialEmptyFields, STATE_TYPE.isPartiallyFilled);
    updateMapStateTypeByGroupId(groupId, hasAllEmptyFields, STATE_TYPE.isEmpty);
    updateMapInputByGroupId(groupId, updatedInputByInputType);
  }

  /**
   * This function is called when user selects the suggest spelling for menuItemName or station
   * The suggestedSpelling is updated to the input field and suggestedSpellingByInputType state is cleared
   * @param {string} inputType - 'menuItemName' / 'station'
   */
  selectSuggestedSpelling(inputType) {
    const { suggestedSpellingByInputType } = this.state;
    this.setState({
      suggestedSpellingByInputType: { ...suggestedSpellingByInputType, [inputType]: '' },
    });
    this.checkInputField(inputType, suggestedSpellingByInputType[inputType]);
  }

  /**
   * This function updates the isSuggestionListOpenedByInputType state for either menuItemName or station
   * @param {string} inputType - 'menuItemName' / 'station'
   * @param {boolean} isNewShowOption - True if to show options, false if to not show options
   */
  updateIsSuggestionListOpenedByInputType(inputType, newIsSuggestionListOpened) {
    const { isSuggestionListOpenedByInputType } = this.state;
    this.setState({
      isSuggestionListOpenedByInputType: {
        ...isSuggestionListOpenedByInputType,
        [inputType]: newIsSuggestionListOpened,
      },
    });
  }

  /**
   * This function is called when user selects an option on the dropdown list for menuItemName or station
   * This function updates the input field and the isSuggestionListOpenedByInputType state to false
   * @param {string} inputType - 'menuItemName' / 'station
   * @param {string} selectedOption - Selected option to replace input field
   */
  selectDropdownOption(inputType, selectedOption) {
    this.checkInputField(inputType, selectedOption);
    this.updateIsSuggestionListOpenedByInputType(inputType, false);
  }

  /**
   * This function is called on change of all input fields (menuItemName, station or costPerKilogram)
   * It updates the current component's input state and filters the dropdown list as a search feature
   * @param {string} inputType - 'menuItemName' / 'station' / 'costPerKilogram'
   * @param {string} value - Menu item name, station or costPerKilogram input
   */
  updateInputField(inputType, value) {
    const { inputByInputType } = this.state;
    this.setState({ inputByInputType: { ...inputByInputType, [inputType]: value } });
    if (inputType === INPUT_TYPE.menuItemName || inputType === INPUT_TYPE.station) {
      this.updateArrFilteredOptionByInputType(inputType, value);
    }
  }

  /**
   * This function updates the filtered options available to user to select from dropdown list sorted in alphabetical order
   * It is called on onFocus and onChange of menuItemName and station input fields, which provides a search functionality where an array
   * of substrings are set in arrFilteredOptionByInputType state to be selected by the user
   * @param {string} inputType - 'menuItemName' / 'station'
   * @param {string} value - Menu item name or station input
   */
  updateArrFilteredOptionByInputType(inputType, value) {
    const { mapInputCountByInputByInputType } = this.props;
    const { arrFilteredOptionByInputType } = this.state;
    const arrFilteredOption = Array.from(mapInputCountByInputByInputType[inputType].keys())
      .sort()
      .filter((option) => option.toLowerCase().includes(value.toLowerCase()));
    this.setState({
      arrFilteredOptionByInputType: {
        ...arrFilteredOptionByInputType,
        [inputType]: arrFilteredOption,
      },
    });
  }

  render() {
    const { arrImageURL, classes, groupId, arrInternalMenuItemIdWasteId } = this.props;
    const {
      arrFilteredOptionByInputType,
      arrSelectedImageIndex,
      errorMessageByInputType,
      isEmptyByInputType,
      isSuggestionListOpenedByInputType,
      inputByInputType,
      suggestedSpellingByInputType,
      isMenuItemAlreadyExistingInCurrentMenu,
    } = this.state;
    const { currency } = this.context;

    return (
      <Grid
        container
        item
        alignItems="center"
        wrap="nowrap"
        className={`menuItemInputCard ${classes.inputCard}`}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle2" color="textSecondary">
            {arrInternalMenuItemIdWasteId
              .map((internalMenuItemIdWasteId) => internalMenuItemIdWasteId.internalMenuItemId)
              .join(', ')}
          </Typography>
          <Box display="flex" position="relative" className={classes.imageContainer}>
            <Button
              className={classes.prevImgButton}
              onClick={() => this.updateImage(false)}
              disabled={arrSelectedImageIndex[0] === 0}
              variant="contained"
              disableElevation
            >
              {'<'}
            </Button>
            {arrSelectedImageIndex.map((selectedImageIndex) => (
              <img
                key={`${groupId}_${selectedImageIndex}`}
                src={arrImageURL[selectedImageIndex]}
                alt=""
                className={classes.image}
              />
            ))}
            <Button
              className={classes.nextImgButton}
              onClick={() => this.updateImage(true)}
              disabled={
                arrSelectedImageIndex[arrSelectedImageIndex.length - 1] >= arrImageURL.length - 1
              }
              variant="contained"
              disableElevation
            >
              {'>'}
            </Button>
          </Box>
        </Box>
        <Grid className={classes.inputGrid}>
          <Grid item className={classes.inputRow} ref={this.menuItemNameRef}>
            <TextField
              label="Menu Item Name"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              spellCheck={false}
              value={inputByInputType.menuItemName}
              onChange={(event) => {
                this.updateInputField(INPUT_TYPE.menuItemName, event.target.value);
              }}
              error={
                errorMessageByInputType.menuItemName ||
                (isEmptyByInputType.menuItemName &&
                  (!isEmptyByInputType.station || !isEmptyByInputType.costPerKilogram))
              }
              onBlur={(event) => this.checkInputField(INPUT_TYPE.menuItemName, event.target.value)}
              onFocus={(event) => {
                this.updateIsSuggestionListOpenedByInputType(INPUT_TYPE.menuItemName, true);
                this.updateArrFilteredOptionByInputType(
                  INPUT_TYPE.menuItemName,
                  event.target.value
                );
              }}
              className={`menuItemNameInput ${classes.inputField} ${
                isEmptyByInputType.menuItemName &&
                (!isEmptyByInputType.station || !isEmptyByInputType.costPerKilogram)
                  ? classes.inputWarning
                  : ''
              }`}
            />
            {isSuggestionListOpenedByInputType.menuItemName && (
              <ul className={classes.dropdownList}>
                {arrFilteredOptionByInputType.menuItemName.map((option) => (
                  <li
                    key={`${option}`}
                    onClick={() => this.selectDropdownOption(INPUT_TYPE.menuItemName, option)}
                    className={classes.dropdownOption}
                    aria-hidden="true"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            )}
            {!suggestedSpellingByInputType.menuItemName || errorMessageByInputType.menuItemName ? (
              <FormHelperText
                className={
                  isEmptyByInputType.menuItemName &&
                  (!isEmptyByInputType.station || !isEmptyByInputType.costPerKilogram)
                    ? classes.warningMessage
                    : classes.errorMessage
                }
              >
                {isEmptyByInputType.menuItemName &&
                (!isEmptyByInputType.station || !isEmptyByInputType.costPerKilogram)
                  ? 'Kindly fill in the above field'
                  : `${errorMessageByInputType.menuItemName} `}
              </FormHelperText>
            ) : (
              <FormHelperText className={classes.suggestedSpellingMessage}>
                Did you mean{' '}
                <button
                  type="button"
                  className={classes.suggestedSpellingButton}
                  onClick={() => this.selectSuggestedSpelling(INPUT_TYPE.menuItemName)}
                >
                  {suggestedSpellingByInputType.menuItemName}
                </button>
                ?
              </FormHelperText>
            )}
          </Grid>
          <Grid item className={classes.inputRow} ref={this.stationRef}>
            <TextField
              label="Food Station"
              fullWidth
              value={inputByInputType.station}
              InputLabelProps={{
                shrink: true,
              }}
              Input
              spellCheck={false}
              onChange={(event) => {
                this.updateInputField(INPUT_TYPE.station, event.target.value);
              }}
              error={
                errorMessageByInputType.station ||
                (isEmptyByInputType.station &&
                  (!isEmptyByInputType.menuItemName || !isEmptyByInputType.costPerKilogram))
              }
              onBlur={(event) => {
                this.checkInputField(INPUT_TYPE.station, event.target.value);
              }}
              onFocus={(event) => {
                this.updateIsSuggestionListOpenedByInputType(INPUT_TYPE.station, true);
                this.updateArrFilteredOptionByInputType(INPUT_TYPE.station, event.target.value);
              }}
              className={`stationInput ${classes.inputField} ${
                isEmptyByInputType.station &&
                (!isEmptyByInputType.menuItemName || !isEmptyByInputType.costPerKilogram)
                  ? classes.inputWarning
                  : ''
              }`}
              disabled={isMenuItemAlreadyExistingInCurrentMenu}
            />
            {isSuggestionListOpenedByInputType.station && (
              <ul className={classes.dropdownList}>
                {arrFilteredOptionByInputType.station.map((option) => (
                  <li
                    key={`${option}`}
                    onClick={() => this.selectDropdownOption(INPUT_TYPE.station, option)}
                    className={classes.dropdownOption}
                    aria-hidden="true"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            )}
            {!suggestedSpellingByInputType.station || errorMessageByInputType.station ? (
              <FormHelperText
                className={
                  isEmptyByInputType.station &&
                  (!isEmptyByInputType.menuItemName || !isEmptyByInputType.costPerKilogram)
                    ? classes.warningMessage
                    : classes.errorMessage
                }
              >
                {isEmptyByInputType.station &&
                (!isEmptyByInputType.menuItemName || !isEmptyByInputType.costPerKilogram)
                  ? 'Kindly fill in the above field'
                  : `${errorMessageByInputType.station} `}
              </FormHelperText>
            ) : (
              <FormHelperText className={classes.suggestedSpellingMessage}>
                Did you mean{' '}
                <button
                  type="button"
                  className={classes.suggestedSpellingButton}
                  onClick={() => this.selectSuggestedSpelling(INPUT_TYPE.station)}
                >
                  {suggestedSpellingByInputType.station}
                </button>
                ?
              </FormHelperText>
            )}
          </Grid>
          <Grid item className={classes.inputRow}>
            <FormControl
              className={`${
                isEmptyByInputType.costPerKilogram &&
                (!isEmptyByInputType.menuItemName || !isEmptyByInputType.station)
                  ? classes.inputWarning
                  : ''
              }`}
            >
              <InputLabel
                error={
                  errorMessageByInputType.costPerKilogram ||
                  (isEmptyByInputType.costPerKilogram &&
                    (!isEmptyByInputType.menuItemName || !isEmptyByInputType.station))
                }
                shrink
              >
                Cost per Kilogram
              </InputLabel>
              <Input
                value={inputByInputType.costPerKilogram}
                endAdornment={<InputAdornment position="end">{currency}</InputAdornment>}
                onChange={(event) => {
                  this.updateInputField(INPUT_TYPE.costPerKilogram, event.target.value);
                }}
                spellCheck={false}
                error={
                  errorMessageByInputType.costPerKilogram ||
                  (isEmptyByInputType.costPerKilogram &&
                    (!isEmptyByInputType.menuItemName || !isEmptyByInputType.station))
                }
                onBlur={(event) =>
                  this.checkInputField(INPUT_TYPE.costPerKilogram, event.target.value)
                }
                fullWidth
                className={`costPerKilogramInput ${classes.inputField}`}
                disabled={isMenuItemAlreadyExistingInCurrentMenu}
              />
              <FormHelperText
                className={
                  isEmptyByInputType.costPerKilogram &&
                  (!isEmptyByInputType.menuItemName || !isEmptyByInputType.station)
                    ? classes.warningMessage
                    : classes.errorMessage
                }
              >
                {isEmptyByInputType.costPerKilogram &&
                (!isEmptyByInputType.menuItemName || !isEmptyByInputType.station)
                  ? 'Kindly fill in the above field'
                  : `${errorMessageByInputType.costPerKilogram} `}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

MenuItemInput.contextType = AppContext;

export default withRouter(withStyles(styles)(MenuItemInput));
