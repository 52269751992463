import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { withAuth0 } from '@auth0/auth0-react';

import { AppContext } from '../../AppContext';
import Image from '../../imgs/Lumitics-Page-Header.jpg';

const styles = (theme) => ({
  rootGrid: {
    height: '100%',
  },
  /**
   * Lumitics logo
   */
  logoGridItem: {
    background: theme.palette.primary.main,
    width: '100%',
    textAlign: 'center', // Align title logo to center
    [theme.breakpoints.down('sm')]: {
      height: '30%',
    },
    [theme.breakpoints.up('sm')]: {
      height: '40%',
    },
  },
  logoStyle: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      objectFit: 'cover',
    },
    [theme.breakpoints.up('sm')]: {
      objectFit: 'scale-down',
    },
  },
  /**
   * Login form
   */
  bottomPartBackgroundContainer: {
    background:
      'linear-gradient(180deg, #102547 0%, #152A4F 25%, #17305C 50%, #1C396A 75%, #214079 100%)',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '70%',
    },
    [theme.breakpoints.up('sm')]: {
      height: '60%',
    },
  },
  gridItem: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  // // Login button
  loginButton: {
    backgroundColor: theme.palette.info.main,
  },
  loginButtonText: {
    color: '#FFFFFF',
  },
  containedButton: {
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
  },
});

class LoginPage extends PureComponent {
  componentDidMount() {
    document.title = 'Login | Lumitics | Towards Zero Food Waste';
    const { renderLoaderAnimation } = this.context;
    renderLoaderAnimation(false);
  }

  render() {
    const { auth0, classes } = this.props;
    const { loginWithRedirect } = auth0;

    return (
      <>
        <Grid container spacing={0} className={classes.rootGrid}>
          {/* Title logo */}
          <Grid item xs={12} lg={12} className={classes.logoGridItem}>
            <img src={Image} className={classes.logoStyle} alt="Lumitics" />
          </Grid>

          {/* Bottom container */}
          <Grid item className={classes.bottomPartBackgroundContainer}>
            <Grid container direction="column" alignItems="center">
              {/* A very inspiring quote */}
              <Grid item className={classes.gridItem}>
                <Typography variant="body2" color="secondary" align="center">
                  The journey to zero food waste begins
                </Typography>
              </Grid>

              {/* Login button */}
              <Grid item className={classes.gridItem}>
                <Button
                  variant="contained"
                  className={`loginButton ${classes.loginButton}`}
                  classes={{ contained: classes.containedButton }}
                  onClick={async () => {
                    await loginWithRedirect({
                      appState: {
                        returnTo: '/callback',
                      },
                    });
                  }}
                >
                  <Typography className={classes.loginButtonText}>CLICK HERE TO LOGIN</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

LoginPage.contextType = AppContext;

export default withRouter(withAuth0(withStyles(styles)(LoginPage)));
