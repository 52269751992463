import React, { PureComponent } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CustomTable from '../../common/CustomTable';

const styles = (theme) => ({
  componentTitle: {
    color: theme.palette.primary.main,
    paddingBottom: '12px',
  },
});

class WastageByServiceTable extends PureComponent {
  render() {
    const { classes, toggleValueForWeightCost, selectedLocationWasteAnalysisForHighlights } =
      this.props;

    const weightCostHeader = toggleValueForWeightCost === 'weight' ? 'WEIGHT' : 'COST';
    const arrTableHeader = [
      { id: 'groupName', label: 'SERVICE' },
      { id: toggleValueForWeightCost, label: weightCostHeader },
    ];

    let arrTableRow = [];
    if (selectedLocationWasteAnalysisForHighlights) {
      if (toggleValueForWeightCost === 'weight') {
        arrTableRow =
          selectedLocationWasteAnalysisForHighlights.wasteAnalysisForServiceTable.byWeight;
      } else {
        arrTableRow =
          selectedLocationWasteAnalysisForHighlights.wasteAnalysisForServiceTable.byCost;
      }
    }

    const arrTableAlignment = ['left', 'right'];
    const arrTableWrap = [true, false];
    const arrIdKey = ['serviceName'];

    return (
      <Box className="wastageByServiceTable" id="wastage-by-service-table">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography className={classes.componentTitle} display="inline" variant="h1">
            WASTAGE BY SERVICE
          </Typography>
        </Grid>
        <CustomTable
          arrTableHeader={arrTableHeader}
          arrTableRow={arrTableRow}
          arrTableAlignment={arrTableAlignment}
          arrTableWrap={arrTableWrap}
          toggleValueForWeightCost={toggleValueForWeightCost}
          arrIdKey={arrIdKey}
          isGrouped
        />
      </Box>
    );
  }
}

export default withStyles(styles)(WastageByServiceTable);
