import axios from 'axios';
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { Box, Grid } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import KeyOverview from './KeyOverview';
import RestaurantBreakdown from './restaurant-breakdown/RestaurantBreakdown';
import Watchlist from './watchlist/Watchlist';
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext, CONSTANT } from '../../AppContext';

const styles = (theme) => ({
  rootGridContainer: {
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  rootGridItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  leftColumnContainer: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '15px',
    },
  },
  rightColumnContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
    },
  },
  stickyWatchlist: {
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
    },
  },
  menuItemMappingWarning: {
    borderRadius: '10px',
  },
});

/**
 * Get the following arrays:
 * - An array of watchlist items where their ranks are to be updated, with the new ranks reflected, to be sent to Backend to be updated in the backend
 * - An array of all watchlist items with the affected ones updated with the new ranks, for the set state in the Frontend
 * @param {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis - Array of watchlist items with its menuItemRestaurantWasteAnalysis arranged according to the new order
 * @returns {Object} Object - Object containing arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend and arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
 * @returns {typedefs.WatchlistItemMenuItemRestaurant[]} arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend - Array of watchlist items with the updated rank and an empty array of menuItemRestaurant
 * @returns {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend - Array of watchlist items with the updated rank (those affected) and its array of menuItemRestaurantWasteAnalysis
 */
const getArrWatchlistItemMenuItemRestaurantWithUpdatedRankForFrontendAndBackend = (
  arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis
) => {
  const arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend = [];
  const arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend = [];

  arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis.forEach(
    (reorderedWatchlistItemMenuItemRestaurantWasteAnalysis, index) => {
      const newRank = index + 1;
      const watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend =
        reorderedWatchlistItemMenuItemRestaurantWasteAnalysis;
      if (reorderedWatchlistItemMenuItemRestaurantWasteAnalysis.rank !== newRank) {
        // Note that the update of rank has to be done within the if condition because watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
        // is a shallow copy of reorderedWatchlistItemMenuItemRestaurantWasteAnalysis, hence changing the rank out of the if condition will render the if
        // condition ineffective
        watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend.rank = newRank;
        // This watchlistItemMenuItemRestaurantForRankToBeUpdated object contains weight, cost and arrMenuItemRestaurantWasteAnalysis
        // which are not required for the updating of ranks in database, but kept in the object as it does not cause any other
        // complications so there is no need for extra code to remove them
        const watchlistItemMenuItemRestaurantForRankToBeUpdated = {
          ...watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
          arrMenuItemRestaurant: [],
        };
        arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend.push(
          watchlistItemMenuItemRestaurantForRankToBeUpdated
        );
      }
      arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend.push(
        watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
      );
    }
  );
  return {
    arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
    arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
  };
};

/**
 * Check if there is any data for the line chart to be display on the Key Overview
 * @param {typedefs.WasteAnalysisForKeyOverview} wasteAnalysisForKeyOverview - Waste analysis for key overview
 */
const checkShouldKeyOverviewLineChart = (wasteAnalysisForKeyOverview) => {
  return wasteAnalysisForKeyOverview.wasteAnalysisForLineChart.arrDate.length > 1;
};

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // If fetching data is done
      isWasteAnalysisForHighlightsFetched: false,

      // Waste Analysis For Highlights
      arrRestaurantWasteAnalysisForHighlights: [],
      wasteAnalysisForKeyOverview: {},
      arrMenuItemRestaurant: [],
      arrWatchlistItemMenuItemRestaurantWasteAnalysis: [],

      // To store previous start date and end date for condition for componentDidUpdate
      previousSelectedStartDate: '',
      previousSelectedEndDate: '',
      previousSelectedGroupBy: '',

      // Parameters for Restaurant Breakdown
      isTooltipForRestaurantBreakdownOpened: false,
      arrSelectedMenuItemServiceWasteAnalysis: [],
      restaurantTabValue: 0,
      isRightArrowDisabled: false,
      isLeftArrowDisabled: true,
      selectedRestaurantService: null,
      selectedRestaurantWasteAnalysisForHighlights: null,
      selectedLocationWasteAnalysisForHighlights: null,
      selectedLocationId: '',
      selectedToggleValueForWeightCostForRestaurantBreakdown: 'weight', // weight or cost

      // Key Overview
      isKeyOverviewPanelExpanded: true,
      isTooltipForKeyOverviewOpened: false,
      shouldDisplayKeyOverviewLineChart: false,
      shouldDisplayKeyOverviewBarChartForRestaurants: false,

      // Styles used to update sticky Watchlist
      stickyWatchlistWidth: '100%',
      stickyWatchlistTransform: null,
      stickyWatchlistTransition: null,

      // Menu item mapping warning
      isMenuItemMappingWarningOpened: false,
      menuItemMappingWarningCount: 0,
    };

    // Create a ref assigned to a component in render()
    this.rightColumnContainerRef = React.createRef();
  }

  componentDidMount() {
    document.title = 'Lumitics | Towards Zero Food Waste';
    const { organisationName, setPageHistory } = this.context;

    setPageHistory([`${organisationName}: ${CONSTANT.highlightsPage}`]);
    this.setPreviousSelectedStartEndDateAndGroupBy();
    this.fetchWasteAnalysisForHighlights();
    // Handle window resize events
    window.addEventListener('resize', () => this.updateWatchlistStyle());
    this.updateWatchlistStyle();
  }

  componentDidUpdate() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    const { previousSelectedStartDate, previousSelectedEndDate, previousSelectedGroupBy } =
      this.state;
    /* 
      Comparing the context directly in the following way:
      prevProps.context !== this.context 
      prevProps.context.selectedStartDate !== this.context.selectedStartDate
      somehow cause infinite loop and multiple calls to fetchWasteAnalysisForHighlights()
     */
    if (
      previousSelectedStartDate !== selectedStartDate ||
      previousSelectedEndDate !== selectedEndDate ||
      previousSelectedGroupBy !== selectedGroupBy
    ) {
      // Update previousSelectedStartDate, previousSelectedEndDate and previousSelectedGroupBy
      // with new context ones
      this.setPreviousSelectedStartEndDateAndGroupBy();

      this.fetchWasteAnalysisForHighlights();
    }

    this.updateWatchlistStyle();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWatchlistStyle());
  }

  onCloseRestaurantBreakdownTooltip() {
    this.closeRestaurantBreakdownTooltip();
  }

  onClickAwayRestaurantBreakdownTooltip() {
    this.closeRestaurantBreakdownTooltip();
  }

  onClickRestaurantBreakdownInfoIcon() {
    const { isTooltipForRestaurantBreakdownOpened } = this.state;

    this.setState({
      isTooltipForRestaurantBreakdownOpened: !isTooltipForRestaurantBreakdownOpened,
    });
  }

  onClickClearSelectionButton() {
    this.setState({
      arrSelectedMenuItemServiceWasteAnalysis: [],
    });
  }

  /**
   * This function filters out menu item(s) that is already on the watchlist from arrSelectedMenuItemServiceWasteAnalysis and passes it to
   * the addToWatchlist function. Also set state to unselect the menu item(s).
   */
  onClickAddMenuItemToWatchlist() {
    const { arrMenuItemRestaurant, arrSelectedMenuItemServiceWasteAnalysis } = this.state;
    const arrMenuItemRestaurantToBeAdded = [];

    arrSelectedMenuItemServiceWasteAnalysis.forEach((selectedMenuItemServiceWasteAnalysis) => {
      const matchedMenuItemRestaurant = arrMenuItemRestaurant.find(
        (menuItemRestaurant) =>
          menuItemRestaurant.menuItemId === selectedMenuItemServiceWasteAnalysis.menuItemId &&
          menuItemRestaurant.serviceId === selectedMenuItemServiceWasteAnalysis.serviceId
      );
      if (!this.isMenuItemAlreadyOnWatchlist(matchedMenuItemRestaurant)) {
        arrMenuItemRestaurantToBeAdded.push(matchedMenuItemRestaurant);
      }
    });

    this.addToWatchlist(arrMenuItemRestaurantToBeAdded);
    this.setState({ arrSelectedMenuItemServiceWasteAnalysis: [] });
  }

  /**
   * Change restaurant tab when left arrow button is clicked. If leftmost tab is reached, disable the left arrow button. Else, set state with the
   * newly selected restaurant and the first location on the its array.
   */
  onClickLeftArrowIconButton() {
    const { arrRestaurantService } = this.props;
    const { arrRestaurantWasteAnalysisForHighlights, restaurantTabValue } = this.state;
    const newTabValue = restaurantTabValue - 1;

    if (newTabValue >= 0) {
      const selectedRestaurantService = arrRestaurantService[newTabValue];
      const selectedRestaurantWasteAnalysisForHighlights =
        arrRestaurantWasteAnalysisForHighlights.find(
          (restaurantWasteAnalysisForHighlights) =>
            restaurantWasteAnalysisForHighlights.restaurantId ===
            selectedRestaurantService.restaurantId
        );
      const selectedLocationId = arrRestaurantService[newTabValue].arrLocationService[0].locationId;
      const selectedLocationWasteAnalysisForHighlights =
        selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
          (locationWasteAnalysisForHighlights) =>
            locationWasteAnalysisForHighlights.locationId === selectedLocationId
        );
      this.setState({
        selectedRestaurantService,
        selectedRestaurantWasteAnalysisForHighlights,
        restaurantTabValue: newTabValue,
        selectedLocationId,
        selectedLocationWasteAnalysisForHighlights,
      });
      this.disableArrowButton(newTabValue);
    } else {
      this.setState({ isLeftArrowDisabled: true }); // Reached leftmost tab
    }
  }

  /**
   * Change restaurant tab when right arrow button is clicked. If rightmost tab is reached, disable the right arrow button. Else, set state with the
   * newly selected restaurant and the first location on the its array.
   */
  onClickRightArrowIconButton() {
    const { arrRestaurantService } = this.props;
    const { arrRestaurantWasteAnalysisForHighlights, restaurantTabValue } = this.state;
    const newTabValue = restaurantTabValue + 1;

    if (newTabValue < arrRestaurantService.length) {
      const selectedRestaurantService = arrRestaurantService[newTabValue];
      const selectedRestaurantWasteAnalysisForHighlights =
        arrRestaurantWasteAnalysisForHighlights.find(
          (restaurantWasteAnalysisForHighlights) =>
            restaurantWasteAnalysisForHighlights.restaurantId ===
            selectedRestaurantService.restaurantId
        );
      const selectedLocationId = arrRestaurantService[newTabValue].arrLocationService[0].locationId;
      const selectedLocationWasteAnalysisForHighlights =
        selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
          (locationWasteAnalysisForHighlights) =>
            locationWasteAnalysisForHighlights.locationId === selectedLocationId
        );
      this.setState({
        selectedRestaurantService,
        selectedRestaurantWasteAnalysisForHighlights,
        restaurantTabValue: newTabValue,
        selectedLocationId,
        selectedLocationWasteAnalysisForHighlights,
      });
      this.disableArrowButton(newTabValue);
    } else {
      this.setState({ isRightArrowDisabled: true }); // Reached rightmost tab
    }
  }

  /**
   * Change restaurant tab when the tab is clicked on the tab panel. Set state with the newly selected restaurant and the first location on the its array.
   */
  onChangeRestaurantBreakdownTabList(event, newValue) {
    const { arrRestaurantService } = this.props;
    const { arrRestaurantWasteAnalysisForHighlights } = this.state;
    const newTabValue = parseInt(newValue, 10);

    const selectedRestaurantService = arrRestaurantService[newTabValue];
    const selectedRestaurantWasteAnalysisForHighlights =
      arrRestaurantWasteAnalysisForHighlights.find(
        (restaurantWasteAnalysisForHighlights) =>
          restaurantWasteAnalysisForHighlights.restaurantId ===
          selectedRestaurantService.restaurantId
      );
    const selectedLocationId = arrRestaurantService[newTabValue].arrLocationService[0].locationId;
    const selectedLocationWasteAnalysisForHighlights =
      selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
        (locationWasteAnalysisForHighlights) =>
          locationWasteAnalysisForHighlights.locationId === selectedLocationId
      );
    this.setState({
      selectedRestaurantService,
      selectedRestaurantWasteAnalysisForHighlights,
      restaurantTabValue: newTabValue,
      selectedLocationId,
      selectedLocationWasteAnalysisForHighlights,
    });
    this.disableArrowButton(newTabValue);
  }

  onChangeToggleWeightCostButtonForRestaurantBreakdown(event, newSelectedToggleValue) {
    this.setState({
      selectedToggleValueForWeightCostForRestaurantBreakdown: newSelectedToggleValue,
    });
  }

  /**
   * Change selected location
   */
  onChangeDropdownList(event) {
    const { value } = event.target;

    const { selectedRestaurantWasteAnalysisForHighlights } = this.state;
    const selectedLocationWasteAnalysisForHighlights =
      selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
        (locationWasteAnalysisForHighlights) =>
          locationWasteAnalysisForHighlights.locationId === value
      );
    this.setState({
      selectedLocationId: value,
      selectedLocationWasteAnalysisForHighlights,
    });
  }

  onClickKeyOverviewInfoIcon(event) {
    const { isTooltipForKeyOverviewOpened } = this.state;

    this.setState({ isTooltipForKeyOverviewOpened: !isTooltipForKeyOverviewOpened });
    event.stopPropagation(); // Don't propagate click to expansion panel
  }

  onCloseKeyOverviewTooltip() {
    this.closeKeyOverviewTooltip();
  }

  onClickAwayKeyOverviewTooltip() {
    this.closeKeyOverviewTooltip();
  }

  /**
   * Check if the app is run on desktop, and change teh overview expansion panel accordingly
   */
  onChangeKeyOverviewExpansionPanel() {
    const { isKeyOverviewPanelExpanded } = this.state;

    if (this.checkIsDesktopView()) {
      this.setState({ isKeyOverviewPanelExpanded: true });
    } else {
      this.setState({ isKeyOverviewPanelExpanded: !isKeyOverviewPanelExpanded });
    }
  }

  setPreviousSelectedStartEndDateAndGroupBy() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    this.setState({
      previousSelectedStartDate: selectedStartDate,
      previousSelectedEndDate: selectedEndDate,
      previousSelectedGroupBy: selectedGroupBy,
    });
  }

  /**
   * Get an array of location for dropdown
   */
  getArrDropdownLocation() {
    const { selectedRestaurantService } = this.state;
    let arrDropdownItem = [];
    if (selectedRestaurantService) {
      arrDropdownItem = selectedRestaurantService.arrLocationService.map((locationService) => {
        return {
          value: locationService.locationId,
          name: locationService.name,
        };
      });
    }
    return arrDropdownItem;
  }

  checkIsDesktopView() {
    const { theme } = this.props;

    return window.matchMedia(`(min-width: ${theme.breakpoints.values.md}px)`).matches;
  }

  closeRestaurantBreakdownTooltip() {
    this.setState({ isTooltipForRestaurantBreakdownOpened: false });
  }

  closeKeyOverviewTooltip() {
    this.setState({ isTooltipForKeyOverviewOpened: false });
  }

  /**
   * Check if a menu item is already on the watchlist
   * @param {typedefs.MenuItemServiceWasteAnalysis} menuItemServiceWasteAnalysis - Menu item to be verified if it is already on the watchlist
   * @returns {boolean} Boolean value to indicate if menu item is already on the watchlist
   */
  isMenuItemAlreadyOnWatchlist(menuItemServiceWasteAnalysis) {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    const matchedWatchlistItemMenuItemRestaurantWasteAnalysis =
      arrWatchlistItemMenuItemRestaurantWasteAnalysis.find(
        (watchlistItemMenuItemRestaurantWasteAnalysis) =>
          menuItemServiceWasteAnalysis.menuItemName ===
          watchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis[0]
            .menuItemName
      );

    if (!matchedWatchlistItemMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    const matchedMenuItemRestaurantWasteAnalysis =
      matchedWatchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis.find(
        (menuItemRestaurantWasteAnalysis) =>
          menuItemRestaurantWasteAnalysis.menuItemId === menuItemServiceWasteAnalysis.menuItemId
      );

    if (!matchedMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    return true;
  }

  updateArrSelectedMenuItemServiceWasteAnalysis(arrNewlySelectedMenuItemServiceWasteAnalysis) {
    this.setState({
      arrSelectedMenuItemServiceWasteAnalysis: arrNewlySelectedMenuItemServiceWasteAnalysis,
    });
  }

  /**
   * Disable left/right arrow button when leftmost/rightmost tab is selected
   */
  disableArrowButton(restaurantTabValue) {
    const { arrRestaurantService } = this.props;

    if (arrRestaurantService.length === 1) {
      // Edge case: when there's only 1 restaurant tab
      this.setState({ isRightArrowDisabled: true, isLeftArrowDisabled: true });
    } else if (restaurantTabValue >= arrRestaurantService.length - 1) {
      // Rightmost tab
      this.setState({ isRightArrowDisabled: true, isLeftArrowDisabled: false });
    } else if (restaurantTabValue <= 0) {
      // leftmost tab
      this.setState({ isLeftArrowDisabled: true, isRightArrowDisabled: false });
    } else {
      this.setState({ isRightArrowDisabled: false, isLeftArrowDisabled: false });
    }
  }

  /**
   * Update sticky watchlist style based on scrollable header's style
   */
  updateWatchlistStyle() {
    const { scrollableHeaderTransform, scrollableHeaderTransition, theme } = this.props;

    const isDesktopView = window.matchMedia(
      `(min-width: ${theme.breakpoints.values.md}px)`
    ).matches;
    if (isDesktopView) {
      // Calculate watchlist after its parent container has rendered
      const rightColumnContainer = this.rightColumnContainerRef.current;
      const watchlistWidth = rightColumnContainer ? rightColumnContainer.offsetWidth - 15 : '100%';
      // Desktop view
      this.setState({
        stickyWatchlistWidth: watchlistWidth,
        stickyWatchlistTransform: scrollableHeaderTransform,
        stickyWatchlistTransition: scrollableHeaderTransition,
      });
    } else {
      // Mobile view
      this.setState({
        stickyWatchlistWidth: '100%',
        stickyWatchlistTransform: null,
        stickyWatchlistTransition: null,
      });
    }
  }

  /**
   * Fetch waste analysis for highlights from the backend in one API call. Set state the first restaurant on arrRestaurantWasteAnalysisForHighlights as the
   * the selected restaurant as first location on the arrLocationWasteAnalysisForHighlights as the selected location.
   */
  async fetchWasteAnalysisForHighlights() {
    const {
      selectedStartDate,
      selectedEndDate,
      renderLoaderAnimation,
      openSnackbar,
      selectedGroupBy,
    } = this.context;
    const { arrRestaurantService, history } = this.props;
    const { auth0 } = this.props;
    const { user } = auth0;
    try {
      if (arrRestaurantService.length === 0) {
        throw new Error('No Service');
      }
      renderLoaderAnimation(true);
      let { isCompanyUser, datavizUserId: userId } = user;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId, impersonatorIsCompanyUser } = this.context;
        userId = impersonatorDatavizUserId;
        isCompanyUser = impersonatorIsCompanyUser;
      }
      const token = await auth0.getAccessTokenSilently();
      const response = await axios.post(
        '/api/fetch-waste-analysis-for-highlights',
        {
          userId,
          arrRestaurantService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
          isCompanyUser,
          numberOfTopWastedFoodItemsToDisplay: CONSTANT.numberOfTopWastedFoodItemsToDisplay,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { wasteAnalysisForHighlights } = response.data;

      const {
        arrRestaurantWasteAnalysisForHighlights,
        wasteAnalysisForKeyOverview,
        arrMenuItemRestaurant,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        wasteReductionAnalysisAndWastePerCoverForKeyOverview,
        menuItemMappingWarningCount,
      } = wasteAnalysisForHighlights;
      const selectedRestaurantService = arrRestaurantService[0];
      const selectedRestaurantWasteAnalysisForHighlights =
        arrRestaurantWasteAnalysisForHighlights.find(
          (restaurantWasteAnalysisForHighlights) =>
            restaurantWasteAnalysisForHighlights.restaurantId ===
            selectedRestaurantService.restaurantId
        );
      const selectedLocationId = selectedRestaurantService.arrLocationService[0].locationId;
      const selectedLocationWasteAnalysisForHighlights =
        selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
          (locationWasteAnalysisForHighlights) =>
            locationWasteAnalysisForHighlights.locationId === selectedLocationId
        );

      const shouldDisplayKeyOverviewBarChartForRestaurants =
        this.checkShouldDisplayKeyOverviewBarChartForRestaurants(wasteAnalysisForKeyOverview);
      const shouldDisplayKeyOverviewLineChart = checkShouldKeyOverviewLineChart(
        wasteAnalysisForKeyOverview
      );

      this.setState({
        isMenuItemMappingWarningOpened: menuItemMappingWarningCount > 0,
        menuItemMappingWarningCount,
        isWasteAnalysisForHighlightsFetched: true,
        shouldDisplayKeyOverviewBarChartForRestaurants,
        shouldDisplayKeyOverviewLineChart,
        arrRestaurantWasteAnalysisForHighlights,
        wasteAnalysisForKeyOverview,
        arrMenuItemRestaurant,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        selectedRestaurantService,
        selectedRestaurantWasteAnalysisForHighlights,
        selectedLocationId,
        selectedLocationWasteAnalysisForHighlights,
        wasteReductionAnalysisAndWastePerCoverForKeyOverview,
      });
      renderLoaderAnimation(false);
    } catch (error) {
      renderLoaderAnimation(false);
      // Catch JWT web token error
      if (user.isAdmin) {
        history.push('/impersonator');
      } else {
        history.push('/login');
      }

      if (error.message === 'No Service') {
        openSnackbar(
          'No service(s) currently. Please inform Lumitics to validate the service(s).',
          'error'
        );
      } else {
        openSnackbar(
          'Unknown error during loading for waste analysis for home page. Please notify admin.',
          'error'
        );
      }
    }
  }

  // ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
  async deleteFromWatchlist(menuItemId, watchlistItemMenuItemRestaurantWasteAnalysis) {
    const { openSnackbar } = this.context;
    const { auth0, history } = this.props;
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/delete-menu-item-from-watchlist',
        {
          userId,
          menuItemIdToBeDeleted: menuItemId,
          watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted:
            watchlistItemMenuItemRestaurantWasteAnalysis,
          arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response;
      this.setState({
        arrWatchlistItemMenuItemRestaurantWasteAnalysis:
          data.arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      });
      openSnackbar('Successfully deleted 1 food item from the watchlist', 'success');
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during deleting food item from watchlist. Please try again. If the problem persists, notify admin',
          'error'
        );
      }
    }
  }

  /**
   * To send the menu item(s) to be added to the watchlist to the Backend for adding to the database. If the number of watchlist items is already at its maximum watchlist
   * size of 20, a snackbar will apear to inform the user and the menu item(s) will not be sent to the Backend.
   * ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
   * @param {typedefs.MenuItemRestaurant[]} arrMenuItemRestaurantToBeAdded - Array of menu item(s) to be added to watchlist
   */
  async addToWatchlist(arrMenuItemRestaurantToBeAdded) {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { auth0, history } = this.props;
    const { arrMenuItemRestaurant, arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    if (arrWatchlistItemMenuItemRestaurantWasteAnalysis.length >= 20) {
      openSnackbar(
        `You can only have a maximum of ${CONSTANT.watchlistMaxSize} items in your watchlist`,
        'error'
      );
    } else if (arrMenuItemRestaurantToBeAdded.length === 0) {
      openSnackbar(`Selected food item(s) are already on the watchlist`, 'error');
    } else {
      try {
        renderLoaderAnimation(true);
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        const response = await axios.post(
          '/api/add-menu-items-to-watchlist',
          {
            userId,
            arrMenuItemRestaurantToBeAdded,
            arrWatchlistItemMenuItemRestaurantWasteAnalysis,
            arrAllMenuItemRestaurant: arrMenuItemRestaurant,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            groupBy: selectedGroupBy,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { data } = response;
        this.setState({
          arrWatchlistItemMenuItemRestaurantWasteAnalysis:
            data.arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        });
        renderLoaderAnimation(false);
        openSnackbar(
          'Successfully added ' +
            `${arrMenuItemRestaurantToBeAdded.length}` +
            ' food item' +
            `${arrMenuItemRestaurantToBeAdded.length > 1 ? 's' : ''}` +
            ' to the watchlist',
          'success'
        );
      } catch (error) {
        const { response } = error;
        // Catch JWT web token error
        if (response && response.status === 401) {
          history.push('/login');
        } else {
          openSnackbar(
            'Unknown error during adding food item to watchlist. Please try again. If the problem persists, notify admin',
            'error'
          );
        }
      }
    }
  }

  /**
   * To send the watchlist items that have a change in rank the Backend for updating in the database. Only the watchlist items with a change in the rank will be sent to the Backend.
   * To reduce the waiting time for the updated display of watchlist items, the new array of watchlist items will be set state first, before axios.post is done.
   * ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
   * @param {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis - Array of watchlist items with its menuItemRestaurantWasteAnalysis arranged according to the new order
   */
  async updateWatchlist(arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis) {
    const { openSnackbar } = this.context;
    const { auth0, history } = this.props;

    try {
      const {
        arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
        arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
      } = getArrWatchlistItemMenuItemRestaurantWithUpdatedRankForFrontendAndBackend(
        arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis
      );
      // This setState is done out before the axios.post because the errors in updating the ranks in the database does not affect the the Frontend
      // display and setting the state only if the update of ranks in the database is successfully delays the display of the updated ranks
      this.setState({
        arrWatchlistItemMenuItemRestaurantWasteAnalysis:
          arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
      });
      if (arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend.length !== 0) {
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        await axios.post(
          'api/update-ranks-for-watchlist-items',
          {
            userId,
            arrWatchlistItemMenuItemRestaurantToBeUpdated:
              arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during updating rank for menu items on watchlist. Please try again. If the problem persists, notify admin',
          'error'
        );
      }
    }
  }

  /**
   * Check if there is any data for the bar charts for restaurants to be display on the Key Overview
   * @param {typedefs.WasteAnalysisForKeyOverview} wasteAnalysisForKeyOverview - Waste analysis for key overview
   */
  checkShouldDisplayKeyOverviewBarChartForRestaurants(wasteAnalysisForKeyOverview) {
    const { auth0 } = this.props;
    const { user } = auth0;
    const { impersonatorIsCompanyUser } = this.context;

    return (
      wasteAnalysisForKeyOverview.wasteAnalysisForBarChart.xAxisArrRestaurantName.length > 1 &&
      ((!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser))
    );
  }

  render() {
    const { classes, arrRestaurantService } = this.props;
    const { impersonatorDatavizUserId } = this.context;
    const {
      isMenuItemMappingWarningOpened,
      menuItemMappingWarningCount,
      isWasteAnalysisForHighlightsFetched,
      arrMenuItemRestaurant,
      wasteAnalysisForKeyOverview,
      wasteReductionAnalysisAndWastePerCoverForKeyOverview,
      arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      stickyWatchlistWidth,
      stickyWatchlistTransform,
      stickyWatchlistTransition,

      selectedRestaurantService,
      selectedLocationId,
      selectedLocationWasteAnalysisForHighlights,
      restaurantTabValue,
      isTooltipForRestaurantBreakdownOpened,
      isRightArrowDisabled,
      isLeftArrowDisabled,
      selectedToggleValueForWeightCostForRestaurantBreakdown,
      arrSelectedMenuItemServiceWasteAnalysis,

      isKeyOverviewPanelExpanded,
      isTooltipForKeyOverviewOpened,
      shouldDisplayKeyOverviewLineChart,
      shouldDisplayKeyOverviewBarChartForRestaurants,
    } = this.state;

    return (
      <Box>
        {isWasteAnalysisForHighlightsFetched && (
          <>
            {isMenuItemMappingWarningOpened && menuItemMappingWarningCount && (
              <Grid
                container
                className={classes.rootGridContainer}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <Grid item xs={12}>
                  <Alert
                    severity="warning"
                    onClose={() => this.setState({ isMenuItemMappingWarningOpened: false })}
                    className={classes.menuItemMappingWarning}
                  >
                    <AlertTitle>
                      <strong>Warning</strong>
                    </AlertTitle>
                    Please note that there are <strong>{menuItemMappingWarningCount}</strong> food
                    items to be mapped.{' '}
                    <Link to="/menu-item-mapping" style={{ color: 'inherit', fontWeight: 'bold' }}>
                      Click here to be directed to Menu Item Mapping page.
                    </Link>
                  </Alert>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              className={classes.rootGridContainer}
              justifyContent={impersonatorDatavizUserId !== -1 ? 'flex-start' : 'center'}
            >
              {/* Key Overview */}
              <Grid
                item
                xs={12}
                md={7}
                className={`${classes.rootGridItem} ${classes.leftColumnContainer}`}
              >
                <KeyOverview
                  wasteAnalysisForKeyOverview={wasteAnalysisForKeyOverview}
                  wasteReductionAnalysisAndWastePerCoverForKeyOverview={
                    wasteReductionAnalysisAndWastePerCoverForKeyOverview
                  }
                  onCloseTooltip={() => this.onCloseKeyOverviewTooltip()}
                  onClickAwayTooltip={() => this.onClickAwayKeyOverviewTooltip()}
                  onClickInfoIcon={(event) => this.onClickKeyOverviewInfoIcon(event)}
                  onChangeExpansionPanel={() => this.onChangeKeyOverviewExpansionPanel()}
                  isExpanded={isKeyOverviewPanelExpanded}
                  isTooltipOpened={isTooltipForKeyOverviewOpened}
                  shouldDisplayLineChart={shouldDisplayKeyOverviewLineChart}
                  shouldDisplayBarChartForRestaurants={
                    shouldDisplayKeyOverviewBarChartForRestaurants
                  }
                  shouldShowMore={
                    shouldDisplayKeyOverviewLineChart ||
                    shouldDisplayKeyOverviewBarChartForRestaurants
                  }
                />
              </Grid>

              {/* Watchlist */}
              {impersonatorDatavizUserId !== -1 && (
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={`${classes.rootGridItem} ${classes.rightColumnContainer}`}
                  ref={this.rightColumnContainerRef}
                >
                  <Box
                    className={classes.stickyWatchlist}
                    style={{
                      width: stickyWatchlistWidth,
                      transform: stickyWatchlistTransform,
                      transition: stickyWatchlistTransition,
                    }}
                  >
                    <Watchlist
                      arrWatchlistItemMenuItemRestaurantWasteAnalysis={
                        arrWatchlistItemMenuItemRestaurantWasteAnalysis
                      }
                      arrMenuItemRestaurant={arrMenuItemRestaurant}
                      deleteFromWatchlist={(
                        menuItemId,
                        watchlistItemMenuItemRestaurantWasteAnalysis
                      ) =>
                        this.deleteFromWatchlist(
                          menuItemId,
                          watchlistItemMenuItemRestaurantWasteAnalysis
                        )
                      }
                      addToWatchlist={(arrMenuItemRestaurantToBeAdded) =>
                        this.addToWatchlist(arrMenuItemRestaurantToBeAdded)
                      }
                      updateWatchlist={(arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis) =>
                        this.updateWatchlist(
                          arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis
                        )
                      }
                    />
                  </Box>
                </Grid>
              )}

              {/* Restaurant Breakdown */}
              <Grid
                item
                xs={12}
                md={7}
                className={`${classes.rootGridItem} ${classes.leftColumnContainer}`}
              >
                <RestaurantBreakdown
                  arrRestaurantService={arrRestaurantService}
                  arrDropdownItem={this.getArrDropdownLocation()}
                  selectedRestaurantService={selectedRestaurantService}
                  selectedLocationId={selectedLocationId}
                  selectedLocationWasteAnalysisForHighlights={
                    selectedLocationWasteAnalysisForHighlights
                  }
                  arrSelectedMenuItemServiceWasteAnalysis={arrSelectedMenuItemServiceWasteAnalysis}
                  tabValue={restaurantTabValue}
                  isRightArrowDisabled={isRightArrowDisabled}
                  isLeftArrowDisabled={isLeftArrowDisabled}
                  isTooltipOpened={isTooltipForRestaurantBreakdownOpened}
                  selectedToggleValueForWeightCost={
                    selectedToggleValueForWeightCostForRestaurantBreakdown
                  }
                  onChangeDropdownList={(event) => this.onChangeDropdownList(event)}
                  onChangeTabList={(event, newValue) =>
                    this.onChangeRestaurantBreakdownTabList(event, newValue)
                  }
                  onChangeToggleWeightCost={(event, newSelectedToggleValue) =>
                    this.onChangeToggleWeightCostButtonForRestaurantBreakdown(
                      event,
                      newSelectedToggleValue
                    )
                  }
                  onClickAdd={() => this.onClickAddMenuItemToWatchlist()}
                  onClickAwayTooltip={() => this.onClickAwayRestaurantBreakdownTooltip()}
                  onClickClearSelection={() => this.onClickClearSelectionButton()}
                  onClickInfoIcon={() => this.onClickRestaurantBreakdownInfoIcon()}
                  onClickLeftArrowIcon={() => this.onClickLeftArrowIconButton()}
                  onClickRightArrowIcon={() => this.onClickRightArrowIconButton()}
                  onCloseTooltip={() => this.onCloseRestaurantBreakdownTooltip()}
                  updateArrSelectedMenuItemServiceWasteAnalysis={(
                    arrNewlySelectedMenuItemServiceWasteAnalysis
                  ) =>
                    this.updateArrSelectedMenuItemServiceWasteAnalysis(
                      arrNewlySelectedMenuItemServiceWasteAnalysis
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    );
  }
}

Home.contextType = AppContext;

export default withRouter(withAuth0(withTheme(withStyles(styles)(Home))));
