import React, { PureComponent } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Box } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { AppContext, CONSTANT } from '../../../AppContext';

const INVALID_DATA_REPRESENTATION = '-';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const styles = () => ({
  outerBoxContainer: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  innerBoxContainer: {
    position: 'relative',
  },
});

class BarLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: CONSTANT.defaultBarLineChartHeight,
      chartContainerWidth: '100%',
      chartOption: this.createChartOption(),
      chartData: this.createDataSet(),
    };

    this.chartReference = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.updateChartWidth());
    this.updateChartWidth();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateChart(prevProps, prevState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateChartWidth());
  }

  /**
   * Get the font size for the y-axis, x-axis and scale label.
   * Returns:
   *    mobile view: 10
   *    desktop view < 1280px: 12
   *    else: 14
   * @returns {number} Number - Size of axis font: 10, 12 or 14
   */
  getAxisFontSize() {
    const { theme } = this.props;

    if (window.innerWidth < theme.breakpoints.values.md) {
      return 10;
    }
    if (window.innerWidth < theme.breakpoints.values.lg) {
      return 12;
    }
    return 14;
  }

  /**
   * Update the bar chart with the new data if there is a change in either the bar line chart data
   */
  updateChart(prevProps) {
    const { xAxisData, yAxisBarData, yAxisLineData } = this.props;

    if (
      xAxisData !== prevProps.xAxisData ||
      yAxisBarData !== prevProps.yAxisBarData ||
      yAxisLineData !== prevProps.yAxisLineData
    ) {
      this.setState({
        chartOption: this.createChartOption(),
        chartData: this.createDataSet(),
      });
      this.updateChartWidth();
    }
  }

  /**
   * Creates the data needed for both x and y axis of the bar line chart
   * @returns {Object} chartData - Object containing data to plot bar line chart
   */
  createDataSet() {
    const { xAxisData, yAxisBarData, yAxisLineData } = this.props;

    const chartData = {
      labels: xAxisData,
      datasets: [
        {
          type: 'line',
          label: 'Cover',
          borderColor: CONSTANT.pastelColorArray[1],
          borderWidth: 2,
          fill: false,
          data: yAxisLineData,
          yAxisID: 'yLine',
        },
        {
          type: 'bar',
          label: 'Waste Per Cover',
          backgroundColor: CONSTANT.pastelColorArray[0],
          data: yAxisBarData,
          yAxisID: 'yBar',
        },
      ],
    };
    return chartData;
  }

  /**
   * Line bar chart becomes scrollable when there are more than 7 x axis data
   */
  updateChartWidth() {
    const { xAxisData } = this.props;
    if (xAxisData && xAxisData.length > 7) {
      this.setState({ chartContainerWidth: `${89 * xAxisData.length}px` });
    } else {
      this.setState({ chartContainerWidth: '100%' });
    }
  }

  /**
   * Calculates the maximum value of the y-axis tick for the bar line chart. The max value is found based on the
   * highest individual data points multiplied by 1.05 to pad the top of the axes.
   * @returns {number} Number - Maximum value of the y-axis tick
   */
  calculateMaxYTickValue(chartType) {
    const { yAxisBarData, yAxisLineData } = this.props;
    const yAxisData = chartType === 'bar' ? yAxisBarData : yAxisLineData;
    if (yAxisData.length === 0) {
      return 0;
    }

    return 1.05 * Math.max(...yAxisData);
  }

  /**
   * Create data structure required by the bar line chart to display it correctly
   * @returns {Object} chartOption - Object containing chart options for the bar line chart
   */
  createChartOption() {
    const { yAxisBarLabel, yAxisLineLabel } = this.props;
    const chartOption = {
      maintainAspectRatio: false,
      responsive: true,
      stacked: false,
      // Options to style the x and y axes
      scales: {
        x: {
          ticks: {
            display: true,
            color: CONSTANT.defaultAxisColor,
            font: this.getAxisFontSize(),
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        yBar: {
          suggestedMax: this.calculateMaxYTickValue('bar'),
          ticks: {
            display: true,
            maxTicksLimit: 6,
            font: this.getAxisFontSize(),
            color: CONSTANT.defaultAxisColor,
            padding: 6,
            precision: 0,
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          title: {
            display: true,
            text: yAxisBarLabel,
            font: this.getAxisFontSize(),
            color: CONSTANT.defaultAxisColor,
          },
        },
        yLine: {
          suggestedMax: this.calculateMaxYTickValue('line'),
          ticks: {
            display: true,
            maxTicksLimit: 6,
            font: this.getAxisFontSize(),
            color: CONSTANT.defaultAxisColor,
            padding: 6,
            precision: 0,
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          title: {
            display: true,
            text: yAxisLineLabel,
            font: this.getAxisFontSize(),
            color: CONSTANT.defaultAxisColor,
          },
          position: 'right',
        },
      },
      layout: {
        autoPadding: true,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          mode: 'index',
          bodyAlign: 'left',
          callbacks: {
            label: (context) => {
              const currentStack = context.dataset.label;
              const currentValue = context.dataset.data[context.dataIndex];
              const currentValueFormattedTo2dpAndWithCommaSeparation =
                currentValue !== INVALID_DATA_REPRESENTATION
                  ? Number(currentValue.toFixed(2)).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })
                  : currentValue;
              if (currentStack === 'Waste Per Cover') {
                return `${currentStack}: ${currentValueFormattedTo2dpAndWithCommaSeparation} grams`;
              }

              return `${currentStack}: ${currentValue.toLocaleString('en-US')}`;
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
    };

    return chartOption;
  }

  render() {
    const { classes } = this.props;
    const { chartData, chartOption, height, chartContainerWidth } = this.state;

    return (
      <Box
        className={classes.outerBoxContainer}
        style={{ height: CONSTANT.defaultBarLineChartHeight + 15 }}
      >
        <Box className={classes.innerBoxContainer} style={{ width: chartContainerWidth }}>
          <Chart
            ref={this.chartReference}
            type="bar"
            data={JSON.parse(JSON.stringify(chartData))}
            height={height}
            options={chartOption}
          />
        </Box>
      </Box>
    );
  }
}

BarLineChart.contextType = AppContext;

export default withTheme(withStyles(styles)(BarLineChart));
