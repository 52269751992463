import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AppContext } from '../../../AppContext';
// Reusable component
import BarChart from '../../common/chart/BarChart';

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.main,
    paddingBottom: '12px',
  },
});

class TopThreeWastefulStations extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currency } = this.context;
    const { classes, selectedLocationWasteAnalysisForHighlights, toggleValueForWeightCost } =
      this.props;

    let xAxisData = [];
    let yAxisData = [];
    let labelData = [];
    // The checking of weight = 0 is if there is no waste thrown during the selected period, there should not be any axis or label shown
    if (selectedLocationWasteAnalysisForHighlights) {
      if (toggleValueForWeightCost === 'weight') {
        xAxisData =
          selectedLocationWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart
            .byWeight.arrStation;
        yAxisData =
          selectedLocationWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart
            .byWeight.arrWeight;
        labelData =
          selectedLocationWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart
            .byWeight.arrServiceName;
      } else {
        xAxisData =
          selectedLocationWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byCost
            .arrStation;
        yAxisData =
          selectedLocationWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byCost
            .arrCost;
        labelData =
          selectedLocationWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byCost
            .arrServiceName;
      }
    }
    return (
      <Box id="top-three-wasteful-stations-chart">
        <Grid container justifyContent="center" alignItems="center">
          <Typography className={classes.title} variant="h1">
            TOP 3 WASTEFUL STATIONS
          </Typography>
        </Grid>
        <BarChart
          xAxisData={xAxisData}
          yAxisData={yAxisData}
          yAxisLabel={toggleValueForWeightCost === 'weight' ? 'WEIGHT (KG)' : `COST (${currency})`}
          labelData={labelData}
        />
      </Box>
    );
  }
}

TopThreeWastefulStations.contextType = AppContext;

export default withStyles(styles)(TopThreeWastefulStations);
