import moment from 'moment';
import React, { PureComponent } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { Backdrop, Box, Button, Fade, Grid, Modal, Typography } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import DatePicker from './DatePicker';
import { AppContext, CONSTANT } from '../../AppContext';

/**
 * Custom styles for the DatePickerModal component
 */
const styles = (theme) => ({
  // Styles for modal
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContents: 'center',
  },
  modalForm: {
    backgroundColor: 'white',
    borderRadius: '15px',
    padding: '30px',
    width: '30%',
    margin: 'auto',
    textAlign: 'center',
    minWidth: '400px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      minWidth: '300px',
    },
    height: 'min-content',
    outline: 'none',
  },
  modalFormHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'start',
    padding: '10px',
  },
  modalConfirmButtonStyle: {
    ...theme.typography.h3,
    fontWeight: 400,
    lineHeight: 1.75,
    backgroundColor: '#EEf4FF',
    color: '#3A80FF',
    margin: '10px 0px',
    padding: '5px 15px',
    width: '90%',
  },
  modalCancelButtonStyle: {
    ...theme.typography.h3,
    fontWeight: 400,
    lineHeight: 1.75,
    backgroundColor: '#EBEBEB',
    color: '#929292',
    margin: '10px 0px',
    padding: '5px 15px',
    width: '90%',
  },
  dateRangeQuickSelectButton: {
    paddingLeft: '1px',
    paddingRight: '1px',
  },
  preselectDateRangeButton: {
    ...theme.typography.h6,
    borderRadius: '2px',
    backgroundColor: 'transparent',
    color: '#929292',
    borderColor: '#D6D6D6',
    maxHeight: '30px',
    padding: '4px 2px',
    '&:focus': {
      backgroundColor: '#F3F7FF',
      color: '#3A80FF',
      borderColor: '#79A9FF',
    },
    width: '100%',
    maxWidth: '200px',
    marginBottom: '8px',
  },
  preselectedDateRangeButton: {
    ...theme.typography.h6,
    borderRadius: '2px',
    backgroundColor: '#F3F7FF',
    color: '#3A80FF',
    borderColor: '#79A9FF',
    fontWeight: 600,
    maxHeight: '30px',
    padding: '4px 2px',
    width: '100%',
    maxWidth: '200px',
    '&:focus': {
      backgroundColor: '#F3F7FF',
      color: '#3A80FF',
      borderColor: '#79A9FF',
    },
    '&:disabled': {
      backgroundColor: '#F3F7FF',
      color: '#3A80FF',
      borderColor: '#79A9FF',
    },
    marginBottom: '8px',
  },
  calendarHeaderStyle: {
    color: theme.palette.gray1,
    paddingTop: '60px',
  },
  tooltipIcon: {
    ...theme.typography.h1,
    color: theme.palette.gray3,
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dateText: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: '13px',
    },
  },
});

// Constant
const GROUP_BY_OPTION = {
  day: {
    display: 'Day',
    value: CONSTANT.groupByDay,
  },
  week: {
    display: 'Week',
    value: CONSTANT.groupByWeek,
  },
  month: { display: 'Month', value: CONSTANT.groupByMonth },
};
const QUICK_SELECT_OPTION = {
  day: {
    yesterday: 'Yesterday',
    lastSevenDays: 'Last 7 Days',
    lastThirtyDays: 'Last 30 Days',
  },
  week: {
    lastWeek: 'Last Week',
    lastFourWeeks: 'Last 4 Weeks',
    lastTenWeeks: 'Last 10 Weeks',
  },
  month: {
    lastMonth: 'Last Month',
    lastThreeMonths: 'Last 3 Months',
    lastTwelveMonths: 'Last 12 Months',
  },
};

/**
 * The required props are :
 * - isDatePickerModalOpen
 * - closeDatePickerModal: function to close the modal
 */

class DatePickerModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      groupBy: GROUP_BY_OPTION.day.value,
      arrQuickSelectOption: Object.values(QUICK_SELECT_OPTION.day),
      arrCalendarRange: [],
      isTooltipOpened: false,
    };

    // Ref created to enable interaction between modal buttons and Calendar component
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    this.setPreSelectedDateRange();
  }

  /**
   * This function sets the component's groupBy and arrCalendarRange state according to selected groupBy and date range in the app's context
   * arrCalendarRange and groupBy is used by DatePicker component to determine what date range is currently highlighted and with which selection type
   * This function is called when DatePickerModal is first mounted, and when the modal is closed to reset the selection back to context's state
   */
  setPreSelectedDateRange() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;

    this.setState({
      groupBy: selectedGroupBy,
      arrCalendarRange: [new DateObject(selectedStartDate), new DateObject(selectedEndDate)],
    });
  }

  toggleTooltip() {
    const { isTooltipOpened } = this.state;

    this.setState({ isTooltipOpened: !isTooltipOpened });
  }

  closeTooltip() {
    this.setState({ isTooltipOpened: false });
  }

  /**
   * This function is invoked when user changes date range in Date Picker
   * 1. If groupBy is 'Day', arrCalendarRange state is updated as per ranges returned by Date Picker
   * 2. If groupBy is 'Week', arrCalendarRange state is updated with start of selected week (1st click) to end of selected week (2nd click)
   * 3. If groupBy is 'Month', arrCalendarRange state is updated with start of selected month (1st click) to end of selected month (2nd click)
   * Note: Moment is used in conjunction with DateObject type where DateObjects are first converted to Moment objects to gain access to .startOf() and .endOf() methods. After which, they are converted back to DateObject to be displayed by Date Picker.
   * The Calendar always passes in arrCalendarRange where length 1 signifies a '1st click' and length 2 signifies a '2nd click'. This distinction is necessary as the treatment of date range selection is different
   * @param {DateObject[]} arrCalendarRange - Array of date range as selected by user
   */
  updateCalendar(arrCalendarRange) {
    let arrUpdatedCalendarRange;
    const { groupBy } = this.state;

    switch (groupBy) {
      // For selecting date range by day
      case GROUP_BY_OPTION.day.value:
        arrUpdatedCalendarRange = arrCalendarRange;
        break;
      // For selecting date range by week
      case GROUP_BY_OPTION.week.value:
        // Date range by week: 1st click (Select date)
        if (arrCalendarRange.length === 1) {
          arrUpdatedCalendarRange = arrCalendarRange;
        } else {
          // Date range by week: 2nd click (Select starting day of selected week and ending day of selected week)
          arrUpdatedCalendarRange = [
            new DateObject(
              moment(arrCalendarRange[0].toDate()).startOf('isoWeek').format('YYYY-MM-DD')
            ),
            new DateObject(
              moment(arrCalendarRange[1].toDate()).endOf('isoWeek').format('YYYY-MM-DD')
            ),
          ];
        }
        break;
      // For selecting date range by month
      case GROUP_BY_OPTION.month.value:
        // Date range by month: 1st click (Select month)
        if (arrCalendarRange.length === 1) {
          arrUpdatedCalendarRange = [
            new DateObject(
              moment(arrCalendarRange[0].toDate()).startOf('month').format('YYYY-MM-DD')
            ),
          ];
        } else {
          // Date range by month: 2nd click (Select starting day of selected month and ending day of selected month)
          arrUpdatedCalendarRange = [
            arrCalendarRange[0],
            new DateObject(
              moment(arrCalendarRange[1].toDate()).endOf('month').format('YYYY-MM-DD')
            ),
          ];
        }
        break;
      default:
    }
    this.setState({ arrCalendarRange: arrUpdatedCalendarRange });
  }

  /**
   * Onclick function that closes modal
   */
  closeModal() {
    const { closeDatePickerModal } = this.props;

    this.setPreSelectedDateRange();
    closeDatePickerModal();
  }

  /**
   * Calendar updates the global context upon user's confirmation. The newly selected startDate and endDate will be saved in the YYYY-MM-DD format
   * for consistency (this is also the format used by Backend).
   */
  selectDate() {
    const { setStartDate, setEndDate, setGroupBy } = this.context;
    const { closeDatePickerModal } = this.props;
    const { arrCalendarRange, groupBy } = this.state;

    const startDate = arrCalendarRange[0].format('YYYY-MM-DD');
    const endDate = arrCalendarRange[1].format('YYYY-MM-DD');

    setStartDate(startDate);
    setEndDate(endDate);
    setGroupBy(groupBy);
    closeDatePickerModal();
  }

  /**
   * This function is invoked when user switches 'Group By' between 'Day', 'Week' and 'Month'. Their default dates are as follows:
   * Day - Start: Previous day, End: Previous day
   * Week - Start: Monday of previous week, End: Sunday of previous week
   * Month: Start: First day of previous month, End: Last day of previous month
   * A change in 'Group By' will update the following states:
   * 1. groupBy - The currently selected group by view
   * 2. arrQuickSelectOptions - The quick select options available for the selected group by
   * 3. arrCalendarRange - The default date range for the selected group by. This is reflected to the user where the date range picker
   * will select the above dates selected by default whenever group by is changed
   * @param {string} groupBy - 'Day', 'Week' or 'Month'
   */
  updateGroupBy(groupBy) {
    const todayMoment = moment();
    let arrUpdatedCalendarRange;
    let arrQuickSelectOption;
    switch (groupBy) {
      case GROUP_BY_OPTION.day.value:
        arrUpdatedCalendarRange = [
          new DateObject().subtract(1, 'day'),
          new DateObject().subtract(1, 'day'),
        ];
        arrQuickSelectOption = Object.values(QUICK_SELECT_OPTION.day);
        break;
      case GROUP_BY_OPTION.week.value:
        arrUpdatedCalendarRange = [
          new DateObject(
            todayMoment.clone().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
          ),
        ];
        arrQuickSelectOption = Object.values(QUICK_SELECT_OPTION.week);
        break;
      case GROUP_BY_OPTION.month.value:
        arrUpdatedCalendarRange = [
          new DateObject(
            todayMoment.clone().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          ),
        ];
        arrQuickSelectOption = Object.values(QUICK_SELECT_OPTION.month);
        break;
      default:
    }
    this.setState({
      groupBy,
      arrQuickSelectOption,
      arrCalendarRange: arrUpdatedCalendarRange,
    });
    this.refreshCalendarView(arrUpdatedCalendarRange[0].month, arrUpdatedCalendarRange[0].year);
  }

  /**
   * This function is invoked when a user clicks on a quick select option. This function sets
   * the arrCalendarRange state to the pre-defined date range of the option selected.
   * @param {string} option - The quick select date option clicked by user
   */
  quickSelectDate(option) {
    const todayMoment = moment();

    let arrUpdatedCalendarRange;

    switch (option) {
      case QUICK_SELECT_OPTION.day.yesterday:
        arrUpdatedCalendarRange = [
          new DateObject().subtract(1, 'day'),
          new DateObject().subtract(1, 'day'),
        ];
        break;
      case QUICK_SELECT_OPTION.day.lastSevenDays:
        arrUpdatedCalendarRange = [
          new DateObject().subtract(7, 'days'),
          new DateObject().subtract(1, 'day'),
        ];
        break;
      case QUICK_SELECT_OPTION.day.lastThirtyDays:
        arrUpdatedCalendarRange = [
          new DateObject().subtract(30, 'days'),
          new DateObject().subtract(1, 'day'),
        ];
        break;
      case QUICK_SELECT_OPTION.week.lastWeek:
        arrUpdatedCalendarRange = [
          new DateObject(
            todayMoment.clone().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.week.lastFourWeeks:
        arrUpdatedCalendarRange = [
          new DateObject(
            todayMoment.clone().subtract(4, 'week').startOf('isoWeek').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.week.lastTenWeeks:
        arrUpdatedCalendarRange = [
          new DateObject(
            todayMoment.clone().subtract(10, 'week').startOf('isoWeek').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.month.lastMonth:
        arrUpdatedCalendarRange = [
          new DateObject(
            todayMoment.clone().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.month.lastThreeMonths:
        arrUpdatedCalendarRange = [
          new DateObject(
            todayMoment.clone().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.month.lastTwelveMonths:
        arrUpdatedCalendarRange = [
          new DateObject(
            todayMoment.clone().subtract(12, 'month').startOf('month').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          ),
        ];
        break;
      default:
    }

    this.setState({ arrCalendarRange: arrUpdatedCalendarRange });
    this.refreshCalendarView(arrUpdatedCalendarRange[0].month, arrUpdatedCalendarRange[0].year);
  }

  /**
   * This function refreshes the Date Picker's view to its selected start date when user updates group by or quick select
   * @param {number} month - Month in number format (1-indexed)
   * @param {number} year - Year in number format
   */
  refreshCalendarView(month, year) {
    this.calendarRef.current.set('month', month);
    this.calendarRef.current.set('year', year);
  }

  render() {
    const { classes, closeDatePickerModal, isDatePickerModalOpen } = this.props;
    const { arrCalendarRange, groupBy, arrQuickSelectOption, isTooltipOpened } = this.state;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isDatePickerModalOpen}
        close={closeDatePickerModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        className={`datePickerModal ${classes.modalStyle}`}
        disableAutoFocus
        disableEnforceFocus
      >
        <Fade in={isDatePickerModalOpen}>
          <Box className={classes.modalForm}>
            <Box component="div" style={{ marginTop: '10px', marginBottom: '0px' }}>
              <Grid container spacing={0} justifyContent="center">
                {/* Group by buttons */}
                <Grid item xs={12} className={classes.modalFormHeader}>
                  <Typography variant="h3">Group comparisons by:</Typography>
                  <ClickAwayListener onClickAway={() => this.closeTooltip()}>
                    <Tooltip
                      open={isTooltipOpened}
                      onClose={() => this.closeTooltip()}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      title={
                        <Typography variant="caption">
                          <p>
                            Currently selecting dates grouped by
                            <b>
                              {groupBy === CONSTANT.groupByDay && ' daily '}
                              {groupBy === CONSTANT.groupByWeek && ' weekly '}
                              {groupBy === CONSTANT.groupByMonth && ' monthly '}
                            </b>
                            comparison.
                          </p>
                          {groupBy !== CONSTANT.groupByMonth && (
                            <p>
                              Maximum date range selectable is
                              <b>
                                {groupBy === CONSTANT.groupByDay && ' 56 days (8 weeks)'}
                                {groupBy === CONSTANT.groupByWeek && ' 14 weeks'}
                              </b>
                              .
                            </p>
                          )}
                          <p>
                            *Note: You may select other <i>&apos;Group comparisons by:&apos;</i>{' '}
                            options to view
                            <b>
                              {groupBy === CONSTANT.groupByDay && ' weekly or monthly '}
                              {groupBy === CONSTANT.groupByWeek && ' daily or monthly '}
                              {groupBy === CONSTANT.groupByMonth && ' daily or weekly '}
                            </b>
                            comparisons.
                          </p>
                        </Typography>
                      }
                    >
                      <InfoIcon
                        onClick={() => this.toggleTooltip()}
                        className={classes.tooltipIcon}
                      />
                    </Tooltip>
                  </ClickAwayListener>
                </Grid>
                <Grid container direction="row" spacing={0}>
                  {Object.values(GROUP_BY_OPTION).map((groupByOption) => (
                    <Grid
                      item
                      xs={4}
                      className={classes.dateRangeQuickSelectButton}
                      key={groupByOption.value}
                    >
                      <Button
                        onClick={() => this.updateGroupBy(groupByOption.value)}
                        className={`groupByButton ${groupByOption.value} ${
                          groupBy === groupByOption.value
                            ? classes.preselectedDateRangeButton
                            : classes.preselectDateRangeButton
                        }`}
                        variant="outlined"
                      >
                        {groupByOption.display}
                      </Button>
                    </Grid>
                  ))}
                </Grid>

                {/* Quick select buttons */}
                <Grid container spacing={0} direction="row">
                  <Grid item xs={12} className={classes.modalFormHeader}>
                    <Typography variant="h3">Quick select:</Typography>
                  </Grid>
                  {arrQuickSelectOption.map((quickSelectOption) => (
                    <Grid
                      item
                      xs={4}
                      key={quickSelectOption}
                      className={classes.dateRangeQuickSelectButton}
                    >
                      <Button
                        onClick={() => this.quickSelectDate(quickSelectOption)}
                        variant="outlined"
                        className={`${quickSelectOption.replaceAll(' ', '')} ${
                          classes.preselectDateRangeButton
                        }`}
                      >
                        {quickSelectOption}
                      </Button>
                    </Grid>
                  ))}
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={8} className={classes.datePickerContainer}>
                      {/* Date range picker */}
                      <DatePicker
                        arrCalendarRange={arrCalendarRange}
                        updateCalendar={(newArrCalendarRange) =>
                          this.updateCalendar(newArrCalendarRange)
                        }
                        groupBy={groupBy}
                        calendarRef={this.calendarRef}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {/* Start and End text */}
                      <Grid container direction="row" alignContent="flex-start" alignItems="center">
                        <Grid item xs={12}>
                          <Typography variant="h4" className={classes.calendarHeaderStyle}>
                            START
                          </Typography>
                          <Typography variant="h1" className={`startDate ${classes.dateText}`}>
                            {arrCalendarRange[0] && arrCalendarRange[0].format('DD MMM YYYY')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4" className={classes.calendarHeaderStyle}>
                            END
                          </Typography>
                          <Typography variant="h1" className={`endDate ${classes.dateText}`}>
                            {arrCalendarRange[1] ? arrCalendarRange[1].format('DD MMM YYYY') : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => this.closeModal()}
                    className={classes.modalCancelButtonStyle}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => this.selectDate()}
                    className={`dateSelectButton ${classes.modalConfirmButtonStyle}`}
                    disabled={arrCalendarRange.length !== 2}
                  >
                    Select
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  }
}
DatePickerModal.contextType = AppContext;

export default withStyles(styles)(DatePickerModal);
