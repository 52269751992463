import React, { PureComponent } from 'react';
// AddToWatchlistTable component
import AddToWatchlistTable from './AddToWatchlistTable';
import Searchbar from '../../common/Searchbar';

/**
 * A PureComponent that renders a searchbar and an AddToWatchlistTable
 */
class AddToWatchlistModal extends PureComponent {
  render() {
    const {
      arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      searchValue,
      arrFilteredMenuItemRestaurant,
      arrSelectedMenuItemRestaurant,
      onChangeSearchbar,
      onClickMenuItemCard,
      onClickSubmit,
    } = this.props;

    return (
      <>
        <Searchbar onChange={onChangeSearchbar} searchValue={searchValue} />
        <AddToWatchlistTable
          arrSelectedMenuItemRestaurant={arrSelectedMenuItemRestaurant}
          arrFilteredMenuItemRestaurant={arrFilteredMenuItemRestaurant}
          searchValue={searchValue}
          arrWatchlistItemMenuItemRestaurantWasteAnalysis={
            arrWatchlistItemMenuItemRestaurantWasteAnalysis
          }
          onClickMenuItemCard={onClickMenuItemCard}
          onClickSubmit={onClickSubmit}
        />
      </>
    );
  }
}

export default AddToWatchlistModal;
