import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  cancelButton: {
    marginLeft: '10px',
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    borderRadius: '7px',
  },
  confirmButton: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primaryGradient,
    borderRadius: '7px',
    marginRight: '12px',
  },
});

class ConfirmationDialog extends PureComponent {
  render() {
    const {
      classes,
      menuItemName,
      isConfirmationDialogOpen,
      onClickCancel,
      onClickSubmit,
      onClose,
    } = this.props;

    return (
      <Grid>
        <Dialog
          open={isConfirmationDialogOpen}
          onClose={onClose}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h2">
                Remove <b>{menuItemName}</b> from your watchlist?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid>
              <Button
                variant="outlined"
                size="small"
                className={classes.cancelButton}
                onClick={onClickCancel}
              >
                <Typography variant="h6">Cancel</Typography>
              </Button>
            </Grid>
            <Grid style={{ flex: '1 0 0' }} />
            <Grid>
              <Button
                variant="outlined"
                size="small"
                className={classes.confirmButton}
                onClick={onClickSubmit}
              >
                <Typography variant="h6">Confirm</Typography>
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}
export default withStyles(styles)(ConfirmationDialog);
