import axios from 'axios';
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import './App.css';
import { AppContext, CONSTANT } from './AppContext';
import ConfirmationSnackbar from './components/common/ConfirmationSnackbar';
import Loader from './components/common/Loader';
import FoodItemsBreakdown from './components/food-items-breakdown/FoodItemsBreakdown';
import Header from './components/header/Header';
import Home from './components/home/Home';
import CallbackPage from './components/login/CallbackPage';
import ImpersonatorPage from './components/login/ImpersonatorPage';
import LoginPage from './components/login/LoginPage';
import ScrollToTop from './components/ScrollToTop';
import ChangePassword from './components/sidebar-pages/ChangePassword';
import ContactUs from './components/sidebar-pages/ContactUs';
import CoverInput from './components/sidebar-pages/CoverInput';
import MenuItemMapping from './components/sidebar-pages/MenuItemMapping';
import StationsBreakdown from './components/stations-breakdown/StationsBreakdown';
import WatchlistClickthrough from './components/watchlist-clickthrough/WatchlistClickthrough';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.globalBackground,
    minHeight: '100vh',
    maxHeight: 'auto',
    overflow: 'auto',
  },
  homeDefault: {
    width: '100%',
    maxWidth: '650px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '1300px',
    },
  },
  homeCoverInput: {
    width: '100%',
    maxWidth: '650px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '90vw',
    },
  },
});

/**
 * Parse impersonatorArrLocationId from local storage which is always stored as a string
 * impersonatorArrLocationId is deemed valid if it can be parsed into an array and every element in the array is a number
 * @param {string} impersonatorArrLocationIdString - String of impersonatorArrLocationId to be parsed
 * @returns {Array<number>} - If impersonatorArrLocationIdString is a valid number array, then impersonatorArrLocationId is returned, else an empty array is returned
 */
const parseImpersonatorArrLocationId = (impersonatorArrLocationIdString) => {
  try {
    const arrLocationId = JSON.parse(`[${impersonatorArrLocationIdString}]`);
    if (
      Array.isArray(arrLocationId) &&
      arrLocationId.every((locationId) => typeof locationId === 'number')
    ) {
      return arrLocationId;
    }
    return [];
  } catch (error) {
    return [];
  }
};

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      impersonatorIsCompanyUser: localStorage.getItem('impersonatorIsCompanyUser') === 'true',
      impersonatorName: localStorage.getItem('impersonatorName'),
      impersonatorArrLocationId: parseImpersonatorArrLocationId(
        localStorage.getItem('impersonatorArrLocationId')
      ),
      selectedStartDate: localStorage.getItem('startDate'),
      selectedEndDate: localStorage.getItem('endDate'),
      selectedGroupBy: localStorage.getItem('groupBy'),
      impersonatorDatavizUserId: Number(localStorage.getItem('impersonatorDatavizUserId')),
      pageHistory: [],
      numberOfUnreadNotifications: '',

      // To indicate if app data is fetched
      isAppDataFetched: false,

      // App Data
      arrRestaurantService: [],
      arrNotification: [],
      companyName: null,
      companyLogoURL: null,
      organisationName: null,
      currency: '',

      // Snackbar
      isSnackbarOpen: false,
      snackbarText: '',
      snackbarType: '',

      // Scrollable header's current transform styleesetStartDate
      scrollableHeaderTransform: null,
      scrollableHeaderTransition: null,

      // Loader
      showLoader: false,

      isForcePasswordChange: null,
    };
  }

  // To call fetchAppData after logging in
  componentDidUpdate(p, prevState) {
    const { impersonatorDatavizUserId, isAppDataFetched } = this.state;
    const { auth0 } = this.props;
    const { isAuthenticated, isLoading } = auth0;
    const { user } = auth0;

    // When the same user log out and log in again, the 'isAppDataFetched' which is false and the valid token will trigger the fetchAppData
    // in ComponentDidUpdate to fetch the appdata for the user again as the App component is already mount
    if (
      !isAppDataFetched &&
      !isLoading &&
      isAuthenticated &&
      (!user.isAdmin ||
        (user.isAdmin && impersonatorDatavizUserId) ||
        (user.isAdmin && prevState.impersonatorDatavizUserId !== impersonatorDatavizUserId))
    ) {
      this.fetchAppData();
      // This removal of the loader if isAppDataFetched is false is for pages that are loaded
      // without any backend calls, and therefore the loader is not removed when these pages are
      // loaded. These can hapen when the page's URL is input directly.
      if (!isAppDataFetched) {
        this.renderLoaderAnimation(false);
      }
    }
  }

  onCloseSnackbar() {
    this.setState({
      isSnackbarOpen: false,
    });
  }

  setImpersonatorDatavizUserId(newImpersonatorDatavizUserId) {
    localStorage.setItem('impersonatorDatavizUserId', newImpersonatorDatavizUserId);
    this.setState({
      impersonatorDatavizUserId: newImpersonatorDatavizUserId,
    });
  }

  setImpersonatorName(newImpersonatorName) {
    localStorage.setItem('impersonatorName', newImpersonatorName);
    this.setState({ impersonatorName: newImpersonatorName });
  }

  setImpersonatorIsCompanyUser(newImpersonatorIsCompanyUser) {
    localStorage.setItem('impersonatorIsCompanyUser', newImpersonatorIsCompanyUser);
    this.setState({
      impersonatorIsCompanyUser: newImpersonatorIsCompanyUser,
    });
  }

  setImpersonatorArrLocationId(newImpersonatorArrLocationId) {
    localStorage.setItem('impersonatorArrLocationId', newImpersonatorArrLocationId);
    this.setState({
      impersonatorArrLocationId: newImpersonatorArrLocationId,
    });
  }

  setScrollableHeaderStyle(transform, transition) {
    this.setState({
      scrollableHeaderTransform: transform,
      scrollableHeaderTransition: transition,
    });
  }

  setUserId(newUserId) {
    // This userId is used when the user remounts the app after he/she has already successfully logged in
    localStorage.setItem('userId', newUserId);
    this.setState({
      userId: newUserId,
    });
  }

  setStartDate(newSelectedStartDate) {
    localStorage.setItem('startDate', newSelectedStartDate);
    this.setState({
      selectedStartDate: newSelectedStartDate,
    });
  }

  setEndDate(newSelectedEndDate) {
    localStorage.setItem('endDate', newSelectedEndDate);
    this.setState({
      selectedEndDate: newSelectedEndDate,
    });
  }

  setGroupBy(newSelectedGroupBy) {
    // Note: groupBy should be a valid GROUP_BY string (see AppContext.Constants)
    localStorage.setItem('groupBy', newSelectedGroupBy);
    this.setState({
      selectedGroupBy: newSelectedGroupBy,
    });
  }

  setPageHistory(newPageHistory) {
    // Note: pageHistory should be a list of page titles (see AppContext.Constants)
    this.setState({
      pageHistory: newPageHistory,
    });
  }

  setIsForcePasswordChange(newIsForcePasswordChange) {
    localStorage.setItem('isForcePasswordChange', newIsForcePasswordChange);
    this.setState({
      isForcePasswordChange: newIsForcePasswordChange,
    });
  }

  /**
   * This function is used when the user log outs. When the user logs out, the isAppDateFetched parameter is set to false so that when the user
   * login again, the Home component is only mounted when the app data for the current user is fetched. If not, the data from the previous user
   * will remain in the App component mounted and cause issues when other child components mount.
   * @param {boolean} newIsAppDataFetched - Indicates if appData should be fetched
   */
  setIsAppDataFetched(newIsAppDataFetched) {
    this.setState({
      isAppDataFetched: newIsAppDataFetched,
    });
  }

  async updateNumberOfUnreadNotifications(
    updatedNumberOfUnreadNotifications,
    updatedArrNotification
  ) {
    const { auth0 } = this.props;
    const token = await auth0.getAccessTokenSilently();
    const { user } = auth0;
    try {
      await axios.post(
        '/api/update-notification',
        {
          userId: user.datavizUserId,
          arrNotification: updatedArrNotification,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.setState({
        numberOfUnreadNotifications: updatedNumberOfUnreadNotifications,
      });
    } catch (error) {
      this.openSnackbar(
        'Unknown error when updating number of unread notifications. Please notify admin.',
        'error'
      );
    }
  }

  /**
   * Fetch the company, restaurant, location and service that the user has access to or from locations requested
   * by an admin user through the impersonator page. After the data is fetched from the Backend,
   * check if user is a company user. If yes, organisation name (name that the user will see on the Header) will be the company name.
   * If no, it will be the restaurant name.
   */
  async fetchAppData() {
    const { auth0 } = this.props;
    const { user } = auth0;
    let userId = user.datavizUserId;
    let arrLocationId;
    if (user.isAdmin) {
      const { impersonatorDatavizUserId, impersonatorArrLocationId } = this.state;
      userId = impersonatorDatavizUserId;
      arrLocationId = impersonatorArrLocationId;
    }
    window.dataLayer.push({
      datavizUserId: userId,
      isAdmin: user.isAdmin,
      event: 'login',
    });
    try {
      this.renderLoaderAnimation(true);
      const token = await auth0.getAccessTokenSilently();
      const response = await axios.post(
        '/api/fetch-app-data',
        {
          userId,
          arrLocationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { appData } = response.data;
      const { companyService, companyLogoURL, arrNotification, numberOfUnreadNotifications } =
        appData;

      let arrRestaurantService = [];
      let organisationName = '';
      let currency = '';
      if (companyService.arrRestaurantService.length !== 0) {
        organisationName =
          companyService.arrRestaurantService.length > 1
            ? companyService.name
            : companyService.arrRestaurantService[0].name;
        arrRestaurantService = companyService.arrRestaurantService;
        currency =
          companyService.arrRestaurantService[0].arrLocationService[0].arrService[0].currency;
      }

      // Update state with app data retrieved
      this.setState({
        isAppDataFetched: true,
        arrRestaurantService,
        companyName: companyService.name,
        companyLogoURL,
        organisationName,
        currency,
        pageHistory: [`${organisationName}: ${CONSTANT.highlightsPage}`],
        //* Hardcoded data values for now *//
        arrNotification,
        numberOfUnreadNotifications,
      });
    } catch (error) {
      this.renderLoaderAnimation(false);
      const { response } = error;

      // response.status = 401 is a JWT web token error. For this error, there is no need for an error snackbar to be shown.
      if (!(response && response.status === 401)) {
        this.openSnackbar('Unknown error when fetching app data. Please notify admin.', 'error');
      }
      // To remove the token for any error in fetching appData so that AppData component will not keep on re-rendering and the
      // error snackbar will not keep popping up. This can also improve efficiency as there are lesser redundant re-rendering.
      // Push to login page
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      // To cause re-rendering when the token is removed
      this.setState({
        userId: '',
      });
    }
  }

  openSnackbar(newSnackbarText, newSnackbarType) {
    this.setState({
      isSnackbarOpen: true,
      snackbarText: newSnackbarText,
      snackbarType: newSnackbarType,
    });
  }

  renderLoaderAnimation(newShowLoader) {
    this.setState({
      showLoader: newShowLoader,
    });
  }

  render() {
    const {
      arrNotification,
      arrRestaurantService,
      companyLogoURL,
      companyName,
      currency,
      impersonatorDatavizUserId,
      impersonatorIsCompanyUser,
      impersonatorArrLocationId,
      impersonatorName,
      isAppDataFetched,
      isForcePasswordChange,
      isSnackbarOpen,
      numberOfUnreadNotifications,
      organisationName,
      pageHistory,
      scrollableHeaderTransform,
      scrollableHeaderTransition,
      selectedEndDate,
      selectedGroupBy,
      selectedStartDate,
      showLoader,
      snackbarText,
      snackbarType,
      userId,
    } = this.state;

    const { classes } = this.props;
    const { auth0 } = this.props;

    const { isAuthenticated, isLoading, user } = auth0;

    return (
      <AppContext.Provider
        value={{
          impersonatorName,
          impersonatorDatavizUserId,
          impersonatorIsCompanyUser,
          impersonatorArrLocationId,
          isForcePasswordChange,
          userId,
          companyName,
          currency,
          organisationName,
          companyLogoURL,
          // localStorage store all variables as string so need to convert to boolean
          selectedStartDate,
          selectedEndDate,
          selectedGroupBy,
          pageHistory,
          numberOfUnreadNotifications,
          showLoader,
          fetchAppData: () => this.fetchAppData(),
          setImpersonatorDatavizUserId: (newImpersonatorDatavizUserId) =>
            this.setImpersonatorDatavizUserId(newImpersonatorDatavizUserId),
          setImpersonatorName: (newImpersonatorName) =>
            this.setImpersonatorName(newImpersonatorName),
          setImpersonatorIsCompanyUser: (newImpersonatorIsCompanyUser) =>
            this.setImpersonatorIsCompanyUser(newImpersonatorIsCompanyUser),
          setImpersonatorArrLocationId: (newImpersonatorArrLocationId) =>
            this.setImpersonatorArrLocationId(newImpersonatorArrLocationId),
          setIsAppDataFetched: (newIsAppDataFetched) =>
            this.setIsAppDataFetched(newIsAppDataFetched),
          setIsForcePasswordChange: (newIsForcePasswordChange) =>
            this.setIsForcePasswordChange(newIsForcePasswordChange),
          setUserId: (newUserId) => this.setUserId(newUserId),
          setStartDate: (newSelectedStartDate) => this.setStartDate(newSelectedStartDate),
          setEndDate: (newSelectedEndDate) => this.setEndDate(newSelectedEndDate),
          setGroupBy: (newSelectedGroupBy) => this.setGroupBy(newSelectedGroupBy),
          setPageHistory: (newPageHistory) => this.setPageHistory(newPageHistory),
          updateNumberOfUnreadNotifications: (
            updatedNumberOfUnreadNotifications,
            updatedArrNotification
          ) =>
            this.updateNumberOfUnreadNotifications(
              updatedNumberOfUnreadNotifications,
              updatedArrNotification
            ),
          openSnackbar: (newSnackbarText, newSnackbarType) =>
            this.openSnackbar(newSnackbarText, newSnackbarType),
          renderLoaderAnimation: (newShowLoader) => this.renderLoaderAnimation(newShowLoader),
        }}
      >
        <ScrollToTop />

        {/* Look through <Route>s and renders the first one that matches current URL */}
        <Switch>
          {/* Login page */}
          <Route path="/login">
            <LoginPage />
          </Route>

          {/* Redirect to login page if user is not logged in */}
          {!isLoading && !isAuthenticated && <Redirect to="/login" />}

          {/* Callback page to customise post-login behaviour */}
          <Route path="/callback">
            <CallbackPage />
          </Route>

          {/* Impersonator page for admin user */}
          {!isLoading && isAuthenticated && user.isAdmin && (
            <Route path="/impersonator">
              <ImpersonatorPage />
            </Route>
          )}

          {!isLoading && isAuthenticated && user.isAdmin && !impersonatorDatavizUserId && (
            <Redirect to="/impersonator" />
          )}

          {isAppDataFetched && (
            <Grid container direction="column" alignItems="center" className={classes.root}>
              {/* Do not place this component in a Grid, it is already wrapped in one */}
              <Header
                arrNotification={arrNotification}
                arrRestaurantService={arrRestaurantService}
                setScrollableHeaderStyle={(transform, transition) =>
                  this.setScrollableHeaderStyle(transform, transition)
                }
              />

              <Grid item className={classes.homeDefault}>
                {/* Settings */}
                {/* <Route path="/settings" render={(routeProps) => <Settings {...routeProps} />} /> */}
                {/* Contact Us */}
                <Route path="/contact-us" render={(routeProps) => <ContactUs {...routeProps} />} />
                {/* Change Password */}
                <Route
                  path="/change-password"
                  render={(routeProps) => <ChangePassword {...routeProps} />}
                />
                {/* Menu Item Mapping */}
                <Route
                  path="/menu-item-mapping"
                  render={(routeProps) => (
                    <MenuItemMapping {...routeProps} arrRestaurantService={arrRestaurantService} />
                  )}
                />
                {/* View all watchlist items */}
                <Route
                  path="/watchlist"
                  render={(routeProps) => (
                    <WatchlistClickthrough
                      {...routeProps}
                      arrRestaurantService={arrRestaurantService}
                    />
                  )}
                />
                {/* Stations breakdown page, with restaurantId parameter.
                      Note that the inline function passed to Route's render prop is allowed
                      by react-router, and will not cause unnecessary re-renders. */}
                <Route
                  path="/stations/:restaurantId"
                  render={(routeProps) => (
                    <StationsBreakdown
                      {...routeProps}
                      arrRestaurantService={arrRestaurantService}
                    />
                  )}
                />
                {/* Items breakdown page, with restaurantId parameter. */}
                <Route
                  path="/items/:restaurantId"
                  render={(routeProps) => (
                    <FoodItemsBreakdown
                      {...routeProps}
                      arrRestaurantService={arrRestaurantService}
                    />
                  )}
                />
                {/* Most generic path: URLs that don't match anything above */}
                <Route path="/" exact>
                  <Home
                    arrRestaurantService={arrRestaurantService}
                    scrollableHeaderTransform={scrollableHeaderTransform}
                    scrollableHeaderTransition={scrollableHeaderTransition}
                  />
                </Route>
              </Grid>
              <Grid container item justifyContent="center" className={classes.homeCoverInput}>
                {/* Cover Input */}
                <Route
                  path="/cover-input"
                  render={(routeProps) => (
                    <CoverInput
                      id="coverInput"
                      {...routeProps}
                      arrRestaurantService={arrRestaurantService}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Switch>
        <ConfirmationSnackbar
          isSnackbarOpen={isSnackbarOpen}
          snackbarText={snackbarText}
          snackbarType={snackbarType}
          onClose={() => this.onCloseSnackbar()}
        />

        <Loader />
      </AppContext.Provider>
    );
  }
}

export default withAuth0(withTheme(withStyles(styles)(App)));
