import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import { AppContext } from '../../AppContext';

/**
 * Custom styles for the dropdownlist component
 */
const styles = (theme) => ({
  selectPadding: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '12px',
  },
  selectorMenu: {},
  selectStyle: {
    color: theme.palette.gray1,
  },
  dropdownLabelStyle: {
    paddingRight: '2px',
    paddingLeft: '2px',
  },
  dropdownLabelText: {
    ...theme.typography.body2,
  },
  dropdownText: {
    ...theme.typography.body2,
    color: theme.palette.gray1,
  },
  '@global': {
    '.MuiInputBase-root': {
      backgroundColor: 'white',
    },
    '.MuiSelect-select:focus': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
});

/**
 * The required props are :
 * - label           : title string of dropdown
 * - onChangeHandler : function to invoke when selection is changed
 * - selectedValue   : currently selected value
 * - arrDropdownItem : array of objects with properties: { name, value }
 * - backgroundColor : background color for the label in string format : '#F9F9F9' or 'white'
 * - minWidth        : minimum width of dropdown
 */

class DropdownList extends PureComponent {
  render() {
    const { backgroundColor, classes, arrDropdownItem, label, minWidth, selectedValue, onChange } =
      this.props;

    return (
      <Box>
        <FormControl
          variant="outlined"
          focused={false}
          style={{ minWidth: minWidth || '100px' }} // Set the min-width based on props
        >
          <InputLabel
            shrink
            id="group-by-dropdown-label"
            className={classes.dropdownLabelStyle}
            style={{ backgroundColor }} // Set the backgroundColor based on props
          >
            <Typography className={classes.dropdownLabelText}>{label}</Typography>
          </InputLabel>
          <Select
            labelId="group-by-dropdown-label"
            id="group-by-dropdown"
            value={selectedValue}
            onChange={onChange}
            className={classes.selectStyle}
            classes={{
              outlined: classes.selectPadding,
              root: classes.whiteBackground,
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
          >
            {arrDropdownItem.map((dropdownItem) => (
              <MenuItem
                value={dropdownItem.value}
                key={dropdownItem.value}
                classes={{ root: classes.selectorMenu }}
              >
                <Typography className={classes.dropdownText}>{dropdownItem.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }
}
DropdownList.contextType = AppContext;

export default withStyles(styles)(DropdownList);
