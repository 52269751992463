import React, { PureComponent } from 'react';
// Styling
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// Button component
import Button from '@material-ui/core/Button';
// AddToWatchlistCard component
import AddToWatchlistCard from './AddToWatchlistCard';

/**
 * Custom styles for the AddToWatchlistTable component
 */
const styles = (theme) => ({
  // Styling for the list of AddToWatchlistCards
  cardList: {
    height: '230px',
    overflowY: 'auto',
  },

  // Styling for the add button
  enabledAddButton: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primaryGradient,
  },
  button: {
    float: 'right',
    margin: '35px 0px 20px 0px',
    maxHeight: '26px',
    minWidth: '40px',
    minHeight: '26px',
    borderRadius: '10px',
    padding: '0px 5px',
  },

  // Styling for the results count
  resultsCountText: {
    color: theme.palette.gray2,
    paddingTop: '24px',
    paddingLeft: '12px',
  },
});

/**
 * A PureComponent that renders a list of AddToWatchlistCards and functionality to add to watchlist
 */
class AddToWatchlistTable extends PureComponent {
  render() {
    const {
      classes,
      arrFilteredMenuItemRestaurant,
      arrSelectedMenuItemRestaurant,
      searchValue,
      onClickMenuItemCard,
      onClickSubmit,
    } = this.props;

    return (
      <div>
        {searchValue === '' ? null : (
          <Typography
            className={classes.resultsCountText}
            variant="h6"
          >{`${arrFilteredMenuItemRestaurant.length} RESULTS`}</Typography>
        )}

        <div className={classes.cardList}>
          {/* Show different color AddToWatchlistCard with different props based on whether it is selected */}
          {arrFilteredMenuItemRestaurant.map((menuItemRestaurant) => (
            <AddToWatchlistCard
              menuItemRestaurant={menuItemRestaurant}
              onClick={onClickMenuItemCard}
              isSelected={arrSelectedMenuItemRestaurant.includes(menuItemRestaurant)}
              key={`${menuItemRestaurant.menuItemName} ${menuItemRestaurant.restaurantName} ${menuItemRestaurant.locationName} ${menuItemRestaurant.station} ${menuItemRestaurant.serviceName}`}
            />
          ))}
        </div>

        {/* Show disabled button when user has searched something but has not selected anything */}
        {arrFilteredMenuItemRestaurant.length > 0 && arrSelectedMenuItemRestaurant.length === 0 ? (
          <Button variant="outlined" disabled className={classes.button}>
            <Typography variant="h6">ADD</Typography>
          </Button>
        ) : null}

        {/* Show enabled button when user has searched something and has selected something */}
        {arrFilteredMenuItemRestaurant.length > 0 && arrSelectedMenuItemRestaurant.length > 0 ? (
          <Button
            className={`${classes.enabledAddButton} ${classes.button}`}
            onClick={onClickSubmit}
            variant="contained"
          >
            <Typography variant="h6">ADD</Typography>
          </Button>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(AddToWatchlistTable);
