import React, { PureComponent } from 'react';

// Material UI Components
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { AppContext } from '../../AppContext';

// Loader Image
// import LoaderImg from '../../imgs/Loader.gif';
// import LoaderImg from '../../imgs/Loader4.gif';

// import LoaderImg from '../../imgs/Loader2.gif';
import LoaderImg from '../../imgs/Loader3.gif';
// import LoaderImg from '../../imgs/Loader5.gif';

const styles = () => ({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: 'white',
    opacity: 0.8,
    zIndex: 99,
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  img: {
    width: '180px',
  },
});

class Loader extends PureComponent {
  render() {
    const { classes } = this.props;
    const { showLoader } = this.context;

    return (
      showLoader && (
        <Box className={classes.container}>
          <Box className={classes.loader}>
            <img src={LoaderImg} className={classes.img} alt="loader" />
          </Box>
        </Box>
      )
    );
  }
}

Loader.contextType = AppContext;

export default withStyles(styles)(Loader);
