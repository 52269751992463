import React, { PureComponent } from 'react';

// Material UI Components
import { Box, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    border: 0,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
}))(Button);

const styles = (theme) => ({
  container: {
    height: '50%',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  },
  dataVariable: {
    fontSize: '30px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: '24px',
    },
    textWrap: 'nowrap',
    margin: 0,
    fontWeight: 700,
  },
  dataName: {
    fontSize: '12px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: '9px',
    },
    margin: 0,
  },
  negativeReductionValue: {
    color: theme.palette.info.main,
  },
  positiveReductionValue: {
    color: theme.palette.success.main,
  },
  neutralValue: {
    color: 'white',
  },
});
class KeyOverviewValueCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * This function gets the class to be given to a value depending on whether it is a positive number, negative number or neutral
   * @param {string} selector - Determines if value belongs to which key overview value
   * @param {number | string} dataVariable - Value of variable to be determined as positive, negative or neutral
   * @returns Style Class - Class to be given to element
   */
  getIncreasedOrDecreasedReductionValue(selector, dataVariable) {
    const { classes, SHOW_MORE_VALUE } = this.props;
    const isColorRequired =
      selector === SHOW_MORE_VALUE.reductionInCost ||
      selector === SHOW_MORE_VALUE.reductionInWeight ||
      selector === SHOW_MORE_VALUE.reductionInWastePerCover ||
      selector === SHOW_MORE_VALUE.co2Emissions ||
      selector === SHOW_MORE_VALUE.mealsSaved;
    if (isColorRequired && dataVariable !== '-' && dataVariable !== 'NA') {
      if (dataVariable > 0) {
        return classes.positiveReductionValue;
      }
      if (dataVariable < 0) {
        return classes.negativeReductionValue;
      }
    }

    return classes.neutralReductionValue;
  }

  /**
   * This function formats the data title name to be displayed for carbon dioxide emissions and meals saved.
   * For negative 'meals saved', the data name is converted to 'meals additionally wasted'
   * For negative 'co2 emissions avoided', the data name is converted to 'co2 emissions additionally emitted'
   * @param {string} selector - Type of data variable to be formatted
   * @param {string} dataName - Data name to be formatted
   * @param {number} dataVariable - Value of data relating to data name
   * @returns
   */
  formatDataName(selector, dataName, dataVariable) {
    const { SHOW_MORE_VALUE } = this.props;
    if (selector === SHOW_MORE_VALUE.mealsSaved) {
      return dataVariable >= 0 ? `${dataName} Saved` : `${dataName} Additionally Wasted`;
    }
    if (selector === SHOW_MORE_VALUE.co2Emissions) {
      return dataVariable >= 0 ? `${dataName} Avoided` : `${dataName} Additionally Emitted`;
    }
    return dataName;
  }

  render() {
    const {
      classes,
      dataName,
      dataVariable,
      dataFrontUnit,
      dataBackUnit,
      isButtonDisabled,
      showMoreOnClick,
      selector,
      formatValue,
    } = this.props;

    return (
      <Grid item xs={6} variant="contained" className={classes.container}>
        <ColorButton
          color="primary"
          disabled={isButtonDisabled || dataVariable === 'NA'}
          className={selector}
          onClick={() => showMoreOnClick(selector)}
        >
          <Box className={classes.buttonBox}>
            <p
              className={`${classes.dataVariable} ${this.getIncreasedOrDecreasedReductionValue(
                selector,
                dataVariable
              )}`}
            >
              {dataFrontUnit}
              {formatValue(selector, dataVariable)}
              {dataBackUnit}
            </p>
            <p className={classes.dataName}>
              {this.formatDataName(selector, dataName, dataVariable)}
            </p>
          </Box>
        </ColorButton>
      </Grid>
    );
  }
}

export default withStyles(styles)(KeyOverviewValueCard);
