import React, { PureComponent } from 'react';
// Chart Library
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

/**
 * A PureComponent that renders a line chart.
 */

class TrendLine extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chartOption: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: false,
          },
        },
      },
    };
  }

  render() {
    const { chartData, height, width } = this.props;
    const { chartOption } = this.state;

    return (
      <Line
        height={height}
        width={width}
        data={JSON.parse(JSON.stringify(chartData))}
        options={chartOption}
      />
    );
  }
}

export default TrendLine;
