import React, { PureComponent } from 'react';

// Snackbar component
import SnackBar from '@material-ui/core/Snackbar';

// Styling
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import { AppContext } from '../../AppContext';

const styles = (theme) => ({
  success: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    maxWidth: 305,
  },

  error: {
    background: theme.palette.info.main,
    borderRadius: 10,
    maxWidth: 305,
  },
});

class ConfirmationSnackbar extends PureComponent {
  render() {
    const { classes, snackbarText, snackbarType, isSnackbarOpen, onClose } = this.props;

    return (
      <div className="snackBar">
        <SnackBar
          ContentProps={{
            classes: {
              root: `${snackbarType === 'success' ? classes.success : classes.error}`,
            },
          }}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={onClose}
          message={<Typography variant="h4">{snackbarText}</Typography>}
        />
      </div>
    );
  }
}
ConfirmationSnackbar.contextType = AppContext;

export default withStyles(styles)(ConfirmationSnackbar);
