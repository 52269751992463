import axios from 'axios';
import React, { createRef, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Typo from 'typo-js';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';

import MenuItemInput from './MenuItemInput';
// Below eslint-disable necessary as typedefs are used in function descriptions
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext, CONSTANT } from '../../AppContext';

/**
 * This function returns the dialog content to be displayed
 * @param {Array<{locationName: string, serviceName: string}>} arrLocationServiceOfMenu - Array of location and service names for each menu
 * @returns {{warning: {header: string, body: React.ReactFragment}, error: {header: string, body: string}, empty: {header: string, body: string}}} - Object containing warning, error and empty dialog header and body content to be displayed
 */
const getDialogContent = (arrLocationServiceOfMenu = []) => {
  return {
    saveChangesWarning: {
      header: 'WARNING',
      body: (
        <>
          <p>
            Please note that you are only submitting changes for{' '}
            <b>
              {arrLocationServiceOfMenu
                .map((locationServiceOfMenu) => {
                  const { locationName, serviceName } = locationServiceOfMenu;
                  return `${locationName} > ${serviceName}`;
                })
                .join(', ')}
            </b>
            .
          </p>
          <p>
            Please ensure menu item mapping submitted are correct as changes are final and
            irreversible.
          </p>
          <p>
            Please click &#x27;<b>CONFIRM</b>&#x27; if you want to submit the changes, or click
            &#x27;
            <b>CANCEL</b>&#x27; if you would like to check the changes again.
          </p>
        </>
      ),
    },
    saveDraftWarning: {
      header: 'WARNING',
      body: (
        <>
          <p>
            Please note that you are only saving draft for{' '}
            <b>
              {arrLocationServiceOfMenu
                .map((locationServiceOfMenu) => {
                  const { locationName, serviceName } = locationServiceOfMenu;
                  return `${locationName} > ${serviceName}`;
                })
                .join(', ')}
            </b>
            .
          </p>
          <p>
            Please click &#x27;<b>CONFIRM</b>&#x27; if you want to save draft, or click &#x27;
            <b>CANCEL</b>&#x27; if you would like to check the changes again.
          </p>
        </>
      ),
    },
    saveChangesError: {
      header: 'NOTE',
      body: 'Please check for any errors or partially filled menu items!',
    },
    saveDraftError: {
      header: 'NOTE',
      body: 'Please check for any errors!',
    },
    empty: {
      header: 'NOTE',
      body: 'No menu item mapping found!',
    },
  };
};

const dictionary = new Typo('en-GB', false, false, {
  dictionaryPath: '/typo/dictionaries',
});

const styles = (theme) => ({
  inputContainer: {
    minWidth: 'min-content',
  },
  menuContainer: {
    backgroundColor: '#e6e6e6',
    padding: '20px 50px',
    margin: '10px 10px 20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.2)',
  },
  menuHeader: {
    fontSize: '12px',
    minWidth: 'min-content',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#a5acad',
    },
  },
  saveChangesButtonStyle: {
    boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.12)',
    borderRadius: '12px',
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '6px 18px',
    '& .MuiButton-startIcon': {
      marginRight: '6px',
      color: theme.palette.primary2,
    },
    width: '200px',
    alignSelf: 'flex-end',
  },
  saveDraftButtonStyle: {
    boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.12)',
    borderRadius: '12px',
    backgroundColor: theme.palette.primary.main2,
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '6px 18px',
    '& .MuiButton-startIcon': {
      marginRight: '6px',
      color: theme.palette.primary3,
    },
    width: '200px',
    alignSelf: 'flex-end',
  },
  warningTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: theme.palette.info.main,
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: theme.palette.info.main,
      color: '#ffffff',
    },
  },
  confirmButton: {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
    '&:hover': {
      color: '#000000',
      backgroundColor: '#5ab4b8',
    },
  },
  scrollToTopButton: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    bottom: '14px',
    right: '18px',
    color: 'white',
    padding: '0px',
    minWidth: '0',
    height: '3rem',
    width: '3rem',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  tooltipContainer: {
    maxHeight: '12rem',
    overflow: 'auto',
  },
});

/**
 * @typedef State
 * @prop {{header: string, body: string}} dialogContent - Content of dialog to be shown to user
 * @prop {Map<number, Array<{locationName: string, serviceName: string}>>} mapArrLocationServiceByMenuId - Map of locationName and serviceName by menuId to be used for displaying on tab headers
 * @prop {Map<number, {menuItemName: string, station: string, costPerKilogram: string | number, menuId: number}>} mapInputByGroupId - Map of input by groupId which contains all user input by user
 * @prop {Map<number, boolean>} mapIsErrorByGroupId - Map of isError by groupId for checking if there are any input validation errors in an input card
 * @prop {Map<number, boolean>} mapIsPartiallyFilledByGroupId - Map of isError by groupId for checking if there are any partially filled fields in an input card
 * @prop {Map<number, boolean>} mapIsEmptyByGroupId - Map of isEmpty by groupId for checking if input card is empty
 * @prop {Map<number, Array<string>>} mapArrImageURLByGroupId - Map of arrImageURL by groupId to be displayed to user for mapping
 * @prop {Map<number, Set<number>>} mapSetGroupIdByMenuId - Map of setInternalMenuItem by menuId to display the appropriate internal menu items per menuId
 * @prop {boolean} isMenuItemMappingDataFetched - To be set to true when internal menu items to be mapped have been fetched from backend
 * @prop {boolean} isDialogOpened - Whether dialog is opened to be viewed by user. Contents of dialog can be found in dialogContent state
 * @prop {{menuItemName: Map<string, number>, station: Map<string, number>}} mapInputCountByInputByInputType - Contains 2 mapInputCountByInput of menuItemName and station. Their count of inputs are kept to ensure the correct list of suggestions are displayed
 * @prop {number} selectedTab - The current tab, corresponding to the menuId, that user is viewing
 * @prop {string} saveType - The type of save 'Save Draft' or 'Submit' to denote which dialog content and callback function to be used when saving
 * @prop {Map<number, Array<number>} mapArrGroupIdByClientMenuItemId - Map of arrGroupId with the same clientMenuItemId to denote if splitting of client menu item is necessary if the menu item names are different
 */

class MenuItemMapping extends PureComponent {
  constructor(props) {
    super(props);
    /** @type {State} */
    this.state = {
      dialogContent: { header: '', body: '' },
      mapArrLocationServiceByMenuId: new Map(),
      mapInputByGroupId: new Map(),
      mapIsErrorByGroupId: new Map(),
      mapIsPartiallyFilledByGroupId: new Map(),
      mapIsEmptyByGroupId: new Map(),
      mapArrImageURLByGroupId: new Map(),
      mapArrInternalMenuItemIdWasteIdByGroupId: new Map(),
      mapSetGroupIdByMenuId: new Map(),
      isMenuItemMappingDataFetched: false,
      isDialogOpened: false,
      saveType: null,
      mapInputCountByInputByInputType: {
        menuItemName: new Map(),
        station: new Map(),
      },
      selectedTab: 0,
      mapMapExistingMenuItemByMenuIdByMenuItemName: new Map(),
      mapArrGroupIdByClientMenuItemId: new Map(),
    };
    this.scrollToTopRef = createRef();
  }

  componentDidMount() {
    document.title = 'Menu Item Mapping | Lumitics | Towards Zero Food Waste';
    const { impersonatorIsCompanyUser, openSnackbar, setPageHistory, renderLoaderAnimation } =
      this.context;
    const { auth0, history } = this.props;
    const { user } = auth0;

    if ((!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser)) {
      openSnackbar(
        'Please note that you do not have the access to menu item mapping page!',
        'error'
      );
      history.push('/');
    } else {
      setPageHistory([CONSTANT.menuItemMappingPage]);
      renderLoaderAnimation(false);
      this.fetchInternalMenuItemToBeMapped();
    }
  }

  /**
   * This function gets arrMenuId from arrRestaurantService which is used to fetch internal menu items to be mapped
   * While looping through arrRestaurantService, state mapArrLocationServiceByMenuId is also set to be rendered headers of locationServices having the same menu
   * @param {typedefs.RestaurantService[]} arrRestaurantService - Array of user's arrRestaurantService
   * @returns {number[]} arrMenuId - Array of menu id to fetch internal menu items to be mapped
   */
  getArrMenuIdFromArrRestaurantService(arrRestaurantService) {
    const arrMenuId = [];
    const mapArrLocationServiceByMenuId = new Map();
    arrRestaurantService.forEach((restaurantService) => {
      const { arrLocationService } = restaurantService;
      arrLocationService.forEach((locationService) => {
        const { name: locationName, arrService } = locationService;
        arrService.forEach((service) => {
          const { name: serviceName, menu, isValid } = service;
          const { menuId } = menu;
          if (!isValid) {
            return;
          }
          if (mapArrLocationServiceByMenuId.has(menuId)) {
            mapArrLocationServiceByMenuId.get(menuId).push({ locationName, serviceName });
          } else {
            arrMenuId.push(menuId);
            mapArrLocationServiceByMenuId.set(menuId, [{ locationName, serviceName }]);
          }
        });
      });
    });
    this.setState({ mapArrLocationServiceByMenuId });
    return arrMenuId;
  }

  /**
   * This function uses mapSetGroupIdByMenuId and selectedTab to read mapInputByGroupId by retrieving all user inputs in that
   * menu id, filtered by mapIsEmptyByGroupId to ignore all groupId keys that are empty. mapArrInternalMenuItemIdWasteIdByGroupId is
   * used to retrieve all the internal menu item ids that belongs to a group id so as to create the array of internal menu item ids
   * for the client menu item to be mapped to.
   * When saving drafts, new client menu items are to be created and if the group already has a linked client menu item, then update is necessary. If multiple groups hold the same client
   * menu item and the menu item name submitted is different, then they are split accordingly.
   * @param {string} saveType - Type of arrMenuItemAndMappingToBeCreated to be formulated. When saving drafts, empty fields are also considered updates if it has a linked cmi
   * @returns {typedefs.MenuItemWithMappingToBeCreated[]} arrMenuItemAndMappingToBeCreated - Array of menu item with mapping to be created
   */
  getArrMenuItemAndMappingToBeCreated(saveType) {
    const {
      mapIsEmptyByGroupId,
      mapInputByGroupId,
      selectedTab,
      mapSetGroupIdByMenuId,
      mapArrInternalMenuItemIdWasteIdByGroupId,
      mapArrGroupIdByClientMenuItemId,
    } = this.state;

    const mapUpdatedArrGroupIdByClientMenuItemId = new Map(
      JSON.parse(JSON.stringify(Array.from(mapArrGroupIdByClientMenuItemId)))
    );
    const mapArrInternalMenuItemIdWasteIdByMenuItemName = new Map();
    const mapArrGroupIdByMenuItemName = new Map();
    const arrMenuItemAndMappingToBeCreated = [];
    const setGroupId = mapSetGroupIdByMenuId.get(selectedTab);
    [...setGroupId].forEach((groupId) => {
      const isEmpty = mapIsEmptyByGroupId.get(groupId);
      const isClientMenuItem = !!mapInputByGroupId.get(groupId).clientMenuItemId;
      // Skip if input card is empty
      if (
        (saveType === CONSTANT.menuItemMappingSaveChanges && isEmpty) ||
        (saveType === CONSTANT.menuItemMappingSaveDraft && isEmpty && !isClientMenuItem)
      ) {
        return;
      }
      let isToCreateNewClientMenuItem = true;
      const menuItemInput = mapInputByGroupId.get(groupId);
      const { menuItemName, station, costPerKilogram, menuId, clientMenuItemId, startDate } =
        menuItemInput;
      const arrInternalMenuItemIdWasteIdOfGroupId =
        mapArrInternalMenuItemIdWasteIdByGroupId.get(groupId);
      // If a duplicate menuItemName is found, 1. push all the internalMenuItemIdWasteIds of the groupId to arrInternalMenuItemIdWasteId
      // of the menuItemName key, 2. push the groupId to the arrGroupId of the menuItemName key, and skip
      if (mapArrInternalMenuItemIdWasteIdByMenuItemName.has(menuItemName)) {
        mapArrInternalMenuItemIdWasteIdByMenuItemName
          .get(menuItemName)
          .push(...arrInternalMenuItemIdWasteIdOfGroupId);
        mapArrGroupIdByMenuItemName.get(menuItemName).push(groupId);
        return;
      }
      // If unique menuItemName, create the key in mapArrInternalMenuItemIdWasteIdByMenuItemName and push menuItemAndMappingToBeCreated
      // into arrMenuItemAndMappingToBeCreated
      // Note: Setting the value as a deep-copied array is important because adding of the other arrInternalMenuItemIdWasteIdOfGroupId in the subsequent
      // iteration will then not change the array in the mapArrInternalMenuItemIdWasteIdByGroupId, causing duplication of internalMenuItemIdWasteId
      // Deep-copying ensures that all values within arrInternalMenuItemIdWasteIdOfGroupId do not share the same memory references as those in the
      // mapArrInternalMenuItemIdWasteIdByGroupId state
      mapArrInternalMenuItemIdWasteIdByMenuItemName.set(
        menuItemName,
        JSON.parse(JSON.stringify(arrInternalMenuItemIdWasteIdOfGroupId))
      );
      mapArrGroupIdByMenuItemName.set(menuItemName, [groupId]);
      if (clientMenuItemId) {
        if (
          mapUpdatedArrGroupIdByClientMenuItemId.has(clientMenuItemId) &&
          (mapUpdatedArrGroupIdByClientMenuItemId.get(clientMenuItemId).length === 1 ||
            mapUpdatedArrGroupIdByClientMenuItemId
              .get(clientMenuItemId)
              .every((groupIdToCompare) => {
                const input = mapInputByGroupId.get(groupIdToCompare);
                const { menuItemName: menuItemNameToCompare } = input;
                return menuItemName === menuItemNameToCompare;
              }))
        ) {
          isToCreateNewClientMenuItem = false;
        }
        mapUpdatedArrGroupIdByClientMenuItemId.set(
          clientMenuItemId,
          mapUpdatedArrGroupIdByClientMenuItemId
            .get(clientMenuItemId)
            .filter((groupIdToRemove) => groupIdToRemove !== groupId)
        );
      }
      const menuItemAndMappingToBeCreated = {
        name: menuItemName,
        station,
        costPerKilogram: costPerKilogram === '' ? -1 : costPerKilogram,
        menuId,
        isToCreateNewClientMenuItem,
        clientMenuItemId,
        startDate,
      };
      arrMenuItemAndMappingToBeCreated.push(menuItemAndMappingToBeCreated);
    });
    // Populate arrMenuItemAndMappingToBeCreated elements with arrInternalMenuItemIdWasteId for multiple internal menu id mapped to the same client menu id
    for (
      let menuItemIndex = 0;
      menuItemIndex < arrMenuItemAndMappingToBeCreated.length;
      menuItemIndex += 1
    ) {
      const currentMenuItemToBeMapped = arrMenuItemAndMappingToBeCreated[menuItemIndex];
      const { name: menuItemName } = currentMenuItemToBeMapped;
      currentMenuItemToBeMapped.arrInternalMenuItemIdWasteId =
        mapArrInternalMenuItemIdWasteIdByMenuItemName.get(menuItemName);
      currentMenuItemToBeMapped.arrGroupId = mapArrGroupIdByMenuItemName.get(menuItemName);
    }
    return arrMenuItemAndMappingToBeCreated;
  }

  /**
   * This function is called on page load to fetch internal menu items that require mapping for menus in user's locations' services.
   * With these data, the following states are set:
   * 1. mapInputByGroupId - Input form state that contains each group id's menuItemName, station, costPerKilogram inputs
   * 2. mapIsErrorByGroupId - Whether each group id has any errors in their input
   * 3. mapArrImageURLByGroupId - arrImageUrl of each group id to be displayed in each MenuItemInput card
   * 4. mapArrInternalMenuItemIdWasteIdByGroupId - arrInternalMenuItemIdWasteId of each group id to be used for creating client menu item with mapping to be sent to the backend
   * 5. mapSetGroupIdByMenuId - Set of group id, with menu id keys used for rendering each MenuItemInput card. A set is used
   * so that it can easily be removed after user saves changes as opposed to iterating through an array
   * 6. isMenuItemMappingDataFetched - Boolean of whether internal menu item to be mapped has been fetched to decide if 'No menu items to map'
   * user message is displayed, as without it, this message is displayed during the small time window while waiting for backend response
   * 7. mapInputCountByInputByInputType - Object with 2 maps. 1 for menuItemName and 1 for station. These are used to provide user with options to choose from
   * to reduce user entry error and allow users to reuse previously entered menuItemName or station
   * 8. selectedTab - By default, the selectedTab should be the first menuId in ascending order. If there are no menus to be mapped,
   * the value 0 is set as the selectedTab
   */
  async fetchInternalMenuItemToBeMapped() {
    const { openSnackbar, renderLoaderAnimation } = this.context;
    const { arrRestaurantService, auth0, history } = this.props;
    const {
      mapArrImageURLByGroupId,
      mapArrInternalMenuItemIdWasteIdByGroupId,
      mapInputByGroupId,
      mapIsEmptyByGroupId,
      mapIsErrorByGroupId,
      mapIsPartiallyFilledByGroupId,
      mapSetGroupIdByMenuId,
      mapArrGroupIdByClientMenuItemId,
    } = this.state;
    const mapMenuItemNameCountByMenuItemName = new Map();
    const mapStationCountByStation = new Map();
    try {
      renderLoaderAnimation(true);
      const arrMenuId = this.getArrMenuIdFromArrRestaurantService(arrRestaurantService);
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-internal-menu-item-to-be-mapped',
        {
          userId,
          arrMenuId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        arrMenuIdInternalMenuItemToBeMapped: arrMenuIdInternalMenuItemToBeMappedWithImageURL,
        arrClientMenuItem,
      } = response.data;
      arrMenuIdInternalMenuItemToBeMappedWithImageURL.forEach(
        (menuIdInternalMenuItemToBeMappedWithImageURL) => {
          const { menuId, arrInternalMenuItem: arrInternalMenuItemWithImageURL } =
            menuIdInternalMenuItemToBeMappedWithImageURL;
          mapSetGroupIdByMenuId.set(menuId, new Set());
          // For each group with image URL, populate the associated states accordingly
          arrInternalMenuItemWithImageURL.forEach((internalMenuItemWithImageURL) => {
            const { groupId, arrImageURL, arrInternalMenuItemIdWasteId, arrMenuItem } =
              internalMenuItemWithImageURL;
            const defaultInput = {
              menuItemName: '',
              station: '',
              costPerKilogram: '',
              menuId,
              clientMenuItemId: arrMenuItem[0] ? arrMenuItem[0].menuItemId : null,
              startDate: arrMenuItem[0] ? arrMenuItem[0].startDate : null,
            };
            if (arrMenuItem[0]) {
              const {
                name: draftName,
                station: draftStation,
                costPerKilogram: draftCostPerKilogram,
              } = arrMenuItem[0];
              defaultInput.menuItemName = draftName;
              defaultInput.station = draftStation;
              defaultInput.costPerKilogram =
                draftCostPerKilogram === -1 ? '' : draftCostPerKilogram;
              if (draftName) {
                mapMenuItemNameCountByMenuItemName.set(
                  draftName,
                  mapMenuItemNameCountByMenuItemName.has(draftName)
                    ? mapMenuItemNameCountByMenuItemName.get(draftName) + 1
                    : 1
                );
              }
              if (draftStation) {
                mapStationCountByStation.set(
                  draftStation,
                  mapStationCountByStation.has(draftStation)
                    ? mapStationCountByStation.get(draftStation) + 1
                    : 1
                );
              }
            }
            mapInputByGroupId.set(groupId, defaultInput);
            mapIsErrorByGroupId.set(groupId, false);
            mapIsPartiallyFilledByGroupId.set(
              groupId,
              arrMenuItem[0]
                ? !(
                    Boolean(
                      arrMenuItem[0].name &&
                        arrMenuItem[0].station &&
                        arrMenuItem[0].costPerKilogram !== -1
                    ) ||
                    Boolean(
                      !arrMenuItem[0].name &&
                        !arrMenuItem[0].station &&
                        arrMenuItem[0].costPerKilogram === -1
                    )
                  )
                : false
            );
            mapIsEmptyByGroupId.set(
              groupId,
              arrMenuItem[0]
                ? !(
                    arrMenuItem[0].name ||
                    arrMenuItem[0].station ||
                    arrMenuItem[0].costPerKilogram !== -1
                  )
                : true
            );
            mapArrImageURLByGroupId.set(groupId, arrImageURL);
            mapArrInternalMenuItemIdWasteIdByGroupId.set(groupId, arrInternalMenuItemIdWasteId);
            mapSetGroupIdByMenuId.get(menuId).add(groupId);
            if (arrMenuItem.length > 0) {
              if (mapArrGroupIdByClientMenuItemId.has(arrMenuItem[0].menuItemId)) {
                mapArrGroupIdByClientMenuItemId.get(arrMenuItem[0].menuItemId).push(groupId);
              } else {
                mapArrGroupIdByClientMenuItemId.set(arrMenuItem[0].menuItemId, [groupId]);
              }
            }
          });
        }
      );
      // Sort mapSetGroupIdByMenuId by its menuId in ascending order so that UI displays a consistent tab header ordering
      let arrArrSortedSetGroupIdByMenuId = [...mapSetGroupIdByMenuId].sort(
        (menuIdA, menuIdB) => menuIdA[0] - menuIdB[0]
      );

      // Populate mapInputCountByInputByInputType state with menuItemName and station options
      // Also, populate mapMapExistingMenuItemByMenuIdByMenuItemName state for autopopulation of station & costPerKilogram for existing menu item
      const mapMapExistingMenuItemByMenuIdByMenuItemName = new Map();
      arrClientMenuItem.forEach((clientMenuItem) => {
        const { name: menuItemName, station, menuId } = clientMenuItem;
        if (!mapMenuItemNameCountByMenuItemName.has(menuItemName)) {
          mapMenuItemNameCountByMenuItemName.set(menuItemName, 1);
        }
        if (!mapStationCountByStation.has(station)) {
          mapStationCountByStation.set(station, 1);
        }
        if (mapMapExistingMenuItemByMenuIdByMenuItemName.has(menuItemName)) {
          mapMapExistingMenuItemByMenuIdByMenuItemName
            .get(menuItemName)
            .set(menuId, clientMenuItem);
        } else {
          mapMapExistingMenuItemByMenuIdByMenuItemName.set(
            menuItemName,
            new Map([[menuId, clientMenuItem]])
          );
        }
      });

      // User should see inputs sorted by Station first, then Menu Item Name, then Group Id
      arrArrSortedSetGroupIdByMenuId = arrArrSortedSetGroupIdByMenuId.map(
        ([groupId, setGroupId]) => {
          const arrGroupId = [...setGroupId];
          arrGroupId.sort();
          arrGroupId.sort((groupIdA, groupIdB) => {
            const { menuItemName: menuItemNameA } = mapInputByGroupId.get(groupIdA);
            const { menuItemName: menuItemNameB } = mapInputByGroupId.get(groupIdB);
            if (menuItemNameA === '' && menuItemNameB === '') {
              return 0;
            }
            if (menuItemNameB === '') {
              return -1;
            }
            if (menuItemNameA === '') {
              return 1;
            }
            return menuItemNameA.localeCompare(menuItemNameB);
          });
          arrGroupId.sort((groupIdA, groupIdB) => {
            const { station: stationA } = mapInputByGroupId.get(groupIdA);
            const { station: stationB } = mapInputByGroupId.get(groupIdB);
            if (stationA === '' && stationB === '') {
              return 0;
            }
            if (stationB === '') {
              return -1;
            }
            if (stationA === '') {
              return 1;
            }
            return stationA.localeCompare(stationB);
          });
          return [groupId, new Set(arrGroupId)];
        }
      );

      this.setState({
        isMenuItemMappingDataFetched: true,
        mapArrImageURLByGroupId,
        mapArrInternalMenuItemIdWasteIdByGroupId,
        mapInputByGroupId,
        mapIsErrorByGroupId,
        mapSetGroupIdByMenuId: new Map(arrArrSortedSetGroupIdByMenuId),
        selectedTab:
          arrArrSortedSetGroupIdByMenuId.length > 0 ? arrArrSortedSetGroupIdByMenuId[0][0] : 0,
        mapInputCountByInputByInputType: {
          menuItemName: mapMenuItemNameCountByMenuItemName,
          station: mapStationCountByStation,
        },
        mapMapExistingMenuItemByMenuIdByMenuItemName,
        mapArrGroupIdByClientMenuItemId,
        mapIsPartiallyFilledByGroupId,
      });
      renderLoaderAnimation(false);
    } catch (error) {
      renderLoaderAnimation(false);
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar('Unknown error during fetching menu item data. Please notify admin.', 'error');
      }
    }
  }

  /**
   * This function takes in user input and updates mapInputByGroupId which is to be sent to
   * backend for create menu item mapping
   * @param {string} groupId - Group id to be updated
   * @param {object} input - Input to be updated with
   * @param {string} input.menuItemName - menuItemName input
   * @param {string} input.station - station input
   * @param {string | number} input.costPerKilogram - costPerKilogram input. If input is not a number, it will be stored as string
   * @param {number} input.menuId - menuId of internal menu item
   * @param {number} menuId - Id of menu where input belongs to
   */
  updateMapInputByGroupId(groupId, input) {
    const { mapInputByGroupId } = this.state;
    const mapUpdatedInputByGroupId = new Map(mapInputByGroupId);
    mapUpdatedInputByGroupId.set(groupId, { ...mapUpdatedInputByGroupId.get(groupId), ...input });
    this.setState({ mapInputByGroupId: mapUpdatedInputByGroupId });
  }

  /**
   * This function sets the given state with new boolean passed in if
   * it is different from current boolean.
   * @param {string} groupId - Group id to be updated
   * @param {boolean} newBoolean - New boolean to set
   * @param {string} stateType - 'isError' / 'isEmpty' / 'isPartiallyFilled'
   */
  updateMapStateTypeByGroupId(groupId, newBoolean, stateType) {
    const { mapIsEmptyByGroupId, mapIsErrorByGroupId, mapIsPartiallyFilledByGroupId } = this.state;
    if (stateType === 'isError' && mapIsErrorByGroupId.get(groupId) !== newBoolean) {
      const mapUpdatedIsErrorByGroupId = new Map(mapIsErrorByGroupId);
      mapUpdatedIsErrorByGroupId.set(groupId, newBoolean);
      this.setState({ mapIsErrorByGroupId: mapUpdatedIsErrorByGroupId });
    } else if (stateType === 'isEmpty' && mapIsEmptyByGroupId.get(groupId) !== newBoolean) {
      const mapUpdatedIsEmptyByGroupId = new Map(mapIsEmptyByGroupId);
      mapUpdatedIsEmptyByGroupId.set(groupId, newBoolean);
      this.setState({ mapIsEmptyByGroupId: mapUpdatedIsEmptyByGroupId });
    } else if (
      stateType === 'isPartiallyFilled' &&
      mapIsPartiallyFilledByGroupId.get(groupId) !== newBoolean
    ) {
      const mapUpdatedIsPartiallyFilledByGroupId = new Map(mapIsPartiallyFilledByGroupId);
      mapUpdatedIsPartiallyFilledByGroupId.set(groupId, newBoolean);
      this.setState({ mapIsPartiallyFilledByGroupId: mapUpdatedIsPartiallyFilledByGroupId });
    }
  }

  /**
   * This function updates mapInputCountByInputByInputType state that contains the suggested options available to users after they have input at
   * least one of either menuItemName / station
   * This state contains maps used by menuItemName and station to track the count of same menu item name repeating
   * The reason why the number of times the same option has appeared is tracked, is so that if multiple of the same menu item names are input,
   * and one is removed, the state is decremented and not deleted. The menu item name key is only deleted when the count of the same menu item
   * name appearing has decremented to 0
   * @param {string} inputType - 'menuItemName' / 'station'
   * @param {string} previousValue - Previous value of field that was changed from
   * @param {string} newValue - New value of field that was changed to
   */
  updateMapInputCountByInputByInputType(inputType, previousValue, newValue) {
    const { mapInputCountByInputByInputType } = this.state;
    const mapUpdatedInputCountByInputByInputType = { ...mapInputCountByInputByInputType };
    if (newValue !== '') {
      if (!mapUpdatedInputCountByInputByInputType[inputType].has(newValue)) {
        mapUpdatedInputCountByInputByInputType[inputType].set(newValue, 0);
      }
      mapUpdatedInputCountByInputByInputType[inputType].set(
        newValue,
        mapUpdatedInputCountByInputByInputType[inputType].get(newValue) + 1
      );
    }
    if (previousValue !== '') {
      mapUpdatedInputCountByInputByInputType[inputType].set(
        previousValue,
        mapUpdatedInputCountByInputByInputType[inputType].get(previousValue) - 1
      );
      if (mapUpdatedInputCountByInputByInputType[inputType].get(previousValue) === 0) {
        mapUpdatedInputCountByInputByInputType[inputType].delete(previousValue);
      }
    }
    this.setState({ mapInputCountByInputByInputType: mapUpdatedInputCountByInputByInputType });
  }

  /**
   * This function updates the boolean state of isDialogOpened, to open or close the alert messages
   * @param {boolean} newIsDialogOpened - New isDialogOpened to update
   * @param {{header: string, body: string}} dialogContent - Content of dialog to be displayed if isDialogOpened is true, else if false
   * to reset the dialogContent state to blank
   * @param {string} saveType - Save type differentiates the dialog content
   */
  openOrCloseDialog(newIsDialogOpened, dialogContent, saveType) {
    this.setState({
      isDialogOpened: newIsDialogOpened,
      dialogContent: newIsDialogOpened ? dialogContent : { header: '', body: '' },
      saveType: newIsDialogOpened ? saveType : null,
    });
  }

  /**
   * This function is called after successful save changes and it deletes the group ids from the associated states
   * The states that are updated are as follows:
   * 1. mapArrImageURLByGroupId / mapArrInternalMenuItemIdWasteIdByGroupId / mapInputByGroupId / mapIsEmptyByGroupId / mapIsErrorByGroupId / -
   * Group id keys that have been mapped are deleted
   * 2. mapSetGroupIdByMenuId - Group ids are removed from menu id sets. If there are no more group ids in menu id key,
   * the menu id key is deleted
   * 4. selectedTab - If the menuId currently selected no longer exists, update it to the first key in mapSetGroupIdByMenuId. If there
   * are no longer any menu items to be mapped, selectedTab is set to 0
   * @param {typedefs.MenuItemWithMappingToBeCreated} arrMenuItemMapped - Array of client menu item(s) and its mapping(s) sent to the backend and successfully created
   */
  removeGroupIdAfterSaveChanges(arrMenuItemMapped) {
    const {
      mapArrImageURLByGroupId,
      mapArrInternalMenuItemIdWasteIdByGroupId,
      mapInputByGroupId,
      mapIsEmptyByGroupId,
      mapIsErrorByGroupId,
      mapSetGroupIdByMenuId,
      selectedTab,
      mapIsPartiallyFilledByGroupId,
    } = this.state;
    const mapUpdatedArrImageURLByGroupId = new Map(mapArrImageURLByGroupId);
    const mapUpdatedArrInternalMenuItemIdWasteIdByGroupId = new Map(
      mapArrInternalMenuItemIdWasteIdByGroupId
    );
    const mapUpdatedInputByGroupId = new Map(mapInputByGroupId);
    const mapUpdatedIsEmptyByGroupId = new Map(mapIsEmptyByGroupId);
    const mapUpdatedIsErrorByGroupId = new Map(mapIsErrorByGroupId);
    const mapUpdatedSetGroupIdByMenuId = new Map(mapSetGroupIdByMenuId);
    const mapUpdatedIsPartiallyFilledByGroupId = new Map(mapIsPartiallyFilledByGroupId);
    let updatedSelectedTab = selectedTab;

    arrMenuItemMapped.forEach((menuIdGroupIdMapped) => {
      const { arrGroupId, menuId } = menuIdGroupIdMapped;
      arrGroupId.forEach((groupId) => {
        mapUpdatedArrImageURLByGroupId.delete(groupId);
        mapUpdatedArrInternalMenuItemIdWasteIdByGroupId.delete(groupId);
        mapUpdatedInputByGroupId.delete(groupId);
        mapUpdatedIsEmptyByGroupId.delete(groupId);
        mapUpdatedIsErrorByGroupId.delete(groupId);
        mapUpdatedSetGroupIdByMenuId.get(menuId).delete(groupId);
        if (mapUpdatedSetGroupIdByMenuId.get(menuId).size === 0) {
          mapUpdatedSetGroupIdByMenuId.delete(menuId);
          updatedSelectedTab =
            mapUpdatedSetGroupIdByMenuId.size > 0 ? [...mapUpdatedSetGroupIdByMenuId][0][0] : 0;
        }
        mapUpdatedIsPartiallyFilledByGroupId.delete(groupId);
      });
    });
    this.setState({
      mapArrImageURLByGroupId: mapUpdatedArrImageURLByGroupId,
      mapArrInternalMenuItemIdWasteIdByGroupId: mapUpdatedArrInternalMenuItemIdWasteIdByGroupId,
      mapInputByGroupId: mapUpdatedInputByGroupId,
      mapIsEmptyByGroupId: mapUpdatedIsEmptyByGroupId,
      mapIsErrorByGroupId: mapUpdatedIsErrorByGroupId,
      mapSetGroupIdByMenuId: mapUpdatedSetGroupIdByMenuId,
      selectedTab: updatedSelectedTab,
      mapIsPartiallyFilledByGroupId: mapUpdatedIsPartiallyFilledByGroupId,
    });
  }

  /**
   * This function updates the following states:
   * 1. mapArrGroupIdByClientMenuItemId - Add group ids to arrGroupId of clientMenuItemId
   * 2. mapInputByGroupId - Add client menu item id and start date to inputs
   * @param {typedefs.MenuItemWithMappingToBeCreated[]} arrMenuItemAndMappingToSaveDraft - menu item and mapping drafts saved
   * @param {typedefs.MenuItemMapping[]} arrMenuItemMappingCreated - menu item mappings created
   */
  updateClientMenuItemIdToStatesAfterSaveDraft(
    arrMenuItemAndMappingToSaveDraft,
    arrMenuItemMappingCreated
  ) {
    const { mapInputByGroupId } = this.state;
    const mapUpdatedArrGroupIdByClientMenuItemId = new Map();
    arrMenuItemMappingCreated.forEach((menuItemMappingCreated) => {
      const { internalMenuItemId, clientMenuItemId, startDate } = menuItemMappingCreated;
      const menuItemAndMappingToSaveDraft = arrMenuItemAndMappingToSaveDraft.find(
        (menuItemAndMapping) => {
          const { arrInternalMenuItemIdWasteId } = menuItemAndMapping;
          return arrInternalMenuItemIdWasteId.some(
            (internalMenuItemIdWasteId) =>
              internalMenuItemIdWasteId.internalMenuItemId === internalMenuItemId
          );
        }
      );
      const { arrGroupId } = menuItemAndMappingToSaveDraft;
      arrGroupId.forEach((groupId) => {
        mapInputByGroupId.set(groupId, {
          ...mapInputByGroupId.get(groupId),
          clientMenuItemId,
          startDate,
        });
      });
      if (!mapUpdatedArrGroupIdByClientMenuItemId.has(clientMenuItemId)) {
        mapUpdatedArrGroupIdByClientMenuItemId.set(clientMenuItemId, arrGroupId);
      }
    });
    this.setState({
      mapArrGroupIdByClientMenuItemId: mapUpdatedArrGroupIdByClientMenuItemId,
      mapInputByGroupId,
    });
  }

  /**
   * This function sends the menu item mapping(s) to be created to Backend. If there are errors or partially filled input cards found,
   * a request to logErrorAPI is made to log the error for debugging purposes. Upon successful creation of menu item mapping(s),
   * it will call this.removeGroupIdAfterSaveChanges to update the app state and remove the input cards from the UI
   */
  async saveChanges() {
    this.openOrCloseDialog(false);
    const { openSnackbar, renderLoaderAnimation } = this.context;
    const { auth0, history } = this.props;
    const {
      mapIsEmptyByGroupId,
      mapIsErrorByGroupId,
      selectedTab,
      mapSetGroupIdByMenuId,
      mapIsPartiallyFilledByGroupId,
    } = this.state;
    const setGroupId = mapSetGroupIdByMenuId.get(selectedTab);
    const isAllEmpty = [...setGroupId]
      .map((groupId) => mapIsEmptyByGroupId.get(groupId))
      .every((isEmpty) => isEmpty);
    if (isAllEmpty) {
      this.openOrCloseDialog(true, getDialogContent().empty);
      return;
    }
    const isErrorExist = [...setGroupId]
      .map((groupId) => mapIsErrorByGroupId.get(groupId))
      .some((isError) => isError);
    const isPartiallyFilledExist = [...setGroupId]
      .map((groupId) => mapIsPartiallyFilledByGroupId.get(groupId))
      .some((isPartiallyFilled) => isPartiallyFilled);
    const arrMenuItemAndMappingToBeCreated = this.getArrMenuItemAndMappingToBeCreated(
      CONSTANT.menuItemMappingSaveChanges
    );
    const token = await auth0.getAccessTokenSilently();
    const { user } = auth0;
    let userId = user.datavizUserId;
    if (user.isAdmin) {
      const { impersonatorDatavizUserId } = this.context;
      userId = impersonatorDatavizUserId;
    }
    if (isErrorExist || isPartiallyFilledExist) {
      this.openOrCloseDialog(true, getDialogContent().saveChangesError);
      // Within the selected tab, if an error or partially filled field exists, send error to be logged to backend
      try {
        await axios.post(
          '/api/log-error',
          {
            userId,
            errorMessage: getDialogContent().saveChangesError.body,
            pathname: window.location.pathname,
            data: {
              arrMenuItemAndMappingToBeCreated,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        // console.log() written here intentionally as catch block cannot be empty, and there is no expected behaviour if the above axios.post() throws an exception
        console.log(error);
      }
      return;
    }
    try {
      renderLoaderAnimation(true);
      await axios.post(
        '/api/create-menu-item-and-mapping',
        {
          userId,
          arrMenuItemAndMappingToBeCreated,
          userName: `${user.given_name} ${user.family_name}`,
          statusCode: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.removeGroupIdAfterSaveChanges(arrMenuItemAndMappingToBeCreated);
      openSnackbar('Successfully saved changes to menu item mapping', 'success');
      renderLoaderAnimation(false);
    } catch (error) {
      renderLoaderAnimation(false);
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during creating menu item mapping. Please notify admin.',
          'error'
        );
      }
    }
  }

  /**
   * This function sends the menu item mapping(s) to be created to Backend. These menu item mapping(s) will hold a status code
   * -1 which denotes that it is a draft. If there are errors found,
   * a request to logErrorAPI is made to log the error for debugging purposes. Upon successful creation of menu item mapping(s),
   * it will call this.updateClientMenuItemIdToStatesAfterSaveDraft to update the app state
   */
  async saveDraft() {
    this.openOrCloseDialog(false);
    const { openSnackbar, renderLoaderAnimation } = this.context;
    const { auth0, history } = this.props;
    const {
      mapIsEmptyByGroupId,
      mapIsErrorByGroupId,
      selectedTab,
      mapSetGroupIdByMenuId,
      mapInputByGroupId,
    } = this.state;
    const setGroupId = mapSetGroupIdByMenuId.get(selectedTab);
    const isAllEmpty = [...setGroupId]
      .map((groupId) => [groupId, mapIsEmptyByGroupId.get(groupId)])
      .every(([groupId, isEmpty]) => isEmpty && !mapInputByGroupId.get(groupId).clientMenuItemId);
    if (isAllEmpty) {
      this.openOrCloseDialog(true, getDialogContent().empty);
      return;
    }
    const isErrorExist = [...setGroupId]
      .map((groupId) => mapIsErrorByGroupId.get(groupId))
      .some((isError) => isError);
    const arrMenuItemAndMappingToSaveDraft = this.getArrMenuItemAndMappingToBeCreated(
      CONSTANT.menuItemMappingSaveDraft
    );
    const token = await auth0.getAccessTokenSilently();
    const { user } = auth0;
    let userId = user.datavizUserId;
    if (user.isAdmin) {
      const { impersonatorDatavizUserId } = this.context;
      userId = impersonatorDatavizUserId;
    }
    if (isErrorExist) {
      this.openOrCloseDialog(true, getDialogContent().saveDraftError);
      // Within the selected tab, if an error exists, send error to be logged to backend
      try {
        await axios.post(
          '/api/log-error',
          {
            userId,
            errorMessage: getDialogContent().saveDraftError.body,
            pathname: window.location.pathname,
            data: {
              arrMenuItemAndMappingToSaveDraft,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        // console.log() written here intentionally as catch block cannot be empty, and there is no expected behaviour if the above axios.post() throws an exception
        console.log(error);
      }
      return;
    }
    try {
      renderLoaderAnimation(true);
      const response = await axios.post(
        '/api/create-menu-item-and-mapping',
        {
          userId,
          arrMenuItemAndMappingToBeCreated: arrMenuItemAndMappingToSaveDraft,
          statusCode: -1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      openSnackbar('Successfully saved draft to menu item mapping', 'success');
      this.updateClientMenuItemIdToStatesAfterSaveDraft(
        arrMenuItemAndMappingToSaveDraft,
        response.data.arrMenuItemMappingCreated
      );
      renderLoaderAnimation(false);
    } catch (error) {
      renderLoaderAnimation(false);
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error when saving menu item mapping draft. Please notify admin.',
          'error'
        );
      }
    }
  }

  /**
   * This function updates the selectedTab state which then displays the appropriate tab of MenuItemInput components
   * @param {number} newSelectedTab - Selected tab based on menuId
   */
  updateSelectedTab(newSelectedTab) {
    this.setState({ selectedTab: newSelectedTab });
  }

  render() {
    const { classes } = this.props;
    const {
      dialogContent,
      mapInputCountByInputByInputType,
      isDialogOpened,
      isMenuItemMappingDataFetched,
      mapArrImageURLByGroupId,
      mapArrLocationServiceByMenuId,
      mapSetGroupIdByMenuId,
      selectedTab,
      mapMapExistingMenuItemByMenuIdByMenuItemName,
      mapArrInternalMenuItemIdWasteIdByGroupId,
      saveType,
      mapInputByGroupId,
    } = this.state;

    return (
      <Box
        display="flex"
        flexDirection="column"
        paddingTop={3}
        paddingBottom={3}
        ref={this.scrollToTopRef}
      >
        {mapSetGroupIdByMenuId.size !== 0 && (
          <>
            <Box display="flex" justifyContent="end" gridGap={5} marginX={2} marginY={1}>
              <Button
                variant="contained"
                color="primary"
                className={`saveDraftButton ${classes.saveDraftButtonStyle}`}
                startIcon={<AssignmentIcon />}
                onClick={() =>
                  this.openOrCloseDialog(
                    true,
                    getDialogContent(mapArrLocationServiceByMenuId.get(selectedTab))
                      .saveDraftWarning,
                    CONSTANT.menuItemMappingSaveDraft
                  )
                }
              >
                <Typography variant="body2">Save Draft</Typography>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={`saveChangesButton ${classes.saveChangesButtonStyle}`}
                startIcon={<CheckCircleOutlineSharpIcon />}
                onClick={() =>
                  this.openOrCloseDialog(
                    true,
                    getDialogContent(mapArrLocationServiceByMenuId.get(selectedTab))
                      .saveChangesWarning,
                    CONSTANT.menuItemMappingSaveChanges
                  )
                }
              >
                <Typography variant="body2">Submit</Typography>
              </Button>
            </Box>

            {/* Tab Options */}
            <Tabs
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="on"
              value={selectedTab}
              onChange={(_, newSelectedTab) => this.updateSelectedTab(newSelectedTab)}
            >
              {Array.from(mapSetGroupIdByMenuId).map(([menuId, setInternalMenuItemId]) => {
                if (setInternalMenuItemId.size !== 0) {
                  const arrLocationService = mapArrLocationServiceByMenuId.get(menuId);
                  const label = arrLocationService.slice(0, 2).map((locationService) => {
                    const { locationName, serviceName } = locationService;
                    return (
                      <Typography
                        color="primary"
                        noWrap
                        variant="body2"
                        key={`${locationName}_${serviceName}`}
                      >
                        {`${locationName} > ${serviceName}`}
                      </Typography>
                    );
                  });
                  if (arrLocationService.length > 2) {
                    label.push(
                      <Tooltip
                        title={arrLocationService.map((locationService) => {
                          const { locationName, serviceName } = locationService;
                          return (
                            <Typography
                              noWrap
                              variant="body2"
                              key={`${locationName}_${serviceName}`}
                            >
                              {`${locationName} > ${serviceName}`}
                            </Typography>
                          );
                        })}
                        interactive
                        arrow
                        key={`tooltip_${arrLocationService[0].locationName}_${arrLocationService[0].serviceName}`}
                        classes={{
                          tooltip: classes.tooltipContainer,
                        }}
                      >
                        <Typography
                          style={{
                            width: '100%',
                          }}
                        >
                          ...
                        </Typography>
                      </Tooltip>
                    );
                  }
                  return (
                    <Tab
                      key={menuId}
                      label={<Box>{label}</Box>}
                      value={menuId}
                      className={`menuHeader ${classes.menuHeader}`}
                    />
                  );
                }
                return null;
              })}
            </Tabs>
            {/* Tab Contents */}
            {Array.from(mapSetGroupIdByMenuId).map(([menuId, setGroupId]) => {
              if (setGroupId.size !== 0) {
                return (
                  <div value={selectedTab} hidden={selectedTab !== menuId} key={menuId}>
                    <Grid
                      container
                      className={classes.inputContainer}
                      alignItems="center"
                      display="flex"
                      flexwrap="wrap"
                    >
                      {Array.from(setGroupId).map((groupId) => (
                        <MenuItemInput
                          key={`groupId_${groupId}`}
                          groupId={groupId}
                          defaultInput={mapInputByGroupId.get(groupId)}
                          updateMapInputByGroupId={(_, input) =>
                            this.updateMapInputByGroupId(groupId, input)
                          }
                          updateMapStateTypeByGroupId={(_, newBoolean, stateType) =>
                            this.updateMapStateTypeByGroupId(groupId, newBoolean, stateType)
                          }
                          dictionary={dictionary}
                          arrImageURL={mapArrImageURLByGroupId.get(groupId)}
                          mapInputCountByInputByInputType={mapInputCountByInputByInputType}
                          updateMapInputCountByInputByInputType={(
                            inputType,
                            previousValue,
                            newValue
                          ) => {
                            this.updateMapInputCountByInputByInputType(
                              inputType,
                              previousValue,
                              newValue
                            );
                          }}
                          menuId={menuId}
                          mapMapExistingMenuItemByMenuIdByMenuItemName={
                            mapMapExistingMenuItemByMenuIdByMenuItemName
                          }
                          arrInternalMenuItemIdWasteId={mapArrInternalMenuItemIdWasteIdByGroupId.get(
                            groupId
                          )}
                        />
                      ))}
                    </Grid>
                    <Button
                      className={classes.scrollToTopButton}
                      onClick={() => this.scrollToTopRef.current.scrollIntoView()}
                    >
                      <ArrowUpwardIcon />
                    </Button>
                  </div>
                );
              }
              return null;
            })}
          </>
        )}
        {mapSetGroupIdByMenuId.size === 0 && isMenuItemMappingDataFetched && (
          <Box className="noMenuItemsToBeMappedMessage" paddingTop={6}>
            <Typography align="center" variant="h1">
              There are no menu items to be mapped at this moment 🎉
            </Typography>
            <Typography align="center" variant="h6">
              If you believe this to be an error, kindly contact the Lumitics team
            </Typography>
          </Box>
        )}
        {/* Modal for dialog */}
        {/* Conditional rendering used instead of `open` prop in Dialog due to slow native mui animation that causes Dialog to have a small delay, displaying an empty content body before Dialog is fully closed */}
        {isDialogOpened && (
          <Dialog
            open={() => {}}
            onClose={(event, reason) => {
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                this.openOrCloseDialog(false);
              }
            }}
            aria-labelledby="alert-message-dialog-title"
            aria-describedby="alert-message-dialog-description"
          >
            <DialogTitle
              id="alert-message-dialog-title"
              className={classes.warningTitle}
              disableTypography="true"
            >
              {dialogContent.header}
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-message-dialog-description">
                {dialogContent.body}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.cancelButton}
                onClick={() => this.openOrCloseDialog(false)}
              >
                Cancel
              </Button>
              {dialogContent.header !== 'NOTE' && (
                <Button
                  className={`confirmButton ${classes.confirmButton}`}
                  onClick={() => {
                    if (saveType === CONSTANT.menuItemMappingSaveChanges) {
                      this.saveChanges();
                    } else if (saveType === CONSTANT.menuItemMappingSaveDraft) {
                      this.saveDraft();
                    }
                  }}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </Box>
    );
  }
}

MenuItemMapping.contextType = AppContext;

export default withRouter(withAuth0(withStyles(styles)(MenuItemMapping)));
