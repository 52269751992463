import axios from 'axios';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Below required as typedefs is referred to in parameter function description
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext } from '../../AppContext';

const HIERARCHY_TYPE = {
  company: 'company',
  restaurant: 'restaurant',
  location: 'location',
};

/**
 * Custom component used per tab panel as adapted from https://v4.mui.com/components/tabs/
 * @param {{children: React.ReactNode, value: number, index: number}} props - Props passed down from parent component
 * @param {number} props.value - Tab value of currently selected tab
 * @param {number} props.index - Tab index of the current tab panel
 * @returns {React.ReactNode} - Tab panel used for tabbing functionality
 */
const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

/**
 * This function takes in a restaurant/location/service name to render the styled line item
 * @param {string} name - Name of restaurant/location/service
 * @param {string} hierarchyType - Type of hierarchy (restaurant/location/service)
 * @returns {React.ReactNode} - Styled span of heirarchy name to display
 */
const getHierarchyNameToDisplay = (name, hierarchyType) => {
  let nameToDisplay = <span style={{ fontWeight: 'bold', color: '#102547' }}>{name}</span>;
  if (hierarchyType === HIERARCHY_TYPE.restaurant) {
    nameToDisplay = <span style={{ fontWeight: 'bold', color: '#4A89BB' }}>{`● ${name}`}</span>;
  } else if (hierarchyType === HIERARCHY_TYPE.location) {
    nameToDisplay = <span>{`${name}`}</span>;
  }
  return nameToDisplay;
};

const styles = (theme) => ({
  rootGrid: {
    height: '100%',
    background:
      'linear-gradient(180deg, #102547 0%, #152A4F 25%, #17305C 50%, #1C396A 75%, #214079 100%)',
  },
  impersonatorDialog: {
    minWidth: '30rem',
  },
  logoutButton: {
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
  },
  checkbox: {
    width: '30px',
  },
  companyLocationLabel: {
    cursor: 'pointer',
    borderRadius: '10px',
    padding: '0 10px',
    '&:hover': {
      backgroundColor: theme.palette.gray3,
    },
  },
  clearButton: {
    backgroundColor: '#F5DF52',
    '&:hover': {
      backgroundColor: '#F5DF52',
    },
  },
});

class ImpersonatorPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      arrPlatformUser: [],
      selectedDatavizUserId: '',
      tabValue: 0,
      setSelectedLocationId: new Set(),
      arrCompanyLocationOption: [],
      mapRestaurantIdByLocationId: new Map(),
    };
  }

  componentDidMount() {
    document.title = 'Impersonator | Lumitics | Towards Zero Food Waste';
    const { renderLoaderAnimation, openSnackbar } = this.context;
    renderLoaderAnimation(false);
    try {
      this.fetchAllUsersAndArrCompanyService();
    } catch (error) {
      openSnackbar(
        'Unknown error when fetching all users and locations. Please notify admin.',
        'error'
      );
    }
  }

  /**
   * This function fetches all users and locations from company service available for impersonation
   */
  async fetchAllUsersAndArrCompanyService() {
    const { openSnackbar } = this.context;
    const { history } = this.props;
    const { auth0 } = this.props;
    const token = await auth0.getAccessTokenSilently();
    const { user } = auth0;
    if (user.isAdmin) {
      const arrFetchAllUsersAndArrCompanyServicePromise = [
        axios.post(
          '/api/fetch-all-users',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
        axios.post(
          '/api/fetch-company-service',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
      ];

      const [fetchAllUsersResponse, fetchArrCompanyServiceResponse] = await Promise.all(
        arrFetchAllUsersAndArrCompanyServicePromise
      );

      const { arrPlatformUser } = fetchAllUsersResponse.data;
      this.setState({
        arrPlatformUser,
      });
      const { arrCompanyService } = fetchArrCompanyServiceResponse.data;
      this.initialiseArrCompanyLocationOptionAndMapRestaurantIdByLocationId(arrCompanyService);
    } else {
      openSnackbar('You are not authorised to view this page!', 'error');
      history.push('/');
    }
  }

  /**
   * This function initialises arrCompanyLocationOption and mapRestaurantIdByLocationId states after
   * fetching arrCompanyService from backend
   * 1. arrCompanyLocationOption is used to render the list of company locations with some metadata
   * used for mass selection of companies and restaurants
   * 2. mapRestaurantIdByLocationId is used when determining if the location(s) to be impersonated
   * belong to one or multiple restaurants to set the isCompanyUser global state
   * @param {Array<typedefs.CompanyService>} arrCompanyService - Array of all company service
   */
  initialiseArrCompanyLocationOptionAndMapRestaurantIdByLocationId(arrCompanyService) {
    const arrCompanyLocationOption = [];
    const mapRestaurantIdByLocationId = new Map();
    arrCompanyService.forEach((companyService) => {
      const { name: companyName, arrRestaurantService, companyId, isAirline } = companyService;
      if (isAirline) return;
      const companyOption = {
        id: companyId,
        name: companyName,
        arrLocationId: [],
        type: HIERARCHY_TYPE.company,
      };
      arrCompanyLocationOption.push(companyOption);
      arrRestaurantService.forEach((restaurantService) => {
        const { name: restaurantName, arrLocationService, restaurantId } = restaurantService;
        const restaurantOption = {
          id: restaurantId,
          name: restaurantName,
          arrLocationId: [],
          type: HIERARCHY_TYPE.restaurant,
        };
        arrCompanyLocationOption.push(restaurantOption);
        arrLocationService.forEach((locationService) => {
          const { name: locationName, locationId } = locationService;
          companyOption.arrLocationId.push(locationId);
          restaurantOption.arrLocationId.push(locationId);
          const locationOption = {
            id: locationId,
            name: locationName,
            type: HIERARCHY_TYPE.location,
          };
          mapRestaurantIdByLocationId.set(locationId, restaurantId);
          arrCompanyLocationOption.push(locationOption);
        });
      });
    });
    this.setState({
      arrCompanyLocationOption,
      mapRestaurantIdByLocationId,
    });
  }

  selectUser() {
    const {
      openSnackbar,
      setImpersonatorDatavizUserId,
      setImpersonatorIsCompanyUser,
      setImpersonatorName,
      setIsAppDataFetched,
    } = this.context;
    const { arrPlatformUser, selectedDatavizUserId } = this.state;
    const { history } = this.props;
    setImpersonatorDatavizUserId(selectedDatavizUserId);
    const selectedPlatformUser = arrPlatformUser.find(
      (platformUser) => platformUser.datavizUserId === selectedDatavizUserId
    );
    setImpersonatorName(`${selectedPlatformUser.firstName} ${selectedPlatformUser.lastName}`);
    setImpersonatorIsCompanyUser(selectedPlatformUser.isCompany);
    setIsAppDataFetched(false);
    openSnackbar("You've successfully logged in!", 'success');
    history.push('/');
  }

  /**
   * This function is called when a user clicks on 'Select' button when choosing to impersonate by location
   * setSelectedLocationId is read to populate global states and push user to homepage
   */
  selectLocation() {
    const {
      openSnackbar,
      setImpersonatorDatavizUserId,
      setImpersonatorIsCompanyUser,
      setImpersonatorName,
      setIsAppDataFetched,
      setImpersonatorArrLocationId,
    } = this.context;
    const { setSelectedLocationId, mapRestaurantIdByLocationId } = this.state;
    const { history } = this.props;
    const setRestaurantId = new Set();
    const impersonatorArrLocationId = Array.from(setSelectedLocationId);
    setSelectedLocationId.forEach((locationId) => {
      const restaurantId = mapRestaurantIdByLocationId.get(locationId);
      setRestaurantId.add(restaurantId);
    });
    setImpersonatorDatavizUserId(-1);
    setImpersonatorArrLocationId(impersonatorArrLocationId);
    setImpersonatorName('Impersonating Location');
    setImpersonatorIsCompanyUser(setRestaurantId.size > 1);
    setIsAppDataFetched(false);
    openSnackbar("You've successfully logged in!", 'success');
    history.push('/');
  }

  /**
   * This function adds or deletes a location id for setSelectedLocationId state
   * @param {Array<number>} arrLocationIdToUpdate - Array of location id to be added or deleted
   */
  addOrDeleteLocationIdForSetSelectedLocationId(arrLocationIdToUpdate) {
    const { setSelectedLocationId } = this.state;
    const setUpdatedSelectedLocationId = new Set(setSelectedLocationId);
    if (arrLocationIdToUpdate.every((locationId) => setUpdatedSelectedLocationId.has(locationId))) {
      arrLocationIdToUpdate.forEach((locationId) =>
        setUpdatedSelectedLocationId.delete(locationId)
      );
    } else {
      arrLocationIdToUpdate.forEach((locationId) => setUpdatedSelectedLocationId.add(locationId));
    }
    this.setState({
      setSelectedLocationId: setUpdatedSelectedLocationId,
    });
  }

  logout() {
    const { setIsAppDataFetched } = this.context;
    const { auth0 } = this.props;
    const { logout } = auth0;

    setIsAppDataFetched(false);
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
    });
  }

  render() {
    const { classes } = this.props;
    const {
      arrPlatformUser,
      selectedDatavizUserId,
      tabValue,
      arrCompanyLocationOption,
      setSelectedLocationId,
    } = this.state;

    return (
      <Grid
        container
        spacing={0}
        className={classes.rootGrid}
        justifyContent="center"
        alignItems="center"
      >
        {arrPlatformUser.length > 0 && arrCompanyLocationOption.length > 0 && (
          <Dialog open className="impersonatorList" fullWidth maxWidth="sm">
            <DialogTitle>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>Impersonator</Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="info"
                    disableElevation
                    className={classes.logoutButton}
                    onClick={() => this.logout()}
                  >
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </DialogTitle>
            <Tabs
              value={tabValue}
              variant="fullWidth"
              onChange={(_, newTabValue) => {
                this.setState({
                  tabValue: newTabValue,
                });
              }}
            >
              <Tab label="Select User" />
              <Tab label="Select Location" />
            </Tabs>
            <DialogContent>
              <TabPanel value={tabValue} index={0}>
                <FormControl>
                  <RadioGroup
                    name="impersonator"
                    value={selectedDatavizUserId}
                    onChange={(event) =>
                      this.setState({ selectedDatavizUserId: Number(event.target.value) })
                    }
                  >
                    {arrPlatformUser.map((platformUser) => (
                      <FormControlLabel
                        key={`datavizUserId_${platformUser.datavizUserId}`}
                        value={platformUser.datavizUserId}
                        control={<Radio />}
                        label={platformUser.email}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Box display="flex" flexDirection="column">
                  {arrCompanyLocationOption.map((companyLocationOption) => {
                    const { type, id, name, arrLocationId } = companyLocationOption;
                    if (type !== HIERARCHY_TYPE.location && arrLocationId.length === 0) {
                      return null;
                    }
                    return (
                      <Box
                        key={`${type}_${id}`}
                        display="flex"
                        alignItems="center"
                        gridGap={2}
                        className={classes.companyLocationLabel}
                        onClick={() => {
                          this.addOrDeleteLocationIdForSetSelectedLocationId(
                            type === HIERARCHY_TYPE.location ? [id] : arrLocationId
                          );
                        }}
                      >
                        {type === HIERARCHY_TYPE.location && (
                          <input
                            className={classes.checkbox}
                            type="checkbox"
                            name={name}
                            checked={setSelectedLocationId.has(id)}
                            readOnly
                          />
                        )}
                        <Typography>{getHierarchyNameToDisplay(name, type)}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </TabPanel>
            </DialogContent>
            <DialogActions>
              <Box
                display="flex"
                flexDirection="row-reverse"
                justifyContent="space-between"
                width="100%"
                paddingX="0.2rem"
              >
                <Button
                  onClick={() => {
                    if (tabValue === 0) {
                      this.selectUser();
                    } else {
                      this.selectLocation();
                    }
                  }}
                  disabled={
                    tabValue === 0 ? !selectedDatavizUserId : setSelectedLocationId.size === 0
                  }
                  color="primary"
                >
                  Select
                </Button>
                {tabValue === 1 && setSelectedLocationId.size > 0 && (
                  <Button
                    onClick={() => this.setState({ setSelectedLocationId: new Set() })}
                    className={classes.clearButton}
                  >
                    {`Clear ${setSelectedLocationId.size} Location(s) Selected`}
                  </Button>
                )}
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
    );
  }
}

ImpersonatorPage.contextType = AppContext;

export default withRouter(withAuth0(withStyles(styles)(ImpersonatorPage)));
