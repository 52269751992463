import React, { PureComponent } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import BarLineChart from '../../common/chart/BarLineChart';
import LineChart from '../../common/chart/LineChart';
import { AppContext, CONSTANT } from '../../../AppContext';

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.main,
    paddingBottom: '12px',
  },
});

class WastageOverTimeLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Get chart title based on the selected group by
   */
  getWastageTitleForLineChart(classes) {
    const { selectedGroupBy } = this.context;
    let chartTitle = '';

    if (selectedGroupBy === CONSTANT.groupByDay) {
      chartTitle = 'WASTAGE BY DAY';
    } else if (selectedGroupBy === CONSTANT.groupByWeek) {
      chartTitle = 'WASTAGE BY WEEK';
    } else {
      chartTitle = 'WASTAGE BY MONTH';
    }

    return (
      <Typography className={`wastageByTitle ${classes.title}`} variant="h1">
        {chartTitle}
      </Typography>
    );
  }

  render() {
    const { currency } = this.context;
    const { classes, toggleValueForWeightCost, selectedLocationWasteAnalysisForHighlights } =
      this.props;

    const { wasteAnalysisForLineAndBarLineChart } = selectedLocationWasteAnalysisForHighlights;

    return (
      wasteAnalysisForLineAndBarLineChart && (
        <Box id="wastage-over-time-chart">
          <Grid container justifyContent="center" alignItems="center">
            {this.getWastageTitleForLineChart(classes)}
          </Grid>
          <LineChart
            xAxisData={wasteAnalysisForLineAndBarLineChart.arrDate}
            yAxisData={
              toggleValueForWeightCost === 'weight'
                ? [wasteAnalysisForLineAndBarLineChart.arrWeightAndGoal[0]] // Restaurant Goal is removed for the time being
                : [wasteAnalysisForLineAndBarLineChart.arrCost]
            }
            yAxisLabel={
              toggleValueForWeightCost === 'weight' ? 'WEIGHT (KG)' : `COST (${currency})`
            }
            isInKeyOverview={false}
            hasVisibleNodes
            labelData={toggleValueForWeightCost === 'weight' ? ['Weight'] : ['Cost']} // labelData={toggleValueForWeightCost === 'weight' ? ['Weight', 'Goal'] : ['Cost']} Restaurant Goal is removed for the time being
            hasGoal
          />
          {toggleValueForWeightCost === 'weight' && (
            <BarLineChart
              xAxisData={wasteAnalysisForLineAndBarLineChart.arrDate}
              yAxisBarData={wasteAnalysisForLineAndBarLineChart.arrWastePerCover}
              yAxisLineData={wasteAnalysisForLineAndBarLineChart.arrCover}
              yAxisBarLabel="WASTE PER COVER (grams)"
              yAxisLineLabel="COVER"
              barLabelData="Waste Per Cover"
              lineLabelData="Cover"
            />
          )}
        </Box>
      )
    );
  }
}

WastageOverTimeLineChart.contextType = AppContext;

export default withStyles(styles)(WastageOverTimeLineChart);
